import { ServerError } from 'middlewares/withServerErrorHandling';
import { QrCode2Fa } from 'models/2faDTO';
import { BalanceDTO } from 'models/CashFlowDTO';
import { IntegrationListItemDTO } from 'models/IntegrationDTO';
import { KycStatus } from 'models/OrganizationDTO';
import { BlockchainNetworksListItemDTO } from 'models/TokenContractDTO';
import { StateCreator, create } from 'zustand';
import { PersistOptions, createJSONStorage, persist } from 'zustand/middleware';

export type BlockchainNetworkToken = {
  id: number;
  name: string;
  symbol: string;
  networkId: number;
};

type GlobalStateStore = {
  blockchainNetworks: BlockchainNetworksListItemDTO[];
  blockchainNetworksTokens: BlockchainNetworkToken[];
  activeIntegrations: IntegrationListItemDTO[];
  intlPreference: string;
  showAccountBalance: boolean;
  hasAPIKeyCredentialsCreated: boolean;
  error: ServerError;
  hasPublicKeyCreated: boolean;
  kycStatus: KycStatus;
  has2FAEnabled: boolean;
  qrCode2Fa: QrCode2Fa;
  accountBalance: BalanceDTO | null;
  numberOfPasswords: number | null;
  organizationBillingEmail: string;
  setIntlPreference: (value: string) => void;
  setShowAccountBalance: (show: boolean) => void;
  setError: (error: ServerError) => void;
  setHas2FAEnabled: (enabled: boolean) => void;
  setOrganizationBillingEmail: (email: string) => void;
  setQrCode2Fa: (QrCode: QrCode2Fa) => void;
  setHasAPIKeyCredentialsCreated: (enabled: boolean) => void;
  setHasPublicKeyCreated: (enabled: boolean) => void;
  setKycStatus: (value: KycStatus) => void;
  setAccountBalance: (balance: BalanceDTO) => void;
  setNumberOfPasswords: (numberOfPasswords: number) => void;
  setBlockchainNetworks: (
    blockchainNetworks: BlockchainNetworksListItemDTO[]
  ) => void;
  setBlockchainNetworksTokens: (
    blockchainNetworksTokens: BlockchainNetworkToken[]
  ) => void;
  setActiveIntegrations: (activeIntegrations: IntegrationListItemDTO[]) => void;
  resetStateStore: () => void;
};

type ZustandPersist = (
  config: StateCreator<GlobalStateStore>,
  options: PersistOptions<GlobalStateStore>
) => StateCreator<GlobalStateStore>;

const initialState = {
  accountBalance: null,
  error: null,
  intlPreference: null,
  showAccountBalance: false,
  blockchainNetworks: [],
  blockchainNetworksTokens: [],
  activeIntegrations: [],
  numberOfPasswords: null,
  has2FAEnabled: false,
  organizationBillingEmail: '',
  qrCode2Fa: null,
  hasAPIKeyCredentialsCreated: false,
  hasPublicKeyCreated: false,
  kycStatus: 'not_sent'
} as GlobalStateStore;

const useGlobalStateStore = create<GlobalStateStore>(
  (persist as unknown as ZustandPersist)(
    (set) => ({
      ...initialState,
      setError: (error) =>
        set((state) => {
          return {
            ...state,
            error
          };
        }),
      setActiveIntegrations: (value) =>
        set((state) => {
          return {
            ...state,
            activeIntegrations: value
          };
        }),
      setIntlPreference: (value) =>
        set((state) => {
          return {
            ...state,
            intlPreference: value
          };
        }),
      setHas2FAEnabled: (enabled) =>
        set((state) => {
          return {
            ...state,
            has2FAEnabled: enabled
          };
        }),
      setKycStatus: (kycStatus) =>
        set((state) => {
          return {
            ...state,
            kycStatus
          };
        }),
      setOrganizationBillingEmail: (email) =>
        set((state) => {
          return {
            ...state,
            organizationBillingEmail: email
          };
        }),
      setHasAPIKeyCredentialsCreated: (enabled) =>
        set((state) => {
          return {
            ...state,
            hasAPIKeyCredentialsCreated: enabled
          };
        }),
      setHasPublicKeyCreated: (enabled) =>
        set((state) => {
          return {
            ...state,
            hasPublicKeyCreated: enabled
          };
        }),
      setShowAccountBalance: (showAccountBalance) =>
        set((state) => {
          return {
            ...state,
            showAccountBalance
          };
        }),
      setAccountBalance: (accountBalance) =>
        set((state) => {
          return {
            ...state,
            accountBalance
          };
        }),
      setNumberOfPasswords: (numberOfPasswords) =>
        set((state) => {
          return {
            ...state,
            numberOfPasswords
          };
        }),
      setBlockchainNetworks: (blockchainNetworks) =>
        set((state) => {
          return {
            ...state,
            blockchainNetworks
          };
        }),
      setBlockchainNetworksTokens: (blockchainNetworksTokens) =>
        set((state) => {
          return {
            ...state,
            blockchainNetworksTokens
          };
        }),
      setPublicKey: (publicKey) =>
        set((state) => {
          return {
            ...state,
            publicKey
          };
        }),
      setQrCode2Fa: (qrCode2Fa) =>
        set((state) => {
          return {
            ...state,
            qrCode2Fa
          };
        }),
      resetStateStore: () =>
        set(() => {
          return {
            ...initialState
          };
        })
    }),
    {
      name: 'global-state-storage',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

export default useGlobalStateStore;
