export const taxes_payment_page = {
  title: 'Taxes payment',
  table: {
    title: 'Transactions',
    header: {
      receipt: 'Receipt'
    },
    see_receipt: 'See receipt'
  },
  forms: {
    taxes_payment: {
      barcode: 'Barcode',
      input: 'Input the barcode',
      tax_amount: 'Tax amount',
      due_date: 'Due date',
      account_balance: 'Account balance',
      total_ammount: 'Total amount'
    },
    payment_receipt: {
      title: 'Payment receipt',
      activity: 'Activity',
      operation_value: 'Operation value',
      service_fee: 'Service fee',
      download: 'Download'
    }
  },
  buttons: {
    pay_taxes: 'Pay taxes'
  }
};
