export const swap_list_page = {
  breadcrumb_title: 'Swap',
  page_description:
    'Have BTC and need ETH? No need to sell and buy, do a SWAP.',
  table: {
    title: 'Transactions',
    header: {
      converted_to: 'Converted to',
      source_blockchain: 'Source blockchain',
      destination_blockchain: 'Destination blockchain',
      converted_value: 'Converted value',
      source_hash: 'Source hash',
      destination_hash: 'Destination hash'
    }
  },
  buttons: {
    swap: 'Swap'
  }
};
