export const internal_services_page = {
  breadcrumb_title: 'Serviços',
  services: {
    vaults: {
      title: 'Cofres',
      description:
        'Gerencie transações e carteiras com segurança com nossos cofres e personalize políticas de transação sem esforço.'
    },
    wallets: {
      title: 'Carteiras',
      description:
        'Crie e gerencie (saiba seu saldo, envie e receba) as principais criptomoedas do mercado.'
    },
    buy_sell: {
      title: 'Comprar e Vender',
      description:
        'Já criou suas carteiras? Negocie criptomoedas. Compra e venda qualquer ativo digital.'
    },
    swap: {
      title: 'Troca',
      description:
        'Tem BTC e precisa de ETH? Não precisa vender e comprar, faça uma TROCA.'
    },
    asset_tokenization: {
      title: 'Tokenização de Ativos digitais',
      description:
        'Tokenize qualquer tipo de ativo em qualquer uma das principais blockchains de mercado.'
    },
    nft: {
      title: 'NFT',
      description:
        'Crie seus NFTs (imagem, vídeo e áudio) nas principais blockchains de mercado em instantes.'
    },
    webhooks: {
      title: 'Webhooks',
      description:
        'Notifique seu sistema quando receber criptomoedas de qualquer lugar do mundo.'
    },
    taxes_payment: {
      title: 'Pagamento de impostos',
      description:
        'Simplifique o pagamento de impostos com nossa plataforma segura e eficiente.'
    },
    defi: {
      title: 'DeFi',
      description:
        'Empreste criptomoedas e receba % na própria moeda. Peça empréstimo em dinheiro em Reais (BRL) e use como garantia.'
    },
    naas: {
      title: 'Node como Serviço',
      description:
        'Seu acesso aos dados das principais blockchains em tempo real e 24/7.'
    }
  },
  blocked_organization: {
    title: 'Organização bloqueada',
    description:
      'Estamos cientes de que inconvenientes podem acontecer. Entre em contato com nossa equipe para continuar a usar nossos serviços.'
  },
  verify_account: {
    title: 'Verificar conta',
    description: 'Ao verificar sua conta, você poderá negociar criptomoedas.',
    step_1_required: 'Enviar documentos para aprovação;',
    link_button: 'Enviar documentação',
    title_pending: 'Documentos enviados para aprovação',
    description_pending:
      '<emphasis>Análise em progresso</emphasis>. Quando a verificação for concluída o status irá mudar e você será notificado por e-mail.'
  },
  settings: {
    title: 'Configurações de Serviços',
    description:
      'Para usar nossos serviços, você precisa <emphasis>primeiro registrar uma Chave Pública e, em seguida, uma Chave de API</emphasis> na página de Configurações.',
    step_1_required: 'Registrar uma Chave Pública;',
    step_2_required: 'Registrar uma Chave de API;',
    link_button: 'Ir para Configurações'
  }
};
