export const webhooks = {
  breadcrumb_title: 'Webhooks customizáveis',
  form: {
    title: 'Novo webhook customizado',
    description:
      'Você pode selecionar os valores padrão da URL e token na página de <emphasis>Configurações</emphasis>.',
    url: 'URL (opcional)',
    authorization_token: 'Token de autorização (opcional)',
    event_type: 'Tipo do evento',
    incoming: 'Entrada',
    all: 'Todos'
  },
  buttons: {
    create_webhook: 'Criar Webhook'
  }
};
