export const two_factor_settings = {
  breadcrumb_title: '2-factor',
  steps: {
    register_2fa: {
      title: '2-factor authentication',
      subtitle: 'Register an authentication app',
      step_1_title: 'Download an authentication app on your phone',
      step_1_desctiption:
        'Install an authentication app such as <link_google>Google Authenticator</link_google>, <link_authy>Authy</link_authy>, <link_duo_mobile>Duo Mobile</link_duo_mobile> or <link_one_password>1Password</link_one_password>.',
      step_2_title: 'Scan the QR Code',
      step_2_description:
        'Use the authentication app on your phone to scan the QR Code below:',
      step_2_observation:
        'In case you are not able to scan the QR Code, enter this code manually:',
      step_3_title: 'Enter the authentication code',
      step_3_description:
        'Enter the 6-digit code that was generated on the authentication app:',
      skip_button: 'Skip',
      skip_dialog_title: 'Do not show this step again?',
      skip_dialog_description:
        'You can always change it back on the settings menu.'
    },
    registered_2fa: {
      title: '2-FA is active',
      subtitle_settings:
        'The 2-factor authentication is registered. Here’s how to use it on your transactions:',
      subtitle_on_login: 'Use the 2-factor authentication code to continue',
      subtitle_required_to_proceed:
        'Use the 2-factor authentication code to conclude this transaction',
      step_1_title: 'Open the 2-factor authentication app',
      step_1_desctiption: 'Verify the app installed on your phone.',
      step_2_title: 'Verify the generated code',
      step_2_description:
        'A 6-digit code is being displayed on the authentication app.',
      step_3_title: 'Enter the 6-digit code',
      step_3_description:
        'Enter the 6-digit code that was generated on the authentication app.'
    },
    turn_off_2fa_dialog: {
      title: 'Turn OFF the 2-FA authentication?',
      description:
        'All 2-FA settings will reset and a new registration is needed.'
    },
    turn_on_2fa_dialog: {
      title: 'Turn ON the 2-FA authentication?',
      description: 'A new registration is needed.'
    }
  },
  buttons: {
    verify_continue_button: 'Verify and continue',
    cancel_login_button: 'Cancel login',
    turn_off: 'Turn OFF',
    turn_on: 'Turn ON'
  },
  turn_off_dialog: {
    title: 'Turn OFF the 2-factor authentication?',
    description:
      'All 2-factor authentication settings will be reset and a new registration is needed.',
    confirmation_label: 'Turn OFF and reset'
  },
  notifications: {
    success: {
      removed_2fa: '2-factor authetication is now OFF.'
    },
    error: {
      removed_2fa: '2-factor authetication removal failed.'
    }
  }
};
