export const integrations_page = {
  my_integrations_tab_label: 'Minhas integrações',
  browser_tab_label: 'Navegar',

  integrations_services: {
    fin_conecta: {
      title: 'FinConecta',
      description:
        'FinConecta agiliza a integração entre instituições financeiras e fintechs, ampliando a capacidade de atendimento.',
      service_overview:
        'O recurso de integração bancária do FinConecta simplifica o acesso às informações da conta em tempo real diretamente em nossa plataforma. Com uma conexão API segura, os usuários podem visualizar perfeitamente seu saldo bancário e histórico de transações, sem o incômodo de logins ou consultas separadas.',
      feature_1: 'Acesso em tempo real ao saldo bancário',
      feature_2: 'Integração perfeita, sem necessidade de logins adicionais',
      feature_3: 'Fácil visualização do histórico de transações'
    }
  },

  add_integration_dialog: {
    title: 'Conecte sua conta'
  },

  remove_service_dialog: {
    title: 'Remover chave de API',
    description: 'Tem certeza que deseja remover esta chave de API?'
  },

  overview_label: 'Visão geral',
  features_label: 'Recursos',

  secret_label: 'Segredo',
  secret_placeholder: 'Insira seu segredo',
  account_id_label: 'ID da conta',
  account_id_placeholder: 'Insira seu ID da conta',
  api_key_label: 'Chave de API',
  api_key_placeholder: 'Insira sua chave de API',
  username_label: 'Usuário',
  username_placeholder: 'Insira seu usuário',
  password_placeholder: 'Insira sua senha',

  status_labels: {
    connected_status: 'Conectado',
    not_connected_status: 'Não conectado'
  },

  buttons: {
    save_api_key: 'Salvar chave de API',
    remove_api_key: 'Remover chave de API'
  },

  snackbar_messages: {
    success: {
      add_integration: 'Integração realizada com sucesso',
      remove_integration: 'Integração desconectada'
    },
    error: {
      add_integration: 'Erro ao realizar integração',
      remove_integration: 'Erro ao desconectar integração'
    }
  }
};
