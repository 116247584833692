import { IntegrationsProvider } from 'contexts/IntegrationsContext';
import { useSession } from 'next-auth/react';
import { ReactNode, createContext } from 'react';

export const AuthContext = createContext({});

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const { status } = useSession();

  if (status === 'authenticated') {
    return (
      <AuthContext.Provider value={null}>
        <IntegrationsProvider>{children}</IntegrationsProvider>
      </AuthContext.Provider>
    );
  }

  return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
}
