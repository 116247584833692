export const forgotPasswordForm = {
  title: 'Esqueceu sua senha?',
  description: 'Informe seu endereço de e-mail.',
  description2: 'Nós lhe enviaremos um código de verificação.',

  emailLabelField: 'E-mail',
  codeLabelField: 'Código',
  emailRequiredField: 'E-mail é obrigatório',
  emailInvalidField: 'E-mail inválido',
  emailErrorField: 'E-mail incorreto',

  sendCodeButton: 'Enviar código',
  backToLoginButton: 'Voltar ao login',

  snackbar: {
    titleSuccess: 'Código enviado com sucesso.',
    descriptionSuccess: 'Por favor, verifique seu e-mail.'
  }
};

export const validationCodeForm = {
  title: 'Verificar código',
  description:
    'Verifique sua caixa de entrada e insira abaixo o código que lhe enviamos.',

  codeLabelField: 'Código',
  codeRequiredField: 'O código é obrigatório',
  codeErrorField: 'Código incorreto',

  verifyCodeButton: 'Verificar e continuar',
  backToLoginButton: 'Voltar ao login',
  resendCodeButton: 'Reeviar código {{time}}',

  snackbar: {
    titleSuccess: 'Código validado com sucesso.',
    descriptionSuccess: 'Agora você já pode fazer login.'
  }
};

export const resetPasswordForm = {
  title: 'Redefinir senha',
  description: 'Crie uma nova senha.',

  passwordLabelField: 'Senha',
  passwordRequiredField: 'Senha é obrigatória',
  passwordDoesNotMatchField: 'As senhas não são iguais',
  passwordMinCharsField: 'A senha deve ter pelo menos 10 caracteres',
  passwordRegexField: 'Requisitos de senha não atingidos.',

  confirmPasswordLabelField: 'Confirmar senha',
  confirmPasswordRequiredField: 'Confirmação senha é obrigatório',

  resetPasswordButton: 'Redefinir senha',
  backToLoginButton: 'Voltar ao login',

  snackbar: {
    titleSuccess: 'Senha redefinida com sucesso.',
    descriptionSuccess: 'Faça login com a nova senha.'
  }
};
