import { ipPermissionsErrors } from 'services/api/errors/ipPermissionsErrors';
import { two_factor_settings } from './2factor-authenticaton';
import { settings_menu_items } from './settings-menu-items';

export const settings_page = {
  breadcrumb_title: 'Settings',
  settings_menu_items,
  two_factor_settings,
  language_settings: {
    title: 'Language',
    language_options: {
      en: 'English',
      pt: 'Português',
      es: 'Spañol'
    }
  },
  ip_permissions_settings: {
    title: 'IP permissions',
    description:
      'By setting an IP permission you allow only users to login in a specific list of IP Addresses.',
    add_dialog: {
      title: 'Add IP'
    },
    remove_dialog: {
      title: 'Delete IP address?',
      description: 'The IP address will be deleted.'
    },
    ip_list_title: "List of added IP's",
    snackbar: {
      success: {
        add_ip: 'IP successfully added'
      },
      error: {
        ipPermissionsErrors,
        add_ip: 'Error on add IP address',
        invalid_ip: 'Invalid IP address'
      }
    }
  },
  theme_settings: {
    title: 'Theme',
    dark: 'Dark',
    light: 'Light'
  },
  public_key_settings: {
    title: 'Public key',
    description:
      'Add a public key to ensure that the encryption applied to your data can only be decrypted by you.',
    add_public_key_dialog_title: 'Add Public key',
    change_public_key_dialog: {
      title: 'Change Public key',
      current_private_key_label: 'Current Private key',
      current_private_key_description:
        'Enter the private key that generated the current public key registered in our platform.',
      new_public_key_label: 'New Public key',
      new_public_key_description:
        'Enter the new public key, which will be used to encrypt your data.'
    },
    snackbar: {
      success: {
        on_add_public_key: 'Public key added.',
        on_change_public_key: 'Public key changed.'
      },
      error: {
        on_add_public_key: 'Error on add Public key.'
      }
    },
    buttons: {
      confirm_changes: 'Confirm changes'
    }
  },
  api_key_settings: {
    title: 'API key',
    description:
      'By using an API key in your project, you are able to atheticate via OAuth2.',
    create_api_key_dialog_title: 'Create API key',
    delete_api_key_dialog_title: 'Delete API key?',
    delete_api_key_dialog_description:
      'This key will be invalidated and not usable anymore.',
    add_application_dialog_title: 'Add application',
    remove_application_dialog_title: 'Remove application?',
    remove_application_dialog_description: 'The application will be removed.',
    key_label: 'Key',
    secret_label: 'Secret',
    application_label: 'Application',
    public_key_required_informative:
      'You need to first register a <emphasis>Public Key</emphasis> in order to register an API key.'
  },
  webhooks_settings: {
    title: 'Webhook URL & token',
    description:
      'Set a default URL and an authorization token for your Webhooks.',
    url_label: 'URL',
    url_placeholder: 'Add URL',
    authorization_token_label: 'Auth Token (optional)',
    authorization_token_placeholder: 'Add token',

    add_webhook_dialog_title: 'Add Webhook URL & token',
    edit_webhook_dialog_title: 'Edit Webhook URL & token',

    snackbars: {
      success: {
        on_add_webhook: 'Default Webhook URL and authorization token set.',
        invite_member: 'Invite sent'
      },
      error: {
        on_add_webhook: 'Error on add Webhook URL.'
      }
    }
  },
  members: {
    invite: 'Invite',
    email: 'E-mail',
    group: 'Group',
    add: 'Add',
    invite_button: 'Invite users',
    delete_user_title: 'Delete user',
    delete_user_description: 'Are you sure you want to delete this user?'
  }
};
