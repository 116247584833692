export const wallets_list_page = {
  breadcrumb_title: '{{blockchainName}}',
  total_of_wallets: '{{number}} Carteiras',
  table: {
    headers: {
      with_custody: 'Com custódia'
    }
  },
  snackbar: {
    errors: {
      generic_error: 'Erro ao recuperar carteiras'
    }
  },
  filter_by_blockchain: 'Filtrar por Blockchain'
};
