export const my_account_page = {
  breadcrumb_title: 'Minha conta',
  bank_statements: 'Operações bancárias',
  my_data: 'Minhas informações',
  start: 'Começar',
  end: 'Fim',
  transaction: 'Transação',
  tables: {
    cash_flow: {
      title: 'Cash flow',
      date_and_time: 'Data e hora',
      value: 'Valor',
      type: 'Tipo',
      description: 'Descrição',
      all_types: {
        balance: 'Saldo',
        input: 'Entrada',
        output: 'Saída'
      }
    },
    withdraw: {
      title: 'Saque',
      date_and_time: 'Data e hora',
      value: 'Valor',
      pix_key: 'Chave Pix',
      favored: 'Favorecido',
      document: 'Documento',
      status: 'Status',
      all_status: {
        pending: 'Pendente',
        success: 'Sucesso',
        blocked: 'Bloqueado'
      },
      dialog: {
        steps_1: {
          title: 'Nova transação',
          withdraw_via: 'Sacar via',
          source: 'A conta deve pertercenter ao documento: {{document}}',
          value_to_withdraw: 'Valor para sacar',
          pix_key: 'Chave Pix',
          key: 'Chave'
        },
        steps_2: {
          value: 'Valor',
          favored: 'Favorecido',
          document: 'Documento',
          bank: 'Banco'
        }
      }
    },
    deposit: {
      title: 'Depósito',
      dialog: {
        deposit_via: 'Depositar via',
        source: 'A conta deve pertercenter ao documento: {{document}}',
        value_to_deposit: 'Valor para depositar',
        generate_qr_code: 'Gerar QR Code'
      }
    }
  },
  buttons: {
    verify_account: 'Verificar conta',
    change_password: 'Alterar senha',
    withdraw: 'Saque',
    deposit: 'Depósito',
    cash_flow_tab: 'Fluxo de caixa',
    withdraw_tab: 'Solicitações de saque',
    deposit_tab: 'Intenções de depósito',
    verify_account_confirm: 'Começar agora',
    apply: 'Aplicar'
  },
  kyc_rejected: {
    title: 'Documentação recusada',
    dialog_description_1: 'Imagem frontal recusada.',
    dialog_description_2: 'Imagem não reconhecida.',
    resend_button: 'Reenviar',
    cancel_button: 'Enviar depois'
  },
  kyc_pending: {
    title: 'Pendente',
    dialog_description_1: 'Análise em progresso',
    dialog_description_2:
      'O status irá mudar e você receberá um e-mail quando a documentação estiver validada.'
  },
  kyc_verified: {
    title: 'Verificada',
    description: 'A conta está verificada.'
  },
  kyc_not_sent: {
    title: 'Não verificada'
  },
  requests: {
    SUCCESS_KYC_SENT: 'Documentação enviada com sucesso'
  },
  income: 'Renda',
  outcome: 'Resultado'
};
