export const my_account_page = {
  breadcrumb_title: 'My account',
  bank_statements: 'Bank statements',
  my_data: 'My data',
  start: 'Start',
  end: 'End',
  transaction: 'Transaction',
  tables: {
    cash_flow: {
      title: 'Cash flow',
      date_and_time: 'Date and time',
      value: 'Value',
      type: 'Type',
      description: 'Description',
      all_types: {
        balance: 'Balance',
        input: 'Input',
        output: 'Output'
      }
    },
    withdraw: {
      title: 'Withdraw',
      date_and_time: 'Date and time',
      value: 'Value',
      pix_key: 'Pix key',
      favored: 'Favored',
      document: 'Document',
      status: 'Status',
      all_status: {
        pending: 'Pending',
        success: 'Success',
        blocked: 'Blocked'
      },
      dialog: {
        steps_1: {
          title: 'New transaction',
          withdraw_via: 'Withdraw via',
          source: 'The source account must belong to CPF/CNPJ: {{document}}',
          value_to_withdraw: 'Value to withdraw',
          pix_key: 'Pix key',
          key: 'Key'
        },
        steps_2: {
          value: 'Valor',
          favored: 'Favored',
          document: 'Document',
          bank: 'Banco'
        },
        steps_3: {
          confirm_button: 'Confirm transaction'
        }
      }
    },
    deposit: {
      title: 'Deposit',
      dialog: {
        deposit_via: 'Deposit via',
        source: 'The source account must belong to CPF/CNPJ: {{document}}',
        value_to_deposit: 'Value to deposit',
        generate_qr_code: 'Generate QR Code'
      }
    }
  },
  buttons: {
    verify_account: 'Verify account',
    change_password: 'Change password',
    withdraw: 'Withdraw',
    deposit: 'Deposit',
    cash_flow_tab: 'Cash flow',
    withdraw_tab: 'Withdraw intentions',
    deposit_tab: 'Deposit intentions',
    verify_account_confirm: 'Start now',
    apply: 'Apply'
  },
  kyc_rejected: {
    title: 'Documentation rejected',
    dialog_description_1: 'Front image rejected',
    dialog_description_2: 'The image is not readable.',
    resend_button: 'Resend',
    cancel_button: "I'll do it later"
  },
  kyc_pending: {
    title: 'Pending',
    dialog_description_1: 'Analysis in progress',
    dialog_description_2:
      'The status will change and you will receive an email once we verify the documentation.'
  },
  kyc_verified: {
    title: 'Verified',
    description: 'Account is verified'
  },
  kyc_not_sent: {
    title: 'Not verified'
  },
  requests: {
    SUCCESS_KYC_SENT: 'Documentation sent successfully',
    ERROR_SEND_KYC: 'Error on send documentation'
  },
  income: 'Income',
  outcome: 'Outcome'
};
