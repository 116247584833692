export const wallets_home_page = {
  breadcrumb_title: 'Wallets',
  my_wallets: 'My wallets',
  token_contracts: 'Token contracts',
  my_wallets_tab: {
    wallets_qtt: '{{value}} wallets',
    wallets_qtt_singular: '{{value}} wallet',
    wallet_details: 'Wallet details',
    tab_label: 'My wallets',
    new_wallet_form: {
      title: 'New Wallet',
      wallets_limit_informative:
        '<emphasis>{{amountOfWallets}}</emphasis> wallets have been created out of a total of <emphasis>{{walletsLimit}}</emphasis> available',
      description:
        'You are creating a network-level wallet. It will be possible to carry out transactions both in the native currency and in the tokens that belong to this network.',
      informative: 'A password is gerenated after the Wallet creation.',
      informative_password_store:
        'A security hash code was generated as a password for this Wallet. <emphasis>Please, be aware that you need to store this password in order to use it later on.</emphasis>',
      fields: {
        address_type: 'Address type',
        password_store_checkbox:
          'I understand that I need to store this Wallet’s password.'
      },
      success: {
        wallet_created: 'Your Wallet was successfully created'
      }
    },
    snackbar: {
      success: {
        wallet_created_with_webhook: 'Wallet created. Webhooks are enabled.',
        wallet_created_without_webhook: 'Wallet created. Webhooks are disabled.'
      },
      errors: {
        generic_error: 'Error on create a new wallet'
      }
    }
  },
  token_contracts_tab: {
    tab_label: 'Token contracts',
    description:
      'Add token contracts to the Blockchain networks available on the platform. When adding token contracts to a network, it becomes available in all wallets created in this network.',
    custom_token_contract_form: {
      title: 'New custom Token contract',
      fields: {
        token_name: 'Token name'
      }
    },
    delete_token_contract_dialog: {
      title: 'Delete Token contract?',
      description:
        'The Token contract will removed from the list. If you have any Wallet linked to this token it will stop working as well.'
    },
    snackbar: {
      success: {
        token_contract_added: 'Token contract added.'
      },
      errors: {
        generic_error: 'Error on add token contract.'
      }
    },
    token_already_in_use: {
      title: 'Failed to create the token contract',
      description: 'The token contract you are trying to add is already in use.'
    },
    invalid_address:
      'The token contract you are trying to add has an invalid address. Fix it and then try again.'
  },
  buttons: {
    create_wallet: 'Create Wallet',
    complete: 'Complete',
    add_custom: 'Add custom',
    create_token_contract: 'Create Token contract'
  }
};
