export const common_translations = {
  status_labels: {
    available: 'Available',
    soon: 'Soon',
    active: 'Active',
    pending_deploy: 'Pending deploy',
    deploy_error: 'Deploy error',
    imported: 'Imported',
    verification_needed: 'Verification needed',
    pending: 'Pending',
    pending_payment: 'Pending payment',
    payment_confirmed: 'Payment confirmed',
    request_registered: 'Request registered successfully',
    payment_canceled: 'Payment canceled',
    loading: 'Loading...',
    empty: 'Empty',
    format_not_supported: 'Format not supported',
    format_supported: 'Format supported',
    confirmed: 'Confirmed',
    outcome: 'Outcome',
    error: 'Error',
    response_value: 'Response: {{value}}',
    change: 'Change',
    manage: 'Manage',
    success: 'Success'
  },
  labels: {
    hash_id: 'Hash ID',
    explorer: 'Explorer',
    details: 'Details',
    hide: 'Hide',
    show: 'Show',
    connect: 'Connect',
    disconnect: 'Disconnect',
    free_testing: 'Free testing',
    image: 'Imagem',
    video: 'Video',
    audio: 'Audio',
    default: 'Default',
    blockchain: 'Blockchain',
    network: 'Network',
    network_fee: 'Network fee',
    blockchain_network: 'Blockchain network',
    created_at: 'Created {{formatedDate}}',
    symbol: 'Symbol',
    symbols: 'Symbols ({{amount}})',
    symbols_word: 'Symbols',
    decimals_amount: '{{amount}} decimals',
    account_balance: 'Account balance',
    date: 'Date',
    date_and_time: 'Date and time',
    reference: 'Reference',
    destination_wallet: 'Destination Wallet',
    enter_destination_wallet: 'Enter destination wallet',
    wallet_reference: 'Wallet reference',
    wallet_id: 'Wallet ID',
    wallet_identifier: 'Wallet identifier',
    choose_balance: 'Choose a balance',
    wallet_balance: 'Wallet balance',
    wallet_address: 'Wallet address',
    wallet: 'Wallet',
    wallets: 'Wallets',
    address: 'Address',
    confirms: 'Confirms',
    currency: 'Currency',
    status: 'Status',
    value: 'Value',
    lines: 'Lines',
    value_to_send: 'Value to send',
    type: 'Type',
    token: 'Token',
    name: 'Name',
    token_name: 'Token name',
    description: 'Description',
    document: 'Document',
    crypto_price: 'Crypto price',
    blockchain_token_price: '{{blockchainToken}} price',
    amount: 'Amount',
    search: 'Search',
    password: 'Password',
    payment_method: 'Payment method',
    amount_to_receive: 'Amount to receive',
    secure_transaction_text: 'This transaction is secure',
    drag_and_drop: 'Drag & drop or',
    requests: 'Requests',
    services: 'Services',
    identifier: 'Identifier',
    id: 'ID',
    decimals: 'Decimals',
    features: 'Features',
    client_id: 'Client ID',
    key_copied: 'Key copied to clipboard',
    client_secret: 'Client secret',
    edit_value: 'Edit value',
    edit_warning: 'Editing the value will generate a new code.',
    code_expiration_time_one_hour: 'This code is valid for 1 hour',
    expires_in: 'Expires {{value}}',
    back_login: 'Go back to login?',
    new_transfer: 'New transfer',
    disable_webhook: 'Disable Webhook?',
    disable_webhook_description:
      'The Webhook will be disabled. You can re-enable it later on.',
    disable: 'Disable',
    delete_webhook: 'Delete Webhook?',
    delete_webhook_description: 'The Webhook will be deleted.',
    duration: 'Duration',
    created_by: 'Created by',
    creation_date: 'Creation date',
    actions: 'Actions',
    income: 'Income',
    outcome: 'Outcome'
  },
  forms: {
    password_field_label: "Wallet's password",
    field_required: 'Field required',
    exit_form_message: 'Changes will be lost'
  },
  summaries: {
    new_transaction_step: 'New Transaction',
    wallet_password_step: 'Wallet password',
    summary_step: 'Summary',
    two_factor_step: '2-factor authentication',
    service_fee: 'Service fee',
    network_fee_extense: '{{blockchainSymbol}} network fee',
    network_fee_short: '{{blockchainSymbol}} fee',
    transaction_fee_label: 'Transaction fee',
    informative_crypto_price: 'Crypto prices may vary',
    informative_transaction_fee: 'Values vary depending on the priority.',
    low_priority: 'Low priority',
    low_priority_description: "I'm not in a hurry.",
    medium_priority: 'Medium priority',
    medium_priority_description: 'I can wait some time.',
    high_priority: 'High priority',
    high_priority_description: 'I need it asap.'
  },
  buttons: {
    yes: 'Yes',
    no: 'No',
    next: 'Next',
    back: 'Back',
    go_back: 'Go back',
    done: 'Done',
    save: 'Save',
    add: 'Add',
    edit: 'Edit',
    confirm: 'Confirm',
    copy: 'Copy',
    complete: 'Complete',
    approve: 'Approve',
    reject: 'Reject',
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    close: 'Close',
    filter: 'Filter',
    import: 'Import',
    upload: 'Upload',
    add_filter: 'Add filter',
    clear: 'Clear',
    create: 'Create',
    got_it: 'Got it',
    logout: 'Logout',
    confirm_transaction: 'Confirm transaction',
    send: 'Send',
    refresh: 'Refresh',
    export: 'Export'
  },
  documentation_tooltip: 'Open documentation',
  documentation_button: 'API & Docs',
  sandbox_env_alert_title: 'Testing environment',
  sandbox_env_alert_description:
    'We are providing access to a test environment that will allow you to experience the platform, but <emphasis>no data is permanent.</emphasis>',
  sandbox_env_alert_dialog_description:
    'Please keep in mind that right now we are providing access to a <emphasis>test environment</emphasis> that will allow you to experience the platform, but <emphasis>no data is permanent.</emphasis>',
  expired_token_alert_title: 'Expired token',
  expired_token_alert_description: "You'll be redirected to sign-in.",
  logout_dialog_title: 'Are you sure you want to logout?',
  logout_dialog_description: 'Your session will be expired.',
  success_alert: {
    address_copied: 'Address copied to clipboard'
  },
  receive_crypto_currency: 'Receive Cryptocurrency',
  find_logs: 'Find logs',
  invalid_token: 'Invalid token',
  email_exists: 'E-mail already exists'
};
