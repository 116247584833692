import { AxiosError } from 'axios';
import { PreferencesDTO } from 'models/UserDTO';
import APIService, {
  APIServiceDefaultParams,
  BaaSServerResponse
} from 'services/api/APIService';
import { PreferencesBodyData } from './api/schemas/PreferencesSchema';

export const createUserPreference = async ({
  name,
  user_id,
  value,
  accessToken
}: Omit<PreferencesBodyData, 'organization_id' | 'preference_id'> &
  APIServiceDefaultParams): Promise<
  BaaSServerResponse<{ preference: PreferencesDTO }>
> => {
  return APIService.getInstance({ api: 'dashboard', accessToken })
    .post(`/dashboard/preferences/user`, {
      name,
      value,
      user_id
    })
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error.response?.data;
    });
};

export const updateUserPreference = async ({
  preference_id,
  value
}: Pick<PreferencesBodyData, 'value' | 'preference_id'> &
  APIServiceDefaultParams): Promise<BaaSServerResponse> => {
  return APIService.getInstance({ api: 'dashboard' })
    .patch(`/dashboard/preferences/user/${preference_id}`, {
      value
    })
    .then((res) => res.data)
    .catch((error: AxiosError<BaaSServerResponse>) => {
      return error.response?.data;
    });
};
