export const change_password = {
  title: 'Change password',
  snackbar: {
    successes: {
      changePassword: {
        title: 'Password changed successfully'
      }
    },
    errors: {
      changePassword: {
        title: 'Error changing password'
      }
    }
  },
  form: {
    oldPassword: {
      label: 'Current password',
      placeholder: 'Current password',
      errors: {
        required: 'Current password is required',
        incorrectPassword: 'Incorrect password'
      }
    },
    newPassword: {
      label: 'New password',
      placeholder: 'New password',
      tooltip:
        'The password must contain at least 10 characters, an uppercase letter, a lowercase letter, a number and a special character',
      errors: {
        required: 'New password is required',
        minChars: 'Password must be at least 10 characters',
        regex: 'Wrong password requirements.'
      }
    },
    confirmNewPassword: {
      label: 'Confirm new password',
      placeholder: 'Confirm new password',
      errors: {
        required: 'Confirm new password is required',
        match: 'Passwords do not match'
      }
    },
    buttons: {
      submit: 'Change password',
      cancel: 'Cancel'
    }
  }
};
