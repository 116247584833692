/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeProvider } from '@mui/material';
import { ErrorBoundary } from '@sentry/nextjs';
import ErrorPage from 'components/molecules/ErrorPage';
import Snackbar, {
  SnackbarProps
} from 'components/molecules/Snackbar/Snackbar';
import {
  IS_DEV_ENV,
  IS_PROD_ENV,
  IS_SANDBOX_ENV
} from 'constants/environments';
import mixpanel from 'mixpanel-browser';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import Script from 'next/script';
import { SnackbarProvider } from 'notistack';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { MixpanelProvider } from 'react-mixpanel';
import '../styles/globals.css';

import { AuthProvider } from 'contexts/AuthContext';
import LanguageProvider from 'contexts/LanguageContext';
import moment from 'moment';
import { NextPage } from 'next';
import { Session } from 'next-auth';
import { AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';
import { darkTheme } from 'themes/dark-theme';
import i18n from '../i18n';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: any;
  session: Session;
};
function OurFallbackComponent({ error }) {
  const handleErrorButtonAction = () => {
    window.location.reload();
  };
  return (
    <ErrorPage
      errorTitle="A comet is passing nearby Earth"
      errorSubtitle="We have an interference in our systems "
      errorMessage={error.message}
      onClickButton={handleErrorButtonAction}
    />
  );
}
mixpanel.init(process.env.NEXT_PUBLIC_MIX_PANEL_TOKEN, {
  debug: IS_DEV_ENV,
  track_pageview: IS_SANDBOX_ENV || IS_PROD_ENV,
  secure_cookie: IS_SANDBOX_ENV || IS_PROD_ENV,
  persistence: 'cookie'
});
if (IS_DEV_ENV) {
  mixpanel.disable();
}
moment.locale(i18n.language);
function BaaS({ Component, pageProps, session }: AppPropsWithLayout) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Blockchain as a Service</title>
      </Head>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
        scriptProps={{
          async: true,
          defer: false,
          appendTo: 'head'
        }}
      >
        <SessionProvider
          session={session}
          refetchInterval={5 * 60}
          refetchOnWindowFocus
        >
          <ThemeProvider theme={darkTheme}>
            <SnackbarProvider
              autoHideDuration={10000}
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              content={(key, message) => (
                <Snackbar id={key} message={message as SnackbarProps} />
              )}
            >
              <ErrorBoundary fallback={OurFallbackComponent}>
                {/* <Script
                  src={`/js/new-relic-${process.env.NEXT_PUBLIC_ENV}.js`}
                /> */}
                <Script src="https://www.googletagmanager.com/gtag/js?id=G-S8W9RSGWHN" />
                <Script
                  id="google-analytics"
                  dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-S8W9RSGWHN');
            `
                  }}
                />
                <MixpanelProvider mixpanel={mixpanel}>
                  <LanguageProvider>
                    <AuthProvider>
                      {!!Component?.getLayout ? (
                        Component.getLayout(<Component {...pageProps} />)
                      ) : (
                        <Component {...pageProps} />
                      )}
                    </AuthProvider>
                  </LanguageProvider>
                </MixpanelProvider>
              </ErrorBoundary>
            </SnackbarProvider>
          </ThemeProvider>
        </SessionProvider>
      </GoogleReCaptchaProvider>
    </>
  );
}
export default BaaS;
