export const wallets_list_page = {
  breadcrumb_title: '{{blockchainName}}',
  total_of_wallets: '{{number}} Wallets',
  table: {
    headers: {
      with_custody: 'With custody'
    }
  },
  snackbar: {
    errors: {
      generic_error: 'Error on get wallets'
    }
  },
  filter_by_blockchain: 'Filter by Blockchain'
};
