export const change_password = {
  title: 'Alterar senha',
  snackbar: {
    successes: {
      changePassword: {
        title: 'Senha alterada com sucesso'
      }
    },
    errors: {
      changePassword: {
        title: 'Erro ao alterar senha'
      }
    }
  },
  form: {
    oldPassword: {
      label: 'Senha atual',
      placeholder: 'Senha atual',
      errors: {
        required: 'A senha atual é obrigatória',
        incorrectPassword: 'Senha incorreta'
      }
    },
    newPassword: {
      label: 'Nova senha',
      placeholder: 'Nova senha',
      tooltip:
        'A senha deve contar pelo menos 10 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
      errors: {
        required: 'A nova senha é obrigatória',
        minChars: 'A senha precisa ter ao menos 10 caracteres',
        regex: 'Requisitos de senha não atingidos.'
      }
    },
    confirmNewPassword: {
      label: 'Confirme a nova senha',
      placeholder: 'Confirme a nova senha',
      errors: {
        required: 'A confirmação de senha é obrigatória',
        match: 'As senhas não são iguais'
      }
    },
    buttons: {
      submit: 'Mudar senha',
      cancel: 'Cancelar'
    }
  }
};
