/* eslint-disable import/no-cycle */
import { Language } from 'contexts/LanguageContext';
import { HookCallbackFunctions } from 'hooks/types';
import i18n from 'i18n';
import { PreferencesDTO } from 'models/UserDTO';
import { useSession } from 'next-auth/react';
import { SnackbarMessage, useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { PreferencesBodyData } from 'services/api/schemas/PreferencesSchema';
import {
  createUserPreference,
  updateUserPreference
} from 'services/userService';
import useGlobalStateStore from 'store/global-state/useGlobalStateStore';

export interface IIntlHook {
  loading: boolean;
  sessionIntlPreference: PreferencesDTO;
  languageSelected: Language;
  languages: Language[];
  updateIntlPreference?: (
    body: Pick<PreferencesBodyData, 'value' | 'preference_id'>,
    callbacks?: HookCallbackFunctions
  ) => void;
  createIntlPreference?: (
    body: Omit<PreferencesBodyData, 'organization_id' | 'preference_id'>,
    callbacks?: HookCallbackFunctions
  ) => void;
}

export function useIntl(): IIntlHook {
  const { data: session } = useSession();
  // const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();
  const { setIntlPreference, intlPreference } = useGlobalStateStore();
  const languages = useMemo<Language[]>(() => {
    return [
      {
        iconSrc: '/images/flags/usa.png',
        label: 'settings_page.language_settings.language_options.en',
        value: 'en'
      },
      {
        iconSrc: '/images/flags/brazil.png',
        label: 'settings_page.language_settings.language_options.pt',
        value: 'pt'
      }
      // {
      //   iconSrc: '/images/flags/spain.png',
      //   label: 'settings_page.language_settings.language_options.es',
      //   value: 'es'
      // }
    ];
  }, []);

  const [intlPreferenceJustCreated, setIntlPreferenceJustCreated] =
    useState<PreferencesDTO>(null);

  const sessionIntlPreference = useMemo<PreferencesDTO>(() => {
    const sessionIntlPref = session?.user?.user_preferences?.find(
      (item) => item.name === 'INTL_LANG'
    );

    if (!sessionIntlPref) {
      return intlPreferenceJustCreated;
    }

    return sessionIntlPref;
  }, [session, languages, intlPreferenceJustCreated]);

  const [loading, setLoading] = useState(false);
  const [languageSelected, setLanguageSelected] = useState<Language>();
  // languages?.find(
  //   (item) =>
  //     router.locale?.includes(item.value) ||
  //     i18n.language?.includes(item.value)
  // )

  useEffect(() => {
    if (languageSelected) {
      if (intlPreference) {
        const langSelected = languages.find(
          (item) => item.value === intlPreference
        );
        setLanguageSelected(langSelected);
        i18n.changeLanguage(intlPreference);
        return;
      }

      i18n.changeLanguage(languageSelected.value);
    }

    if (!languageSelected && sessionIntlPreference) {
      const langSelected = languages.find(
        (item) => item.value === sessionIntlPreference.value
      );
      setLanguageSelected(langSelected);
    }

    // IF USER HAS NO INTL PREF CONFIG, SO IT SETS ENGLISH BY DEFAULT
    if (!languageSelected && !intlPreference && !sessionIntlPreference) {
      i18n.changeLanguage('en');
    }
  }, [languageSelected, sessionIntlPreference, intlPreference]);

  const updateIntlPreference = (
    body: Pick<PreferencesBodyData, 'value' | 'preference_id'>,
    callbacks?: HookCallbackFunctions
  ) => {
    const { onSuccess, onError } = callbacks ?? {};
    setLoading(true);

    updateUserPreference(body)
      .then((response) => {
        if (response?.success) {
          const langSelected = languages.find(
            (item) => item.value === body.value
          );
          setLanguageSelected(langSelected);
          setIntlPreference(langSelected.value);
          onSuccess?.(response);
          // FORCING THE WINDOW TAB VISIBILITY TO CHANGE AND UPDATE SESSION VALUES
          const event = new Event('visibilitychange');
          document.dispatchEvent(event);

          return;
        }

        enqueueSnackbar({
          type: 'error',
          description: response?.message_type,
          titleIntlPath: `snackbar_messages.error.${response?.message_type}`
        } as unknown as SnackbarMessage);
        onError?.(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createIntlPreference = (
    body: Omit<PreferencesBodyData, 'organization_id' | 'preference_id'>,
    { onSuccess, onError }: HookCallbackFunctions = {}
  ) => {
    createUserPreference(body)
      .then((response) => {
        if (response?.success) {
          const langSelected = languages.find(
            (item) => item.value === body.value
          );
          setLanguageSelected(langSelected);
          setIntlPreference(langSelected.value);
          setIntlPreferenceJustCreated(response?.data?.preference);
          onSuccess?.(response);
          // FORCING THE WINDOW TAB VISIBILITY TO CHANGE AND UPDATE SESSION VALUES
          const event = new Event('visibilitychange');
          document.dispatchEvent(event);

          return;
        }

        enqueueSnackbar({
          type: 'error',
          description: response?.message_type,
          titleIntlPath: `snackbar_messages.error.${response?.message_type}`
        } as unknown as SnackbarMessage);
        onError?.(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    languageSelected,
    sessionIntlPreference,
    languages,
    loading,
    createIntlPreference,
    updateIntlPreference
  };
}
