export const verify_account_page = {
  legal_entity: 'Legal entity',
  country: 'Country',
  name: 'Name',
  pep: 'PEP',
  document: 'Document',
  zip_code: 'Zip Code',
  address1: 'Address 1',
  address2: 'Address 2',
  city: 'City',
  state: 'State/Province',
  responsible_name: 'Legal responsible name',
  legal_informations: 'Legal information',
  legal_responsible: 'Legal responsible',
  social_contract: 'Social contract',
  partners: 'Partners',
  pep_description:
    'PEP: They are politically exposed people, the occupants of public positions and functions listed in the PLD/FTP rules.',
  front: 'Front',
  back: 'Back',
  add_partner: 'Add partner',
  send_docs: 'Send docs',
  verify_identity: 'Verify identity',
  my_account: 'My account',
  select: 'Select',
  document_image: 'Document Image',
  verify_account: 'Verify account',
  resend_docs: 'Resend documents',
  start_now: 'Start Now',
  verify_account_dialog: {
    why: 'Why?',
    how: 'How?',
    security: 'Security',
    send_documentos_to_approval: 'Send documents to approval',
    description_1:
      "In order to ensure the safety of our users, as well as comply with the country's rules and laws, we need to verify your account.",
    description_2:
      'This action is mandatory only if you want to carry out purchase, sale and exchange of crypto or deposits and withdrawals in fiat currency.',
    description_3:
      'The documentation sent will undergo an analysis and you will be able to track the status on your profile.'
  },
  send_documents_to_approval_dialog: {
    title: 'Send documents to approval',
    sub_title_1: 'The documents will undergo an analysis',
    description_1: 'We will send you an e-mail once we are done.',
    description_2: 'Also, you can track the status on your profile (',
    my_account: 'My account).',
    sub_title_2: 'Declaration of the truth for the information provided',
    declaration: `I, <emphasis>{{name}}</emphasis>, born in <emphasis>{{country}}</emphasis>, owner of ID <emphasis>{{cpf}}</emphasis>, DECLARE for the purpose of proving residence, under penalty of Brazilian law (article 2 of Law 7.11583), that I reside at the address <emphasis>{{address}}, {{UF}}</emphasis>. I also declare that I am aware that a false statement may result in the criminal penalty provided for in article 299 of the Brazilian Penal Code, which states: 'Article 299 - To omit, in a public or private document, a statement that should be included in it, or to insert or have a false or different statement inserted in it, with the intent to harm a right, create an obligation, or alter the truth about a legally relevant fact.' Penalty: imprisonment from 1 (one) to 5 (five) years and a fine if the document is public, and imprisonment from 1 (one) to 3 (three) years if the document is private. As it is the truth, I sign this.`,
    agree: 'Agree to the declaration',
    i_agree:
      'I agree to the declaration of the truth for the information provided.'
  },
  uploadPDF: 'Upload PDF',
  reuploadPDF: 'Reupload PDF'
};
