export const vaults_page = {
  breadcrumb_title: 'Vaults',
  no_vaults_created: 'No Vaults Created Yet',
  create_vault: 'Create Vault',
  transaction_polices: 'Transaction Policies',
  transaction_id: 'Transaction ID',
  approvers: 'Approvers',
  approvals: 'Approvals',
  setup: 'Setup',
  wallet_creation: 'Wallet creation',
  vault_password: 'Vault password',
  vault_name: 'Vault Name',
  give_your_vault_name: 'Give your vault name',
  users: 'Users',
  choose_users: 'Choose the users that will access this Vault',
  minimum_operators: 'Mininum Operators to Complete Transaction',
  minimum_operators_placehold: 'Set operators',
  max_time_to_approval: 'Time to complete transaction',
  max_time_to_approval_placehold: 'hours',
  minimum_operators_2: 'Minimum operators: 2',
  operators: 'Operators',
  choose_operators: 'Choose the users that will access this Vault',
  vault_password_info:
    'Vault passwords will be sent to participants after vault creation',
  choose_transaction_policies: 'Choose the Transaction Policies for this Vault',
  transaction_policies_info:
    'Set Transaction Policies in the <emphasis>Transaction Policies</emphasis> screen',
  create_transaction_policy: 'Create Transaction Policy',
  password_generate_info: 'The password is generated after this step.',
  password_info:
    'This is your vault password. <emphasis>Please, be aware that you need to store this password in order to use it later on.</emphasis>',
  agreement_password_info:
    'I understand that I need to store this Vault’s password.',
  status_labels: {
    pending: 'Waiting for operators',
    pending_wallets: 'Pending wallets',
    created: 'Created',
    canceled: 'Canceled'
  },
  transactions_status_labels: {
    pending: 'Pending',
    pending_execution: 'Pending execution',
    executed: 'Executed',
    canceled: 'Canceled'
  },
  vault_approval_dialog_title: 'Vault approval',
  transaction_policy_creation_informative:
    'To create a Vault, you need to create a Transaction Policy',
  vault_operator_password_info:
    'This is your vault password. <emphasis>Please, be aware that you need to store this password in order to use it later on.</emphasis>',
  vault_password_agreement:
    'I understand that I need to store this Wallet’s password.',
  copied_password_message: 'Password copied to clipboard',

  vault_summary: {
    asset: 'Asset:',
    amount: 'Amount:',
    time_frame: 'Time Frame:',
    secure_destinations: 'Secure Destinations:',
    approvar: 'Approval:',
    minimum_approvals: 'Minimum Approvals:',
    creation_date: 'Creation Date',
    created_by: 'Created by',
    and_more_addresses: 'and {{value}} more addresses',
    and_more_assets: 'and {{value}} more assets'
  },
  add_new_wallet: 'Add New Wallet',
  vault_created_successfully: 'Your Vault was successfully created',
  vault_approved_successfully: 'The vault was successfully approved',
  vault_rejected_successfully: 'The vault was successfully rejected',
  password_copied: 'Password copied to clipboard',
  monday_to_friday: 'Monday to Friday',
  validation_errors: {
    minimum_operators:
      'Minimum number of operators must be equal or less than to the total number of operators selected',
    minimum_operators_min: 'Number must be equal or greater than 2'
  },
  vaults_password: "Vault's password",
  vaults_password_placeholder: "Insert vault's password",
  transaction_created_successfully: 'Transaction was created successfully',
  transaction_confirmed_successfully: 'Transaction was confirmed successfully',
  transaction_approved_successfully: 'Transaction was approved successfully',
  insert_password: 'Insert Password',
  pending_transactions: 'Pending',
  transactions: 'Executed',
  transactions_on_chain: 'On chain',
  transaction_confirmation: 'Transaction Confirmation',
  amount_to_send: 'Amount to send',
  coin_network_fee: '{{value}} network fee',
  transaction_approval: 'Transaction Approval'
};
