export const webSiteEn = {
  Menu: {
    option1: 'Soluções',
    option2: 'Preço',
    option3: 'Comece agora',
    option4: 'Entrar',
    option5: 'Documentação'
  },
  TechnologyApplications: {
    title: 'Aplicações da tecnologia Blockchain',
    target: 'Público',
    advantages: 'Vantagens',
    wallets: {
      title: 'Carteiras',
      slug: 'wallets',
      cases: {
        wallet: {
          button: 'Carteira de criptomoedas',
          title: 'Carteira de criptomoedas com compra e venda',
          description:
            'Carteiras de cripto de mercado, carteiras de tokens próprios.',
          targets: {
            banks: 'Bancos',
            fintechs: 'Fintechs',
            game: 'Desenvolvedores de jogos',
            developers: 'Desenvolvedores de software'
          },
          advantages0: 'Facilidade na integração com corretoras de cripto',
          advantages1: 'Possibilidade de criar os próprios pools de liquidez'
        }
      }
    },
    tokens: {
      title: 'Tokenização',
      slug: 'assetTokenization',
      cases: {
        platform: {
          button: 'Tokenização',
          title: 'Plataforma de tokenização de ativos digitais',
          description:
            'Tokenizar um ativo do mundo real (ativos líquidos, crédito de carbono, etc).',
          targets: {
            banks: 'Bancos',
            fintechs: 'Fintechs',
            sustainability: 'Plataformas de sustentabilidade'
          },
          advantages0: 'Fracionar e negociar ativos não-fracionáveis',
          advantages1: 'Fracionar e negociar ativos ilíquidos'
        },
        digital: {
          button: 'Tokens digitais',
          title: 'Criação de tokens digitais',
          description:
            'Tokenizar créditos de carbono, tokenizar moedas de jogos.',
          targets: {
            game: 'Desenvolvedores de jogos',
            fintechs: 'Fintechs',
            sustainability: 'Plataformas de sustentabilidade'
          },
          advantages0:
            'Gestão de ativos digitais de forma transparente e segura'
        },
        gameCurrency: {
          button: 'Moeda para jogos',
          title: 'Moeda digital para jogos',
          description:
            'Um game poderia utilizar Carteiras de cripto para movimentar o dinheiro digital usado dentro do jogo, as empresas podem literalmente criar sua própria moeda dentro de uma Blockchain.',
          targets: {
            game: 'Desenvolvedores de jogos'
          },
          advantages0: 'Segurança',
          advantages1: 'Transparência'
        },
        crowdfunding: {
          button: 'Plataforma de crowdfunding',
          title: 'Plataforma de crowdfunding descentralizado',
          description:
            'Permitir que startups ou projetos de impacto social emitam tokens para arrecadar fundos através de uma campanha de crowdfunding na Blockchain.',
          targets: {
            startups: 'Startups',
            social: 'Projetos sociais',
            investors: 'Investidores'
          },
          advantages0: 'Acesso à financiamento global',
          advantages1:
            'Maior transparência nas transações e participação de investidores do mundo inteiro'
        },
        fidelity: {
          button: 'Programa de fidelidade',
          title: 'Programas de fidelidade baseados em Blockchain',
          description:
            'Empresas podem criar programas de fidelidade onde os pontos de recompensa são representados como tokens na Blockchain, possibilitando aos clientes transferir, trocar ou resgatar seus pontos com maior flexibilidade.',
          targets: {
            retail: 'Varejo',
            hospitality: 'Hotelaria',
            airline: 'Companhia aérea'
          },
          advantages0: 'Redução de custos operacionais',
          advantages1: 'Maior engajamento dos clientes',
          advantages2: 'Rastreabilidade das transações'
        },
        reward: {
          button: 'Programa de recompensa',
          title: 'Programas de recompensa para funcionários',
          description:
            'Empresas podem implementar programas de incentivo para seus colaboradores, onde as recompensas são concedidas em forma de tokens que podem ser utilizados em um marketplace interno ou trocados por benefícios.',
          targets: {
            companies: 'Empresas de todos os setores'
          },
          advantages0: 'Maior motivação dos funcionários',
          advantages1: 'Acompanhamento transparente do desempenho',
          advantages2:
            'Redução de custos com programas de benefícios tradicionais'
        }
      }
    },
    nfts: {
      title: 'NFTs',
      slug: 'nfts',
      cases: {
        platform: {
          button: 'Plataforma de NFTs',
          title: 'Plataforma de NFTs',
          description:
            'Permitir que as empresas armazenem e exibam seus NFT em um marketplace, uma plataforma digital para compra e venda de NFT.',
          targets: {
            banks: 'Bancos',
            fintechs: 'Fintechs',
            others: 'Outros'
          },
          advantages0: 'Segurança',
          advantages1: 'Transparência',
          advantages2: 'Fluxo automatizado'
        },
        tickets: {
          button: 'Ingressos como NFT',
          title: 'Ingressos como NFT',
          description:
            'Um marketplace de eventos com ingressos NFT que permite que artistas e empresas estabeleçam conexões mais fortes com seu público, ao mesmo tempo que erradica a fraude e minimiza os efeitos do scalping.',
          targets: {
            concerts: 'Shows',
            movies: 'Cinemas',
            others: 'Outros'
          },
          advantages0: 'Segurança',
          advantages1: 'Transparência',
          advantages2: 'Ingressos como colecionáveis'
        },
        art: {
          button: 'Arte digital',
          title: 'Mercado de arte digital',
          description:
            'Criar um mercado online para artistas digitais venderem suas obras como NFTs, permitindo a autenticação, propriedade e revenda das criações de forma transparente na Blockchain.',
          targets: {
            artists: 'Artistas digitais',
            collectors: 'Colecionadores de NFT'
          },
          advantages0: 'Maior alcance para artistas independentes',
          advantages1: 'Proteção contra falsificações de obras de arte',
          advantages2: 'Comissões automatizadas para os artistas nas revendas'
        }
      }
    },
    trade: {
      title: 'Trade & Swap',
      slug: 'tradeSwap',
      cases: {
        broker: {
          button: 'Corretora de ativos',
          title: 'Corretora de ativos digitais',
          description:
            'Tokenizar um ativo do mundo real (ativos ilíquidos, crédito de carbono, etc).',
          targets: {
            companies:
              'Empresas que querem criar uma corretora de ativos digitais'
          },
          advantages0: 'Segurança',
          advantages1: 'Transparência'
        },
        custody: {
          button: 'Custódia de ativos',
          title: 'Custódia de ativos digitais',
          description: 'Assegurar ativos digitais com custódia de alto nível.',
          targets: {
            banks: 'Bancos',
            funds: 'Fundos de investimento'
          },
          advantages0: 'Segurança',
          advantages1: 'Transparência'
        }
      }
    },
    buttons: {
      contact: 'Fale conosco'
    }
  },
  BannerSection: {
    banner1: {
      titleComplete:
        'Infraestrutura para conectar empresas à Blockchain e integrar soluções mais seguras e eficientes por meio de APIs',
      title: 'Infraestrutura para conectar empresas à Blockchain',
      title2: 'e integrar soluções mais seguras e eficientes',
      title3: 'por meio de APIs',
      button: 'Nossos serviços',
      button2: 'Serviço para empresas'
    },
    banner2: {
      title: 'Unlimited Cryptocurrency Digital Wallets creation',
      description:
        'Easy to implement on your platform just copying and pasting codes',
      button: 'Learn more'
    },
    banner3: {
      title: 'Increase productivity in asset tokenization',
      description: 'Hassle-free asset tokenization on major networks',
      button: 'Learn more'
    },
    banner4: {
      title: 'Cost-cutting to create your own NFTs',
      description:
        'Easy to use Toolkit to create tokens reducing operating costs',
      button: 'Learn more'
    }
  },
  CardSection: {
    title: 'Desenvolvimento Web 3.0',
    description: 'Pronto para o futuro',
    card1: {
      title: 'Facilidade',
      description:
        'Abstração da complexidade da Blockchain, simplificando a implementação de ecossistemas digitais em poucos cliques.'
    },
    card2: {
      title: 'Economia',
      description:
        'Rápida integração de serviços, economizando tempo para atividades essenciais e reduzindo custos operacionais, onde você só paga pelo que consome.'
    },
    card3: {
      title: 'Acessibilidade',
      description:
        'Conveniência para acesso à Blockchain, fornecendo fluxos rápidos e ferramentas intuitivas para os usuários sem conhecimento técnico.'
    }
  },
  NetworksSection: {
    title:
      'Construa soluções com + de 10.000 ativos através do Blockchain as a Service',
    description: 'As principais Blockchains do mercado.',
    titlePart1on720: 'Construa soluções com + de 10.000 ativos',
    titlePart2on720: 'através do Blockchain as a Service'
  },
  StartNowSection: {
    title: 'Dúvidas?',
    description:
      'Preencha as informações abaixo e entraremos em contato com você'
  },
  UseCasesSection: {
    title: 'Conheça soluções que já estão sendo utilizadas por nossos clientes',
    description:
      'Integre sua empresa com todos os benefícios da Web3.0 com Blockchain as a Service.',
    banking_and_finance: 'Bancos e finanças',
    banking_and_finance_description:
      'Plataforma de carteira e token de ativos digitais',
    use_case_text:
      'Conectamos mais de 64 milhões de pessoas ao universo das criptomoedas, prestando serviços de abertura de carteiras digitais e transações de ativos, por meio da primeira instituição bancária a operar no Brasil, com mais de 200 anos de existência.'
  },
  InvestorsSection: {
    title: 'Eles acreditam em nós',
    investors: 'Investidores:',
    accelerated_by: 'Acelerado por:'
  },
  Footer: {
    social_media_text: 'Nos siga nas redes sociais',
    services_status: 'Status dos serviços',
    description1: 'Bitfy - Todos os direitos reservados – {{year}}',
    description2:
      'Bitfy Intermediações e Serviços de Pagamentos LTDA. CNPJ: 31.255.976/0001-39'
  },
  ApiDocsSection: {
    title: 'API & docs',
    subTitle: 'Integração simples via API',
    description:
      'Disponível nas principais plataformas de hospedagem de código, para desenvolvedores integrarem os serviços de forma simples e otimizada.',
    button: 'Ver documentação'
  },
  StartTutorial: {
    title: 'How to get started?',
    description: '3 simple steps!',
    step1: {
      title: 'Create an account',
      description:
        'By creating a new account you have access to our platform and our technologies. You can create a new account here.'
    },
    step2: {
      title: 'Choose the plan',
      description:
        'We have 4 plans for you to choose what best suits you or you company. You can see all available plans here.'
    },
    step3: {
      title: 'Make the payment',
      description:
        'Our platform allows users to pay via credit card and all our transactions are secure.'
    },
    step4: {
      title:
        'And we are done! You are now ready to start using our technologies.'
    },
    buttons: {
      anyDoubts: 'Any doubts?',
      faq: 'FAQ'
    }
  },
  Services: {
    title: 'Serviços de ponta',
    subTitle: 'One-stop-shop Hub.',
    card0: {
      title: 'Carteiras digitais',
      subTitle: 'Implementação fácil na tecnologia Blockchain.',
      description:
        'Carteiras digitais para armazenamento e/ou transações ponto-a-ponto, fáceis de implementar em sua plataforma através de uma integração simples e objetiva. Disponíveis para mais de 10.000 ativos em diversas Blockchains, oferecendo a possibilidade de as empresas criarem carteiras de auto custódia ou não custodiante.'
    },
    card1: {
      title: 'Tokens',
      subTitle: 'Tokenização de ativos, rápido e fácil.',
      description:
        'Aumente sua produtividade minimizando os riscos e esforço tecnológico excessivo, enquanto cria tokens e contratos inteligentes em um instante, tudo automatizado, nas principais Blockchains do mercado.'
    },
    card2: {
      title: 'NFTs',
      subTitle: 'Disponível para principais Blockchains do mercado.',
      description:
        'Kit de ferramentas fácil de usar para empresas criarem seus próprios NFTs com mais rapidez e segurança, com base em qualquer rede suportada pelo BaaS, abstraindo as dificuldades de conectar blockchains e a aplicação, reduzindo custos operacionais e de infraestrutura.'
    },
    card3: {
      title: 'Swap',
      subTitle: 'Redistribuição do portfólio de criptomoedas.',
      description:
        'Facilidade na troca de ativos de maneira conveniente, permitindo que os clientes redistribuam seu portfólio de criptomoedas em um instante, reduzindo os custos de transação.'
    },
    card4: {
      title: 'Trade',
      subTitle: 'Compra e venda de ativos de forma simplificadas.',
      description:
        'Permita que seus clientes comprem e vendam ativos de forma rápida e automatizada, permitindo que as empresas transacionem as principais criptomoedas do mercado.'
    },
    card5: {
      title: 'Webhook',
      subTitle: 'Abstraia a complexidade de monitorar carteiras na Blockchain.',
      description:
        'Mantenha seu cliente informado sobre qualquer tipo de transação dentro da blockchain com a nossa plataforma de notificação, reduzindo gastos desnecessários enquanto monitora transações na blockchain.'
    }
  },
  Pricing: {
    title: 'Preço',
    subTitle: 'Escolha um dos planos, ou entre em contato conosco',
    yearly: 'Anual',
    monthly: 'Mensal',
    availablePlans: 'Planos disponíveis',
    contact:
      'Entre em contato conosco e tenha o melhor plano para sua empresa.',
    button: 'Começar',
    buttonContact: 'Fale conosco'
  },
  Contact: {
    send_email: 'Enviar email',
    contact_by_email: 'Contate-nos via e-mail',
    title: 'Contato',
    form: {
      name: 'Nome',
      email: 'E-mail',
      phone: 'Telefone',
      message: 'Mensagem',
      messagePlaceholder: 'Digite sua mensagem',
      button: 'Enviar'
    }
  },
  FAQ: {
    description: 'Perguntas frequentes',
    question0: {
      title: 'Existe um teste gratuito disponível?',
      content:
        'Sim, basta clicar em "Comece agora" no meu superior para ir ao ambiente sandbox e se iniciar seu teste gratuito.'
    },
    question1: {
      title: 'Posso mudar de plano mais tarde?',
      content:
        'Sim. Depois de fazer login, você poderá ver seu plano atual e também escolher outro plano que melhor se adapte a você ou sua empresa.'
    },
    question2: {
      title: 'Como funciona o faturamento?',
      content:
        'Nossa plataforma utiliza cartão de crédito como principal opção de pagamento. Você pode optar por pagar mensalmente ou anualmente.'
    },
    question3: {
      title: 'Essas transações são seguras?',
      content:
        'Sim, essas transações são seguras e nossa equipe trabalha consistentemente para manter a privacidade de suas informações.'
    },
    question4: {
      title: 'Eu já tenho uma conta. o que fazer a seguir?',
      content:
        'Se você já possui uma conta, você pode fazer login em nossa plataforma e escolher um de nossos planos para começar a utilizar nossas tecnologias.'
    }
  },
  GetStarted: {
    title: 'Como começar?',
    description: '3 passos simples!',
    step0: {
      title: 'Crie sua conta',
      description:
        'Ao criar uma nova conta você tem acesso à nossa plataforma e às nossas tecnologias. Você pode criar uma nova conta',
      here: 'aqui'
    },
    step1: {
      title: 'Escolha um plano',
      description:
        'Temos alguns planos para você escolher o que melhor combina com você ou sua empresa. Você pode ver todos os planos disponíveis',
      here: 'aqui'
    },
    step2: {
      title: 'Faça o pagamento',
      description:
        'Nossa plataforma permite que os usuários paguem com cartão de crédito e todas as nossas transações são seguras.'
    },
    done: 'Pronto! Agora você está pronto para começar a usar nossas tecnologias.',
    faq: 'FAQ',
    doubts: 'Alguma dúvida?'
  },
  Sandbox: {
    title: 'Ambiente de Sandbox',
    description:
      'Quer conhecer a solução? Acesse nosso ambiente de sandbox. Sem custos envolvidos.',
    testNow: 'Iniciar teste'
  },
  Cookie: {
    warning_text:
      'Usamos cookies de terceiros para personalizar o conteúdo e analisar o tráfego do site.',
    learn_more: 'Saber mais',
    accept: 'Aceitar'
  }
};
