export const swap_list_page = {
  breadcrumb_title: 'Troca',
  page_description:
    'Tem BTC e precisa de ETH? Não precisa vender e comprar, faça TROCA.',
  table: {
    title: 'Transações',
    header: {
      converted_to: 'Convertido para',
      source_blockchain: 'Blockchain de origem',
      destination_blockchain: 'Blockchain de destino',
      converted_value: 'Valor convertido',
      source_hash: 'Hash de origem',
      destination_hash: 'Hash de destino'
    }
  },
  buttons: {
    swap: 'Trocar'
  }
};
