export const new_swap_page = {
  breadcrumb_title: 'New swap',
  form: {
    title: 'I want to swap',
    title2: 'Convert to',
    header: {
      converted_to: 'Converted to',
      source_blockchain: 'Source blockchain',
      destination_blockchain: 'Destination blockchain',
      converted_value: 'Converted value',
      source_hash: 'Source hash',
      destination_hash: 'Destination hash'
    },
    errors: {
      insufficient_funds: 'Insufficient funds. Consider the network fee.'
    }
  },
  dialog: {
    title: 'Swap Cryptocurrency'
  },
  buttons: {
    swap_crypto: 'Swap crypto'
  }
};
