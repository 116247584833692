export const nft_collections_list_page = {
  breadcrumb_title: 'NFT',
  collections: '{{value}} coleções',
  collection: '{{value}} coleção',
  create_collection: 'Criar coleção',
  empty_state: {
    title: 'Coleções',
    description:
      'Uma coleção é usada para agrupar NFTs com o mesmo estilo artístico. Crie uma coleção para começar a enviar seus arquivos.'
  },
  dialog: {
    title: 'Nova coleção',
    fields: {
      name: 'Nome',
      description: 'Descrição',
      blockchain: 'Blockchain',
      symbol: 'Símbolo',
      wallet_reference: 'Referência da carteira'
    }
  },
  collection_name_summary_label: 'Nome da coleção',
  collection_description_summary_label: 'Descrição da coleção',
  collection_symbol_summary_label: 'Símbolo da coleção',
  snackbar: {
    on_create: 'Coleção criada.'
  }
};
