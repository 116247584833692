export const webhooks = {
  breadcrumb_title: 'Custom webhooks',
  form: {
    title: 'New custom webhook',
    description:
      'You can set default values for URL and token on the <emphasis>Settings</emphasis> page.',
    url: 'URL (optional)',
    authorization_token: 'Authorization token (optional)',
    event_type: 'Event type',
    incoming: 'Incoming',
    all: 'all'
  },
  buttons: {
    create_webhook: 'Create Webhook'
  }
};
