export const buy_and_sell_page = {
  breadcrumb_title: 'Buy & Sell',
  page_description:
    'Have you ever created wallets? Trade the cryptocurrencies you own. Buy and sell any digital asset.',
  table: {
    title: 'Transactions',
    header: {
      transaction: 'Transaction',
      amount: 'Amount to receive',
      pair: 'Pair',
      confirmations: 'Confirmations',
      wallet_id: 'Wallet ID',
      hash: 'Hash'
    }
  },
  forms: {
    buy_form: {
      title: 'Buy Cryptocurrency',
      fields: {
        price: '{{networkName}} price',
        price_no_label: 'Price'
      },
      helper_texts: {
        last_update: 'Updated {{minutes}} minutes ago'
      }
    },
    sell_form: {
      title: 'Sell Cryptocurrency',
      fields: {
        price: '{{networkName}} price',
        value: 'Value to sell',
        price_no_label: 'Price'
      },
      helper_texts: {
        last_update: 'Updated {{minutes}} minutes ago'
      }
    }
  },
  buttons: {
    buy: 'Buy',
    sell: 'Sell'
  },
  recipient_wallet: 'Recipient wallet',
  status: {
    confirmed: 'Confirmed',
    pending: 'Pending',
    created: 'Created',
    failed: 'Failed',
    canceled: 'Canceled'
  }
};
