/* eslint-disable import/no-cycle */
import { Typography } from '@mui/material';
import { TextTagProps } from 'components/atoms/Text/Text';

const SEPARATOR = '(-░█╬¾┬║-)';

export const replaceStringWithTag = (
  text: string,
  Tags: Record<string, TextTagProps> = {}
) => {
  const keys = Object.keys(Tags);

  if (keys.length === 0 || Object.keys(Tags).length === 0) {
    return text;
  }

  const tagsInText = [...new Set(text.match(/<.+?>(.*?)<\/.+?>/g) || [])];

  let textTmp = text;

  tagsInText.forEach((x) => {
    textTmp = textTmp.replace(x, `${SEPARATOR}${x}${SEPARATOR}`);
  });

  const textSplitted = textTmp
    .split(`${SEPARATOR}`)
    .filter((str) => str !== '');

  const result = textSplitted.map((str, index) => {
    const [, key, content] = /<(.+)>(.*?)<\/.+>/g.exec(str) ?? [];

    if (key == null) {
      return str;
    }

    if (!Tags[key]) {
      return content;
    }

    const fontWeight = Tags[key]?.fontWeight;
    const typography = Tags[key]?.typography;
    const color = Tags[key]?.color;
    const onClick = Tags[key]?.onClick;
    const textDecorationLine = Tags[key]?.textDecorationLine;

    return (
      <Typography
        key={index}
        component="span"
        fontWeight={fontWeight}
        color={color}
        onClick={
          onClick
            ? () => {
                onClick(index);
              }
            : undefined
        }
        style={{
          textDecorationLine
        }}
        {...typography}
      >
        {content}
      </Typography>
    );
  });

  return result;
};
