export const integrations_page = {
  my_integrations_tab_label: 'My integrations',
  browser_tab_label: 'Browser',

  integrations_services: {
    fin_conecta: {
      title: 'FinConecta',
      description:
        'FinConecta streamlines integration between financial institutions and fintechs, expanding service capabilities.',
      service_overview:
        'FinConecta banking integration feature simplifies access to real-time account information directly on our platform. With a secure API connection, users can seamlessly view their bank balance and transaction history without the hassle of separate logins or queries.',
      feature_1: 'Real-time access to bank balance',
      feature_2: 'Seamless integration, no additional logins required',
      feature_3: 'Easy viewing of transaction history'
    }
  },

  add_integration_dialog: {
    title: 'Connect your account'
  },

  remove_service_dialog: {
    title: 'Remove API key',
    description: 'Are you sure you want to delete the API key?'
  },

  overview_label: 'Overview',
  features_label: 'Features',

  secret_label: 'Secret',
  secret_placeholder: 'Insert secret',
  account_id_label: 'Account ID',
  account_id_placeholder: 'Insert your account ID',
  api_key_label: 'API Key',
  api_key_placeholder: 'Insert API key',
  username_label: 'Username',
  username_placeholder: 'Insert your username',
  password_placeholder: 'Insert your password',

  status_labels: {
    connected_status: 'Connected',
    not_connected_status: 'Not connected'
  },

  buttons: {
    save_api_key: 'Save API key',
    remove_api_key: 'Remove API key'
  },

  snackbar_messages: {
    success: {
      add_integration: 'Integration added successfully',
      remove_integration: 'Integration disconnected'
    },
    error: {
      add_integration: 'Error on connect integration',
      remove_integration: 'Error on disconnect integration'
    }
  }
};
