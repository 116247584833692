export const snackbar_messages = {
  error: {
    url_update: 'Error on update URL',
    get_aplication: 'Error on get applications.',
    create_credencials: 'Error on create credentials.',
    delete_credencials: 'Error on delete the credentials.',
    new_application: 'Error on add an application.',
    remove_application: 'Error on remove an application.',
    get_credencials: 'Error on get credentials.',
    add_ip: 'Error on add ip.',
    delete_id: 'Error on delete id.',
    get_ip: 'Error on get ip.',
    get_public_key: 'Error on get Public key.',
    add_public_key: 'Error on add Public key.',
    change_public_key: 'Error on change Public key.',
    get_webhooks: 'Error on get webhooks.',
    resend_confirmation_code_failed: 'Failed to send confirmation code.',
    get_network: 'Error on get blockchain networks.',
    get_by_id: 'Error on get blockchain network by id.',
    withdraw: 'Error on withdraw',
    get_qr_code: 'Error on get qr code',
    get_pix: 'Error on get pix information',
    get_withdraw: 'Error on withdraw intentions',
    get_deposit: 'Error on get deposit intentions',
    get_exchange: 'Error on get crypto exchanges list',
    get_quote: 'Error on get crypto quote',
    post_exchange: 'Error on purchase crypto',
    get_tradable_postman: 'Error on get tradable tokens',
    update_intl: 'Error on update preferences.',
    create_preference: 'Error on create preference.',
    get_list_moviments: 'Error on get transactions list.',
    get_balance: 'Error on update balance',
    create_collection: 'Error on create collection.',
    create_nft: 'Error on create NFT.',
    transfer_nft: 'Error on transfer NFT.',
    create_org_preference: 'Error on creating preference.',
    update_preference: 'Error on updating preference.',
    get_org: 'Error on get org preferences.',
    get_fees: 'Error on get fees.',
    create_transaction: 'Error on send cryptocurrency.',
    get_plan_historic: 'Error on get plan usage data.',
    get_plans: 'Error on get plans',
    get_plans_payment: 'Error on get plans payment',
    purchase_plan: 'Error on purchase plan.',
    update_credit_card: 'Error on update credit card',
    cancel_plan: 'Error on cancel plan',
    interact_contract: 'Error on execution',
    get_assets_list: 'Error on get assets list.',
    get_assets_template_list: 'Error on get smart contracts.',
    get_draft: 'Error on get draft',
    get_smart_contract: 'Error on get smart contract template by id.',
    save_draft: 'Error on saving as draft',
    update_draft: 'Error on updating draft',
    deploying_contract: 'Error on deploying contract',
    saving_contract: 'Error on saving contract fields',
    update_template_contract: 'Error on saving contract fields',
    swap_preview: 'Error on get swap preview',
    get_swap_transaction: 'Error on get swap transactions',
    swap_crypto: 'Error on swap cryptocurrency.',
    default_tokens: 'Error on get default tokens.',
    get_tokens_contract: 'Error on get token contract.',
    add_token_contract: 'Error on add token contract.',
    delete_token_contract: 'Error on delete token contract.',
    verifying_identity: 'Error on verifying identity',
    create_network_wallet: 'Error on create new wallet.',
    get_wallets: 'Error on get wallets.',
    get_wallet_token_balance: 'Error on get wallet token balance.',
    get_wallet_token_history: 'Error on get wallet token history.',
    creating_webhook: 'Error on creating webhook.',
    get_webhooks_by_network: 'Error on get webhooks count by network.',
    pix_not_found: 'Pix key not found',
    POLICY_ALREADY_IN_USE_EXCEPTION:
      'It was not possible to update this policy as it is already in use in a vault.',
    REQUEST_ERROR_EXCEPTION:
      'An error occurred, please try again in a few moments',
    INTERNAL_SERVER_ERROR:
      'An error occurred, please try again in a few moments',
    INPUT_VALIDATION_EXCEPTION:
      'Error processing the request, check the data entered',
    InvalidCodeException: 'Invalid code',
    TRANSACTION_FAILURE_EXCEPTION:
      'Error processing the transaction, please try again in a few moments',
    ORGANIZATION_ID_NOT_FOUND_EXCEPTION: 'Invalid organization',
    INCORRECT_PASSWORDS_EXCEPTION: 'Invalid passwords',
    UNAUTHORIZED_TRANSACTION_EXCEPTION: 'Invalid operation',
    INSUFFICIENT_BALANCE_EXCEPTION: 'Insufficient funds',
    UNEXPECTED_PROVIDER_RESPONSE_EXCEPTION:
      'An error occurred, please try again in a few moments',
    NOT_FOUND_EXCEPTION: 'Resource not found',
    HTTP_EXCEPTION: 'An error occurred, please try again in a few moments',
    BAD_STORE_EXCEPTION: 'Error storing the file',
    INVALID_FILE_FORMAT_EXCEPTION: 'Invalid file format',
    INVALID_PASSWORDS_EXCEPTION: 'Invalid passwords',
    INVALID_PASSWORD_EXCEPTION: 'Invalid password',
    InputValidationException:
      'Error processing the request, check the data entered',
    RESOURCE_NOT_FOUND_EXCEPTION: 'Resource not found',
    STORE_NOT_ALLOWED_EXCEPTION: 'Error storing the file',
    INVALID_AUTH_TOKEN_EXCEPTION: 'Invalid authentication token',
    RESOURCE_CONFLICT_EXCEPTION: 'The resource provided is invalid',
    INVALID_PERMISSION_EXCEPTION:
      'You do not have permission to perform this action',
    UNPROCESSABLE_ENTITY_EXCEPTION:
      'An error occurred, please try again in a few moments',
    EXTERNAL_API_EXCEPTION:
      'An error occurred, please try again in a few moments',
    INCORRECT_PASSWORD_EXCEPTION: 'Invalid password',
    INVALID_WALLET_ADDRESS_EXCEPTION: 'The wallet address provided is invalid',
    NETWORK_NOT_FOUND_EXCEPTION: 'Invalid blockchain network',
    WRONG_FIELD_INPUT_EXCEPTION: 'The parameters entered are invalid',
    CUSTOM_WEBHOOK_ALREADY_EXISTS_EXCEPTION:
      'This address is already being monitored',
    MISSING_DEFAULT_WEBHOOK_EXCEPTION:
      'Default Webhook settings were not found',
    WEBHOOK_NOT_ENABLED_EXCEPTION:
      'This service is not enabled for that blockchain network',
    UserAlreadyExistsException: 'User already exists',
    ASSET_POLICY_RULE_EXCEPTION:
      "The vault's policy does not allow sending the specified currency",
    SECURE_DESTINATIONS_POLICY_RULE_EXCEPTION:
      "The vault's policy does not allow transactions to the address provided",
    AMOUNT_POLICY_RULE_EXCEPTION:
      "The vault's policy does not allow sending the specified amount"
  },
  success: {
    api_key_deleted: 'API key deleted successfully.',
    handle_credencials: 'API key deleted successfully.',
    remove_application: 'Application removed successfully',
    create_public_key: 'Public key changed.',
    send_crypto: 'Crypto sent successfully.',
    url_added: 'URL added successfully.',
    url_updated: 'URL updated successfully.',
    transaction_created: 'Transaction created successfully',
    plan_purchase: 'Plan purchased successfully.',
    import_contract: 'Contract successfully imported',
    swap: 'Swap created successfully.',
    delete_token: 'Token contract deleted.',
    new_wallet: 'New wallet created.',
    webhook: 'Webhook created.',
    credencials_created: 'Successfully created credentials.',
    new_application: 'New application created successfully.',
    verify_code: 'Code verified successfully.',
    credit_card_update: 'Credit card updated successfully.',
    cancel_plan: 'Plan canceled successfully.',
    token_contract: 'Token contract added.',
    add_token_contract: 'Token contract added.',
    resend_confirmation_code: 'Confirmation code resent.',
    member_invite: 'Invite sent successfully',
    member_delete: 'User deleted successfully'
  }
};
