/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ParsedUrlQuery } from 'querystring';

export interface PaginationInfo {
  page?: number;
  perPage?: number;
}
export interface PagingResponse<T = any> {
  total: number;
  last_page: number;
  current_page: number;
  current_per_page: number;
  items?: T[];
}

export interface BaaSServerResponse<T = any> {
  items?: any;
  success?: boolean;
  message_type?: string;
  messageType?: string;
  message?: string;
  data?: T;
}

export interface APIServiceDefaultParams<T = unknown> {
  accessToken?: string; // Required for server side requests
  orgId?: number;
  page?: number;
  perPage?: number;
  query?: ParsedUrlQuery;
  body?: T;
  filter?: string | URLSearchParams;
  orderBy?: string;
}

type API = 'dashboard' | 'authentication' | 'telemetry' | 'cash-flow' | 'vault';

export interface IAPIServiceInstance {
  api: API;
  orgId?: number;
  accessToken?: string; // It's used only for server-side requests to get the access token.
}

class APIService {
  public service: AxiosInstance;

  constructor({ api, orgId, accessToken }: IAPIServiceInstance) {
    if (accessToken || orgId) {
      // REQUEST FROM SERVER-SIDE
      this.service = axios.create({
        baseURL: this.getAPIBaseURL(api),
        timeout: 36000
      });

      if (orgId)
        this.service.defaults.headers.common['x-organization-id'] = orgId;

      if (accessToken)
        this.service.defaults.headers.common['x-auth-token'] = accessToken;

      this.service.interceptors.request.use(this.handleServerSideRequest);
    } else {
      // REQUEST FROM CLIENT-SIDE
      this.service = axios.create({
        baseURL: process.env.NEXT_PUBLIC_CLIENT_BASE_URL,
        timeout: 36000
      });
    }
  }

  async handleServerSideRequest(config: AxiosRequestConfig) {
    const { url } = config;

    // IT REMOVES THE FIRST PART OF URL (WHICH REFERS TO NEXT API ROUTE) FOR SERVER-SIDE REQUESTS
    const urlSplit = url.split('/');
    const newUrl = url.replace(`/${urlSplit[1]}`, '');

    return { ...config, url: newUrl };
  }

  private getAPIBaseURL(api: API): string {
    if (api === 'authentication') {
      return process.env.AUTHENTICATION_API_BASE_URL;
    }
    if (api === 'telemetry') {
      return process.env.TELEMETRY_API_BASE_URL;
    }
    if (api === 'cash-flow') {
      return process.env.CASH_FLOW_API_BASE_URL;
    }
    if (api === 'vault') {
      return process.env.VAULT_API_BASE_URL;
    }
    return process.env.DASHBOARD_API_BASE_URL;
  }

  public static getInstance({
    api,
    orgId,
    accessToken
  }: IAPIServiceInstance): APIService {
    return new APIService({ api, orgId, accessToken });
  }

  get<T>(
    path: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<BaaSServerResponse<T>>> {
    return this.request(path, null, { ...options, method: 'GET' });
  }

  delete<T, R = any>(
    path: string,
    payload?: T,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<BaaSServerResponse<R>>> {
    return this.request(path, payload, { ...options, method: 'DELETE' });
  }

  patch<T, R = any>(
    path: string,
    payload: T,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<BaaSServerResponse<R>>> {
    return this.request(path, payload, { ...options, method: 'PATCH' });
  }

  put<T, R = any>(
    path: string,
    payload: T,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<BaaSServerResponse<R>>> {
    return this.request(path, payload, { ...options, method: 'PUT' });
  }

  post<T, R = any>(
    path: string,
    payload?: T,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<BaaSServerResponse<R> | R>> {
    return this.request(path, payload, { ...options, method: 'POST' });
  }

  request<T, R = any>(
    path: string,
    payload: T = undefined,
    options: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<BaaSServerResponse<R>>> {
    const config: AxiosRequestConfig = {
      responseType: 'json',
      url: path,
      data: payload,
      ...options,
      headers: { ...options.headers }
    };

    return this.service.request(config);
  }
}

export default APIService;
