/* eslint-disable import/no-cycle */
import { TextTagProps } from 'components/atoms/Text/Text';
import { en } from 'i18n/locales/en';
import { pt } from 'i18n/locales/pt';
import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/pt-br';
import { initReactI18next } from 'react-i18next';
import { Leaves } from 'types';
import { replaceStringWithTag } from 'utils/replaceStringWithTag';

const translations = {
  pt,
  en
};

i18n.use(initReactI18next).init({
  resources: translations,
  nsSeparator: '.',
  detection: {
    order: ['navigator', 'localStorage', 'cookie']
  },
  keySeparator: false,
  fallbackLng: 'pt',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;

export interface TranslateInterpolationType {
  [key: string]: string;
}

export const setTranslationLocale = (language: string) => {
  moment.locale(language);
  i18n.changeLanguage(language);
};

export const translate = (
  str: TranslationValuePath,
  options?: TranslateInterpolationType
) => i18n.t(str, { ...(options || {}) });

export const translateWithTags = (
  str: TranslationValuePath,
  options?: TranslateInterpolationType,
  tags?: Record<string, TextTagProps>
) =>
  replaceStringWithTag(
    i18n.t(str, {
      ...options,
      interpolation: {
        escapeValue: false
      }
    }),
    tags
  );

export type TranslationValuePath = Leaves<typeof en>;
