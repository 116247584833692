export const asset_tokenization_home_page = {
  breadcrumb_title: 'Asset tokenization',
  total_of_assets: 'Assets',
  table: {
    headers: {
      contract_address: 'Contract address'
    }
  },
  import_asset_dialog_title: 'Import asset',
  snackbar: {
    success: {
      on_import: 'Asset imported.'
    },
    errors: {
      on_import: 'Error on import the asset.'
    }
  }
};
