export const support = {
  breadcrumb_title: 'Suporte',
  contact: 'Contato',
  contact_us: 'Fale conosco via email',
  frequently_asked_questions: 'Perguntas frequentes',
  your_question: 'Sua pergunta será respondida por especialistas.',
  send_email: 'Enviar e-mail',
  wallets: {
    title: 'Carteiras',
    question_1: {
      title: 'O que é carteira',
      description:
        'As carteiras armazenam suas chaves privadas, mantendo suas criptomoedas seguras e acessíveis. Elas também permitem o envio, recebimento, e o uso de criptomoedas como Bitcoin Ethereum.'
    },
    question_2: {
      title: 'Como criar uma carteira',
      description:
        'Para criar uma carteira, o usuário deve escolher a moeda desejada e/ou clicar no botão "Criar", que está localizado no cabeçalho da página. O usuário deve preencher todas as informações e nesse estágio também é possível ativar Webhooks. O usuário deve armazenar as senhas da carteira  que são geradas após a criação.'
    }
  },
  buy_crypto: {
    title: 'Comprar criptomoeda',
    question_1: {
      title: 'Como comprar criptomoeda',
      description:
        'O usuário pode facilmente comprar Bitcoin e outras criptomoedas usando nossa plataforma.'
    }
  }
};
