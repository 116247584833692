import { createTheme } from '@mui/material';

export const primaryColor = {
  main: '#86CEFF',
  dark: '#FFFFF4D',
  overlay: '#0062FF17',
  overlay200: '#86CEFF26',
  overlay300: '#86CEFF57',
  50: '#E2F3FF',
  200: '#86CEFF',
  300: '#41BBFF',
  900: '#00003F'
};

export const alertColor = {
  main: '#E0352A',
  dark: '#862019',
  darker: '#2D0B08',
  light: '#EC867F',
  lighter: '#F9D7D4',
  overlay: '#E0352A26',
  hover: '#E2706C',
  hoverSecondary: '#E0352A33'
};

export const warningColor = {
  main: '#FEEC71',
  dark: '#98860B',
  darker: '#332D04',
  light: '#FEEC71',
  lighter: '#FFF9D0',
  overlay: '#FED95026',
  hover: '#FBD12E'
};

export const successColor = {
  main: '#16C784',
  dark: '#20805C',
  darker: '#0B2B1F',
  light: '#86E6C2',
  lighter: '#D7F7EB',
  overlay: '#16C78426',
  hover: '#44DCAB',
  hoverSecondary: '#16C78433'
};

export const textColor = {
  primary: '#E2F3FF',
  secondary: '#E2F3FFCC'
};

export const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 361,
      md: 721,
      lg: 1241,
      xl: 1441
    }
  },
  palette: {
    mode: 'dark',
    iconPrimary: '#FFFFFFE5',
    iconSecondary: '#FFFFFFB2',
    helperText: '#E2F3FF80',
    stroke: '#86CEFF57',
    background: {
      paper: '#000019',
      default: '#090E29',
      elevated: '#101935',
      surface: '#040620',
      tooltip: '#222F58'
    },
    divider: '#86CEFF1F',
    text: {
      ...textColor
    },
    error: {
      main: alertColor.main,
      dark: alertColor.darker,
      light: alertColor.light,
      A200: alertColor.hoverSecondary
    },
    success: {
      main: successColor.main,
      dark: successColor.dark,
      light: successColor.light,
      contrastText: textColor.primary,
      200: successColor.main,
      A200: successColor.overlay
    },
    warning: {
      main: warningColor.main,
      dark: warningColor.dark,
      light: warningColor.light,
      contrastText: warningColor.darker,
      A200: warningColor.overlay,
      '300': warningColor.hover
    },
    primary: {
      main: primaryColor.main,
      50: primaryColor[50],
      200: primaryColor[200],
      300: primaryColor[300],
      900: primaryColor[900],
      A100: primaryColor.overlay,
      A200: primaryColor.overlay200,
      A400: primaryColor.overlay300
    },
    brand: {
      primary: '#86CEFFFF',
      primaryHover: '#00AAFFFF',
      onPrimary: '#00003FFF',

      secondary: '#86CEFF26',
      secondaryHover: '#86CEFF45',
      onSecondary: '#86CEFFFF'
    },
    cryptos: {
      algorand: `#000000`,
      avalanche: `#E84142`,
      ethereum: `#627eea`,
      bitcoin: `#FF8300`,
      celo: `#FCFE52`,
      binance: '#FCD535',
      polkadot: '#E6007A',
      polygon: '#723AD4',
      dash: '#008DE4',
      ripple: '#1DB4FF',
      hyperledger_besu: '#19A1A1'
    },
    primaryOverlays: {
      50: primaryColor[50],
      200: primaryColor[200],
      300: primaryColor[300],
      900: primaryColor[900],
      A100: primaryColor.overlay,
      A200: primaryColor.overlay200,
      A300: primaryColor.overlay300
    },
    successOverlays: {
      200: successColor.main,
      A200: successColor.overlay
    },
    errorOverlays: {
      200: alertColor.main,
      A200: alertColor.overlay
    },
    warningOverlays: {
      200: warningColor.main,
      A200: warningColor.overlay
    }
  },
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#86CEFF1F'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: primaryColor.overlay300,
          '&$checked': {
            color: primaryColor.main
          },
          '&:disabled': {
            borderColor: '#000000'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
          marginTop: 4,
          background: '#101935',
          border: `1px solid #86CEFF57`
        },
        listbox: {
          backgroundColor: '#101935 !important',
          borderRadius: 12,
          width: '100%'
        },
        input: {
          textTransform: 'capitalize'
        },
        popper: {
          width: '100%'
        },
        option: {
          display: 'flex',
          justifyContent: 'space-between',
          textTransform: 'capitalize'
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        // active: {
        //   fontWeight: 500
        // },
        // label: {
        //   fontWeight: 500
        // },
        root: {
          color: textColor.secondary,
          fontWeight: 700,
          fontSize: '0,875rem'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `1px solid #86CEFF1F`,
          '&:last-child': {
            padding: 7,
            paddingLeft: 21,
            paddingRight: 21
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          border: 'none',
          padding: '0px 32px',
          height: 56
        },
        body: {
          border: 'none',
          padding: '0px 32px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '999999s',
            transitionProperty: 'background-color, color'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 12
        }
      },
      variants: [
        {
          props: {
            variant: 'outlined',
            color: 'primary'
          },
          style: {
            borderRadius: 12,
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '12px',
              backgroundColor: primaryColor.overlay200
            }
          }
        },
        {
          props: {
            variant: 'outlined',
            color: 'secondary'
          },
          style: {
            color: alertColor.light,
            background: alertColor.overlay
          }
        }
      ]
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          '&.Mui-focusVisible': {
            color: primaryColor[200],
            fill: primaryColor[200],
            backgroundColor: primaryColor[200]
          },
          '&.Mui-selected': {
            color: primaryColor[200],
            fill: primaryColor[200],
            backgroundColor: primaryColor[200]
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: 1.715,
          borderRadius: 12,
          boxShadow: 'none'
        },
        startIcon: {
          display: 'flex',
          fill: 'currentColor',
          marginLeft: 0,
          justifyContent: 'center',
          alignItems: 'center',
          width: 18,
          height: 18
        },
        endIcon: {
          paddingLeft: 32,
          paddingRight: 32,
          display: 'flex',
          fill: 'currentColor',
          justifyContent: 'center',
          alignItems: 'center',
          width: 18,
          height: 18
        },
        sizeLarge: {
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 6,
          paddingBottom: 6
        }
      },
      variants: [
        {
          props: {
            variant: 'contained',
            color: 'primary'
          },
          style: {
            backgroundColor: primaryColor[200],
            color: primaryColor[900],
            fill: primaryColor[900],
            ':disabled': {
              backgroundColor: primaryColor[200],
              opacity: 0.34,
              color: primaryColor[900],
              fill: primaryColor[900]
            },
            ':hover': {
              transition: '0.25s ease-in-out',
              backgroundColor: primaryColor[300],
              color: primaryColor[900],
              fill: primaryColor[900]
            }
          }
        },
        {
          props: {
            variant: 'overlayed',
            color: 'primary'
          },
          style: {
            backgroundColor: primaryColor.overlay200,
            color: primaryColor[200],
            fill: primaryColor[200],
            ':disabled': {
              opacity: 0.34,
              color: primaryColor[200],
              fill: primaryColor[200]
            },
            ':hover': {
              transition: '0.25s ease-in-out',
              backgroundColor: `${primaryColor[200]}33`,
              color: primaryColor[200],
              fill: primaryColor[200]
            }
          }
        },
        {
          props: {
            variant: 'text',
            color: 'primary'
          },
          style: {
            color: primaryColor[200],
            fill: primaryColor[200],
            ':disabled': {
              opacity: 0.34,
              color: primaryColor[200],
              fill: primaryColor[200]
            },
            ':hover': {
              transition: '0.25s ease-in-out',
              backgroundColor: primaryColor.overlay300,
              color: primaryColor[200],
              fill: primaryColor[200]
            }
          }
        },
        {
          props: {
            variant: 'contained',
            color: 'error'
          },
          style: {
            backgroundColor: alertColor.light,
            color: alertColor.darker,
            fill: 'currentColor',
            ':disabled': {
              backgroundColor: alertColor.light,
              color: alertColor.darker,
              fill: 'currentColor',
              opacity: 0.34
            },
            ':hover': {
              transition: '0.25s ease-in-out',
              backgroundColor: alertColor.hover,
              color: alertColor.darker,
              fill: 'currentColor'
            }
          }
        },
        {
          props: {
            variant: 'overlayed',
            color: 'error'
          },
          style: {
            backgroundColor: alertColor.overlay,
            color: alertColor.light,
            fill: alertColor.light,
            ':disabled': {
              opacity: 0.34,
              color: alertColor.light,
              fill: alertColor.light
            },
            ':hover': {
              transition: '0.25s ease-in-out',
              backgroundColor: alertColor.hoverSecondary,
              color: alertColor.light,
              fill: alertColor.light
            }
          }
        },
        {
          props: {
            variant: 'text',
            color: 'error'
          },
          style: {
            color: alertColor.light,
            fill: alertColor.light,
            ':disabled': {
              opacity: 0.34,
              color: alertColor.light,
              fill: alertColor.light
            },
            ':hover': {
              transition: '0.25s ease-in-out',
              backgroundColor: alertColor.hoverSecondary,
              color: alertColor.light,
              fill: alertColor.light
            }
          }
        },
        {
          props: {
            variant: 'contained',
            color: 'success'
          },
          style: {
            backgroundColor: successColor.light,
            color: successColor.darker,
            fill: successColor.darker,
            ':disabled': {
              backgroundColor: successColor.light,
              color: successColor.darker,
              fill: successColor.darker,
              opacity: 0.34
            },
            ':hover': {
              transition: '0.25s ease-in-out',
              backgroundColor: successColor.hover,
              color: successColor.darker,
              fill: successColor.darker
            }
          }
        },
        {
          props: {
            variant: 'overlayed',
            color: 'success'
          },
          style: {
            backgroundColor: successColor.overlay,
            color: successColor.light,
            fill: successColor.light,
            ':disabled': {
              opacity: 0.34,
              color: successColor.light,
              fill: successColor.light
            },
            ':hover': {
              transition: '0.25s ease-in-out',
              backgroundColor: successColor.hoverSecondary,
              color: successColor.light,
              fill: successColor.light
            }
          }
        },
        {
          props: {
            variant: 'text',
            color: 'success'
          },
          style: {
            color: successColor.light,
            fill: successColor.light,
            ':disabled': {
              opacity: 0.34,
              color: successColor.light,
              fill: successColor.light
            },
            ':hover': {
              transition: '0.25s ease-in-out',
              backgroundColor: successColor.hoverSecondary,
              color: successColor.light,
              fill: successColor.light
            }
          }
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          height: 48,
          color: '#E2F3FFCC',
          fill: '#E2F3FFCC'
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          alignContent: 'flex-start',
          borderRadius: 8,
          paddingTop: 0,
          minWidth: '720px',
          minHeight: '83px'
        },
        message: {
          padding: 0
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          width: '100%',
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '14px',
          borderRadius: 12,
          boxShadow: 'none',
          border: 'none',
          backgroundColor: primaryColor.overlay200,
          color: textColor.secondary,
          '&.Mui-selected': {
            color: primaryColor[900],
            fontWeight: 700,

            backgroundColor: primaryColor[200],
            ':hover': {
              backgroundColor: primaryColor[300]
            }
          },
          ':hover': {
            backgroundColor: primaryColor.overlay300
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#090E29',
          borderRadius: 16
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 32
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          backgroundColor: `${primaryColor['200']}1F`,
          color: textColor.secondary,
          fill: 'currentColor',
          '&.Mui-disabled': {
            backgroundColor: `${primaryColor['200']}1F`,
            opacity: 0.34
          },
          '&.MuiChip-outlined': {
            background: 'none',
            borderColor: `${primaryColor['200']}1F`,
            color: textColor.secondary,
            fill: 'currentColor'
          },
          '&.MuiChip-deleteIcon': {
            color: textColor.secondary,
            '&:hover': {
              color: textColor.secondary,
              opacity: 0.8
            }
          },
          '&.MuiChip-colorPrimary': {
            backgroundColor: primaryColor['200'],
            color: primaryColor['900'],
            fill: 'currentColor',
            '&:hover': {
              backgroundColor: primaryColor['200']
            }
          },
          '&.MuiChip-colorWarning': {
            backgroundColor: warningColor.overlay,
            color: warningColor.light,
            fill: 'currentColor',
            '&:hover': {
              backgroundColor: warningColor['200']
            }
          },
          '&.MuiChip-deleteIconColorPrimary': {
            background: 'none',
            color: primaryColor['900'],
            fill: 'currentColor',
            '&:hover': {
              color: primaryColor['900']
            }
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${primaryColor[200]}57`,
          '&.Mui-disabled': {
            color: `${primaryColor[200]}57 !important`,
            opacity: 0.34
          },
          '&.Mui-checked': {
            color: primaryColor[200]
          },
          '&.MuiCheckbox-indeterminate': {
            color: primaryColor[200]
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          width: '100px',
          paddingLeft: 17,
          color: '#222F58',
          '::before': { right: 35 }
        },
        tooltip: {
          backgroundColor: '#222F58',
          borderRadius: '12px',
          padding: '14px 12px',
          maxWidth: 375,
          boxSizing: 'content-box'
        },

        tooltipPlacementBottom: {
          left: -15
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: warningColor.overlay,
          color: warningColor.light
        },
        root: {
          padding: '4px 24px',
          width: '100%',
          fontWeight: 700,
          fontSize: '1rem',
          borderRadius: '16px'
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: 1.715,
          borderRadius: 12,
          boxShadow: 'none',
          width: '100%'
        }
      }
    }
  },
  typography: {
    h1: {
      fontSize: '6rem',
      fontWeight: 400
    },
    h2: {
      fontSize: '3.75rem',
      fontWeight: 400
    },
    h3: {
      fontSize: '2.5rem',
      fontWeight: 500
    },
    h4: {
      fontSize: '2.125rem',
      fontWeight: 500
    },
    h5: {
      fontSize: '1.5rem'
    },
    h6: {
      fontSize: '1.25rem'
    },
    fontFamily: [
      'Roboto',
      'Source Sans Pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
});
