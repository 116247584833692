/* eslint-disable no-nested-ternary */
import {
  Box,
  CircularProgress,
  Button as MUIButton,
  ButtonProps as MUIButtonProps
} from '@mui/material';
import Text from 'components/atoms/Text';
import { TranslationValuePath } from 'i18n';
import mixpanel from 'mixpanel-browser';
import { SnackbarMessage, useSnackbar } from 'notistack';
import { TextProps } from '../Text/Text';

export interface ButtonProps extends MUIButtonProps {
  'clipboard-text'?: string;
  'data-mixpanel'?: string;
  'data-mixpanel-properties'?: { [key: string]: string };
  'clipboard-success-message'?: string;
  loading?: boolean;
  intlpath?: TranslationValuePath;
  intlpathprops?: Omit<TextProps, 'intlpath'>;
  actLikeTextLink?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  actLikeTextLink,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box sx={{ position: 'relative' }}>
      <MUIButton
        sx={{
          paddingLeft: props.startIcon ? 1.5 : actLikeTextLink ? 0 : 2,
          paddingRight: props.endIcon ? 1.5 : actLikeTextLink ? 0 : 2,
          fill: 'primaryOverlays.900',
          height: 36,

          ':hover': {
            textDecoration:
              (actLikeTextLink && props?.variant === 'text') || actLikeTextLink
                ? 'underline'
                : 'none'
          }
        }}
        style={
          ((actLikeTextLink && props?.variant === 'text') ||
            actLikeTextLink) && {
            backgroundColor: 'transparent'
          }
        }
        {...props}
        onClick={
          props['clipboard-text']
            ? () => {
                mixpanel.track(
                  props?.['data-mixpanel'] ?? '',
                  props?.['data-mixpanel-properties']
                );
                navigator.clipboard
                  .writeText(props['clipboard-text'])
                  .then(() => {
                    enqueueSnackbar({
                      type: 'success',
                      title: props['clipboard-success-message']
                    } as unknown as SnackbarMessage);
                  });
              }
            : (event) => {
                mixpanel.track(
                  props?.['data-mixpanel'] ?? '',
                  props?.['data-mixpanel-properties']
                );

                if (props?.onClick) {
                  props.onClick(event);
                }
              }
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            visibility: loading ? 'hidden' : 'visible'
          }}
        >
          <Text
            intlPath={props?.intlpath}
            fontWeight={500}
            variant="body2"
            {...props?.intlpathprops}
          >
            {children}
          </Text>
        </Box>
      </MUIButton>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '12px',
            marginLeft: '-12px'
          }}
        />
      )}
    </Box>
  );
};

export default Button;
