export const transaction_policy_page = {
  breadcrumb_title: 'Transaction policy',
  breadcrumb_title_create: 'Create',

  create_transaction_policy: 'Create Transaction Policy',

  form: {
    transaction_label: 'Transaction Policy Name',
    transaction_placeholder: 'Set Transaction Policy Name',

    amount_label: 'Amount',
    amount_description: 'Set threshold values (in fiat currency).',
    amount_description2:
      'Transactions outside the defined amount will be rejected at the time of creation.',

    min_value_invalid: 'The min value must not be greater than max value',

    secure_destinations_label: 'Secure Destinations',
    secure_destinations_description: 'Set secure addresses for transactions.',
    secure_destinations_description2:
      'Transactions that do not go to secure addresses will be rejected at the time of creation.',

    time_frame_label: 'Time Frame',
    time_frame_description:
      'Specify the time at which the transaction can be executed (after approval).',
    time_frame_description2:
      'Transactions approved outside the defined time frame are pending until the window is available.',
    timezone_field_placeholder: 'Timezone',

    monday_to_friday: 'Monday to Friday',

    asset_label: 'Asset',
    asset_description:
      'Choose the cryptocurrencies involved in the transaction.',
    asset_description2:
      'Transactions using unconfigured assets will be rejected at the time of creation.',

    approval_label: 'Approval',
    approval_placeholder: 'Approvers',
    approval_description:
      'Inform who will approve the transactions and the minimum number of approvals required.',
    approval_description2:
      'Until the minimum number of approvers is reached, the transaction will not be executed.',

    min_approvals_placeholder: 'Min. Approvals 2',
    min_approvals_invalid_helper_text:
      'Minimum number of approvers must be less than or equal to the total number of approvers informed',
    min_approvals_helper_text: 'Minimum number of approvers must be 1',

    to_label: 'to',

    minimum_value_label: 'Minimum Value',
    maximum_value_label: 'Maximum Value',
    network_label: 'Network',
    address_label: 'Address',

    add_button: 'Add Transaction Policy',
    update_button: 'Update Transaction Policy',
    add_address_button: 'Add Address',
    add_network_button: 'Add Network'
  },

  delete_policy_dialog: {
    title: 'Delete transaction policy',
    description:
      'Are you sure you want to delete the transaction policy called "{{name}}"?'
  },

  table_header: {
    transaction_policy_name: 'Transaction policy name',
    created_by: 'Created by',
    date: 'Date',
    delete: 'Delete'
  },

  success_message: {
    policy_created: 'Transaction policy created successfully',
    policy_updated: 'Transaction policy updated successfully',
    policy_deleted: 'Transaction policy deleted successfully'
  }
};
