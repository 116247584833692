/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosError } from 'axios';
import APIService, { APIServiceDefaultParams } from 'services/api/APIService';
import { FinConectaIntegrationBodyData } from './api/schemas/IntegrationSchema';

const API_ROUTE_PREFIX = 'cash-flow';

export async function removeIntegration(): Promise<void> {
  await APIService.getInstance({ api: 'cash-flow' })
    .delete(`/${API_ROUTE_PREFIX}/finconnecta/registry`)
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      return error.response?.data;
    });
}

export async function createFinConectaIntegration(
  params?: APIServiceDefaultParams<FinConectaIntegrationBodyData>
): Promise<void> {
  const { body } = params;

  await APIService.getInstance({ api: 'cash-flow' })
    .post<FinConectaIntegrationBodyData>(
      `/${API_ROUTE_PREFIX}/finconnecta/registry`,
      body
    )
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      throw error.response?.data;
    });
}
