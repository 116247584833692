import useIntegrations, {
  IIntegrationsHook
} from 'hooks/useIntegrations/useIntegrations';
import { useSession } from 'next-auth/react';
import { ReactNode, createContext, useEffect, useMemo } from 'react';

type IIntegrationsContext = IIntegrationsHook;

const defaultState: IIntegrationsContext = {
  activeIntegrations: [],
  isLoading: false
};

export const IntegrationsContext = createContext(defaultState);

interface IntegrationsProviderProps {
  children: ReactNode;
}

export function IntegrationsProvider({ children }: IntegrationsProviderProps) {
  const useIntegrationsProperties = useIntegrations();
  const { data: session } = useSession();
  const { setActiveIntegrations } = useIntegrationsProperties;
  const value = useMemo(
    () => ({
      ...useIntegrationsProperties
    }),
    [useIntegrationsProperties]
  );

  useEffect(() => {
    if (!!session?.user?.user_preferences_object?.finconnecta_key) {
      setActiveIntegrations([
        { id: 1, name: 'FinConecta', status: 'connected' }
      ]);
    } else {
      setActiveIntegrations([
        { id: 1, name: 'FinConecta', status: 'not_connected' }
      ]);
    }
  }, [session]);

  return (
    <IntegrationsContext.Provider value={value}>
      {children}
    </IntegrationsContext.Provider>
  );
}
