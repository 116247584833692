import { asset_tokenization_create_edit_page } from './asset-tokenization-create-edit-page';
import { asset_tokenization_details_page } from './asset-tokenization-details-page';
import { asset_tokenization_home_page } from './asset-tokenization-home-page';
import { buy_and_sell_page } from './buy-and-sell-page';
import { change_password } from './change-password';
import { common_translations } from './common';
import {
  forgotPasswordForm,
  resetPasswordForm,
  validationCodeForm
} from './forgot-password';
import { integrations_page } from './integrations-page';
import { internal_sidebar_menu_items } from './internal-menu-items';
import { internal_services_page } from './internal-services-page';
import { my_account_page } from './my-account-page';
import { new_swap_page } from './new-swap-page';
import { nft_collection_page } from './nft-collection-page';
import { nft_collections_list_page } from './nft-collections-list-page';
import { plans_and_payments_page } from './plans-and-payments-page';
import { settings_page } from './settings-page';
import { signInForm } from './sign-in';
import { signUpForm } from './sign-up';
import { snackbar_messages } from './snackbar-messages';
import { support } from './support';
import { swap_list_page } from './swap-list-page';
import { taxes_payment_page } from './taxes-payment';
import { transaction_policy_page } from './transaction-policy';
import { vaults_page } from './vaults';
import { verify_account_page } from './verify-account-page';
import { wallet_details_page } from './wallet-details-page';
import { wallets_home_page } from './wallets-home-page';
import { wallets_list_page } from './wallets-list-page';
import { webSiteEn } from './web-site';
import { webhooks } from './webhooks';

export const en = {
  internal_sidebar_menu_items,
  settings_page,
  internal_services_page,
  my_account_page,
  plans_and_payments_page,
  common_translations,
  signInForm,
  signUpForm,
  forgotPasswordForm,
  validationCodeForm,
  change_password,
  resetPasswordForm,
  integrations_page,

  buy_and_sell_page,

  // Swap
  new_swap_page,
  swap_list_page,

  // NFT
  nft_collection_page,
  nft_collections_list_page,

  // Asset Tokenizations
  asset_tokenization_home_page,
  asset_tokenization_create_edit_page,
  asset_tokenization_details_page,

  // Wallets
  wallets_home_page,
  wallet_details_page,
  wallets_list_page,

  support,
  verify_account_page,
  webhooks,
  snackbar_messages,
  taxes_payment_page,
  vaults_page,
  transaction_policy_page,

  webSiteEn: { ...webSiteEn }
};
