export const plans_and_payments_page = {
  breadcrumb_title: 'Plans & payments',
  available_plans_label: 'Available Plans',
  chosen_plan_label: 'Chosen Plan',
  credit_card_label: 'Credit card',
  yearly_plans_tab: 'Yearly',
  monthly_plans_tab: 'Monthly',
  new_plan_label: 'New',
  requests_per_motn_label: 'Requests per month',
  webhooks_per_motn_label: 'Webhooks per month',
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },
  unlimited: 'Unlimited',
  current_plan: 'Current plan',
  payment_confirmed: 'Payment confirmed',
  payment_pending: 'Pending payment',
  infinite: 'Infinite',
  custom_plan_informative:
    'Get in touch with us and have the best plan for your organization.',
  payment: 'Payment',
  valid_until: 'Valid until {{date}}',
  starts_at: 'Starts at {{date}}',
  invoice: 'Invoice',
  paid: 'Paid',
  checkout_form: {
    title: 'Checkout',
    fields: {
      expiration_date: 'Expiration date',
      card_number: 'Card number',
      holder_name: 'Holder name',
      cvv: 'CVV',
      installments: 'Installments'
    }
  },
  manage_billing_dialog: {
    title: 'Manage billing',
    billing_email_label: 'Billing e-mail',
    bank_transfer_payment_method: 'Bank transfer',
    boleto_payment_method: 'Boleto',
    boleto_payment_method_informative:
      'You will receive monthly an e-mail from our team containing the boleto to be paid.'
  },
  table: {
    title: 'Payment history',
    header: {
      bank_slip: 'Bank slip',
      date: 'Date',
      value: 'Value',
      status: 'Status'
    }
  },
  snackbar: {
    success: {
      payment_confirmed: 'Payment confirmed'
    },
    errors: {
      payment_invalid: 'Error on process payment.'
    }
  },
  buttons: {
    choose: 'Choose',
    contact_us: 'Contact us',
    confirm_payment: 'Confirm payment {{value}}',
    manage_payment: 'Manage',
    see_available_plans: 'See available plans',
    copy_bank_data: 'Copy bank data',
    copy_bank_data_success: 'Bank data copied successfully'
  },
  new_plan: 'New plan'
};
