/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-cycle */
import { IIntlHook, useIntl } from 'hooks/useIntl';
import { TranslationValuePath } from 'i18n';
import React, { ReactNode } from 'react';

type LanguageType = 'en' | 'es' | 'pt';

interface ILanguageContext extends IIntlHook {
  languages: Language[];
}
export interface Language {
  label: TranslationValuePath;
  iconSrc?: string;
  value: LanguageType;
}

const defaultState: ILanguageContext = {
  languages: [],
  sessionIntlPreference: null,
  languageSelected: null,
  loading: false
};

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageContext =
  React.createContext<ILanguageContext>(defaultState);

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const {
    createIntlPreference,
    languageSelected,
    loading,
    updateIntlPreference,
    languages,
    sessionIntlPreference
  } = useIntl();

  return (
    <LanguageContext.Provider
      value={{
        createIntlPreference,
        languageSelected,
        sessionIntlPreference,
        updateIntlPreference,
        loading,
        languages
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
