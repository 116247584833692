export const asset_tokenization_create_edit_page = {
  breadcrumb_title_create: 'Create',
  breadcrumb_title_edit: 'Edit',
  total_of_assets: '{{number}} Assets',
  base_template: 'Base template',
  select_base_template: 'Select template',
  create_and_edit_form: {
    title_create: 'Novo ativo digital',
    title_edit: 'Editar ativo digital',
    contract_name_informative:
      'Enter the name of the main contract declared within your code.',
    fields: {
      base_template: 'Base template'
    }
  },
  cancel_dialog: {
    title_new: 'new asset?',
    title_edit: 'edit asset?',
    description: 'Changes will be lost.'
  },
  deploy_dialog_title: 'Deploy asset',
  contract_label: 'Contract',
  function_list_tab: 'Function list',
  code_tab: 'Code',
  snackbar: {
    success: {
      on_save: 'Asset saved.',
      on_deploy: 'Asset deployed.',
      draft_saved: 'Draft saved successfully',
      draft_updated: 'Draft updated successfully',
      contract_fields_updated: 'Contract fields have been successfully updated'
    },
    errors: {
      on_import: 'Error on import the asset.',
      on_save: 'Error on save.',
      on_deploy: 'Error on deploy.'
    }
  },
  buttons: {
    deploy: 'Deploy',
    update_draft: 'Update draft',
    save_draft: 'Save as draft',
    copy_tooltip: 'Copy code',
    expand_tooltip: 'Expand'
  }
};
