export const signInForm = {
  title: 'Login',
  description: '2-factor authentication',

  sandbox_text_one: 'The accounts created in the sandbox are used ',
  sandbox_text_two:
    'exclusively in this environment and completely\nseparated from the production environment.',

  emailLabelField: 'E-mail',
  emailRequiredField: 'E-mail is required',
  emailInvalidField: 'E-mail is not valid',
  emailErrorField: 'Wrong e-mail',

  passwordLabelField: 'Password',
  passwordRequiredField: 'Password is required',
  passwordErrorField: 'Wrong password',

  loginButton: 'Login',
  signInWithGoogleButton: 'Sign in with Google',
  signUpButton: 'Sign up',
  forgotPasswordButton: 'Forgot my password',

  snackbar: {
    titleSuccess: 'User authenticated successfully',
    descriptionSuccess: 'Welcome to the blockchain world!',

    titleError: 'E-mail or password invalid'
  },
  twoFactorTitle: 'Use the 2-factor authentication code to continue.'
};
