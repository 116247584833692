export const buy_and_sell_page = {
  breadcrumb_title: 'Compra & Venda',
  page_description:
    'Você já criou suas carteiras? Negocie criptomoedas. Compra e venda qualquer ativo digital de mercado.',
  table: {
    title: 'Transações',
    header: {
      transaction: 'Transação',
      amount: 'Valor a receber',
      pair: 'Par',
      confirmations: 'Confirmações',
      wallet_id: 'ID da carteira',
      hash: 'Hash'
    }
  },
  forms: {
    buy_form: {
      title: 'Comprar Criptomoeda',
      fields: {
        price: 'Preço do {{networkName}}',
        price_no_label: 'Preço'
      },
      helper_texts: {
        last_update: 'Atualizado há {{minutes}} minutos'
      }
    },
    sell_form: {
      title: 'Vender Criptomoeda',
      fields: {
        price: 'Preço do {{networkName}}',
        value: 'Volume a ser vendido',
        price_no_label: 'Preço'
      },
      helper_texts: {
        last_update: 'Atualizado há {{minutes}} minutos'
      }
    }
  },
  buttons: {
    buy: 'Comprar',
    sell: 'Vender'
  },
  recipient_wallet: 'Carteira do destinatário',
  status: {
    confirmed: 'Confirmado',
    pending: 'Pendente',
    created: 'Criado',
    failed: 'Falhou',
    canceled: 'Cancelado'
  }
};
