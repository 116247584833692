/* eslint-disable @typescript-eslint/no-unused-vars */
import { HookCallbackFunctions } from 'hooks/types';
import { translate } from 'i18n';
import { IntegrationListItemDTO } from 'models/IntegrationDTO';
import { SnackbarMessage, useSnackbar } from 'notistack';
import { useState } from 'react';
import { APIServiceDefaultParams } from 'services/api/APIService';
import { BigGatewayServerError } from 'services/api/BigGatewayAPIService';
import { FinConectaIntegrationBodyData } from 'services/api/schemas/IntegrationSchema';
import {
  createFinConectaIntegration,
  removeIntegration
} from 'services/integrationServices';
import useGlobalStateStore from 'store/global-state/useGlobalStateStore';

export interface IIntegrationsHook {
  isLoading: boolean;
  activeIntegrations: IntegrationListItemDTO[];
  setActiveIntegrations?: (
    activeIntegrations: IntegrationListItemDTO[]
  ) => void;
  createFinConectaIntegration?: (
    params?: APIServiceDefaultParams<FinConectaIntegrationBodyData>,
    callbacks?: HookCallbackFunctions
  ) => void;
  removeIntegration?: (callbacks?: HookCallbackFunctions) => void;
}

function useIntegrations(): IIntegrationsHook {
  const { enqueueSnackbar } = useSnackbar();

  const { activeIntegrations, setActiveIntegrations } = useGlobalStateStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRemoveIntegration = async (callbacks?: HookCallbackFunctions) => {
    const { onSuccess, onError } = callbacks ?? {};
    setIsLoading(true);

    await removeIntegration()
      .then((response) => {
        enqueueSnackbar({
          title: translate(
            'integrations_page.snackbar_messages.success.remove_integration'
          ),
          type: 'success'
        } as unknown as SnackbarMessage);

        setActiveIntegrations([
          {
            id: 1,
            name: 'FinConecta',
            status: 'not_connected'
          }
        ]);

        // FORCING THE TAB VISIBILITY TO UPDATE SESSION VALUES
        const event = new Event('visibilitychange');
        document.dispatchEvent(event);

        onSuccess?.(response);
      })
      .catch((error: BigGatewayServerError) => {
        enqueueSnackbar({
          title: translate(
            'integrations_page.snackbar_messages.error.remove_integration'
          ),
          description: error?.message_type,
          type: 'error'
        } as unknown as SnackbarMessage);
        onError?.(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCreateFinConectaIntegration = async (
    params: APIServiceDefaultParams<FinConectaIntegrationBodyData>,
    callbacks?: HookCallbackFunctions
  ) => {
    const { onSuccess, onError } = callbacks ?? {};
    setIsLoading(true);

    await createFinConectaIntegration(params)
      .then((response) => {
        onSuccess?.(response);
        enqueueSnackbar({
          title: translate(
            'integrations_page.snackbar_messages.success.add_integration'
          ),
          type: 'success'
        } as unknown as SnackbarMessage);

        // FORCING THE TAB VISIBILITY TO UPDATE SESSION VALUES
        const event = new Event('visibilitychange');
        document.dispatchEvent(event);

        setActiveIntegrations([
          {
            id: 1,
            name: 'FinConecta',
            status: 'connected'
          }
        ]);
      })
      .catch((error: BigGatewayServerError) => {
        enqueueSnackbar({
          title: translate(
            'integrations_page.snackbar_messages.error.add_integration'
          ),
          description: error?.message_type,
          type: 'error'
        } as unknown as SnackbarMessage);
        onError?.(error);
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    activeIntegrations,
    setActiveIntegrations,
    removeIntegration: handleRemoveIntegration,
    createFinConectaIntegration: handleCreateFinConectaIntegration
  };
}

export default useIntegrations;
