export const asset_tokenization_create_edit_page = {
  breadcrumb_title_create: 'Criar',
  breadcrumb_title_edit: 'Editar',
  total_of_assets: '{{number}} Ativos digitais',
  base_template: 'Template base',
  select_base_template: 'Selecione template',
  create_and_edit_form: {
    title_create: 'Novo ativo digital',
    title_edit: 'Editar ativo digital',
    contract_name_informative:
      'Insira o nome do contrato principal declarado dentro do seu código.',
    fields: {
      base_template: 'Template base'
    }
  },
  cancel_dialog: {
    title_new: 'novo ativo',
    title_edit: 'edição do ativo',
    description: 'As alterações serão perdidas.'
  },
  deploy_dialog_title: 'Implantar ativo',
  contract_label: 'Contrato',
  function_list_tab: 'Lista de Funções',
  code_tab: 'Código',
  snackbar: {
    success: {
      on_save: 'Ativo digital salvo.',
      on_deploy: 'Ativo digital implantado.',
      draft_saved: 'Rascunho salvo com sucesso.',
      draft_updated: 'Rascunho atualizado com sucesso',
      contract_fields_updated:
        'Campos do contrato foram atualizados com sucesso.'
    },
    errors: {
      on_import: 'Erro ao importar.',
      on_save: 'Erro ao salvar.',
      on_deploy: 'Erro ao implantar.'
    }
  },
  buttons: {
    deploy: 'Implantar',
    update_draft: 'Atualizar rascunho',
    save_draft: 'Salvar como rascunho',
    copy_tooltip: 'Copiar código',
    expand_tooltip: 'Expandir'
  }
};
