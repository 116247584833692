export const nft_collections_list_page = {
  breadcrumb_title: 'NFT',
  collections: '{{value}} collections',
  collection: '{{value}} collection',
  create_collection: 'Create collection',
  empty_state: {
    title: 'Collections',
    description:
      "A collection is used to group NFT's with same artistic style. Create a collection to start uploading your files."
  },
  dialog: {
    title: 'New collection',
    fields: {
      name: 'Name',
      description: 'Description',
      blockchain: 'Blockchain',
      symbol: 'Symbol',
      wallet_reference: 'Wallet reference'
    }
  },
  collection_name_summary_label: 'Collection name',
  collection_description_summary_label: 'Collection description',
  collection_symbol_summary_label: 'Collection description',
  snackbar: {
    on_create: 'Collection created.'
  }
};
