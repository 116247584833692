export const support = {
  breadcrumb_title: 'Support',
  contact: 'Contact',
  contact_us: 'Contact us via email',
  frequently_asked_questions: 'Frequently asked questions',
  your_question: 'Your questions answered by our experts.',
  send_email: 'Send e-mail',
  wallets: {
    title: 'Wallets',
    question_1: {
      title: 'What is a Wallet',
      description:
        'Wallets store your private keys, keeping your crypto safe and accessible. They also allow you to send, receive, and spend cryptocurrencies like Bitcoin and Ethereum.'
    },
    question_2: {
      title: 'How to create a Wallet',
      description:
        'To create a Wallet, the user can choose the desired network and/or click on the “Create” button, which is located in the header of the page. The user must fill in all the information and at this stage it is also possible to activate Webhooks. The user must store the Wallet’s password that is generated after it’s creation.'
    }
  },
  buy_crypto: {
    title: 'Buy Cryptocurrency',
    question_1: {
      title: 'How to buy Crypto',
      description:
        'Users can easily buy Bitcoin and other cryptocurrencies using our platform.'
    }
  }
};
