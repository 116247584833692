/* eslint-disable react/display-name */
import {
  CancelIcon,
  CheckCircleDuotoneIcon,
  CloseIcon
} from '@bws-bitfy/icons-react';
import { Box, Grid, SnackbarContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import { TranslationValuePath } from 'i18n';
import { useSnackbar } from 'notistack';
import { forwardRef, useCallback, useMemo } from 'react';
import { alertColor, successColor } from 'themes/dark-theme';
import Tooltip from '../Tooltip';

export type SnackbarProps = {
  title?: string;
  titleIntlPath?: TranslationValuePath;
  description?: string;
  descriptionIntlPath?: TranslationValuePath;
  buttonLabel?: string;
  buttonLabelIntlPath?: TranslationValuePath;
  type?: 'success' | 'error';
  icon?: JSX.Element;
};

const Snackbar = forwardRef<
  HTMLDivElement,
  { id: string | number; message: SnackbarProps }
>((props, ref) => {
  const { message } = props;
  const {
    icon,
    title,
    titleIntlPath,
    buttonLabel,
    description,
    type,
    buttonLabelIntlPath,
    descriptionIntlPath
  } = message;
  // const [progress, setProgress] = useState(0);
  const theme = useTheme();
  const { closeSnackbar } = useSnackbar();

  const iconComponent = useMemo(() => {
    if (icon) {
      return (
        <Box
          sx={{
            svg: {
              fill:
                type === 'success'
                  ? theme.palette.success.light
                  : theme.palette.error.light
            }
          }}
          width={40}
          height={40}
        >
          {icon}
        </Box>
      );
    }
    if (type === 'success') {
      return (
        <Box pb={buttonLabel && 2}>
          <CheckCircleDuotoneIcon
            width={38}
            height={38}
            fill={theme.palette.success.light}
          />
        </Box>
      );
    }

    return (
      <Box pb={buttonLabel && 2}>
        <CancelIcon width={38} height={38} fill={theme.palette.error.light} />
      </Box>
    );
  }, [icon]);

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => {
  //       if (prevProgress <= 100) return prevProgress + 1;
  //       return 100;
  //     });
  //   }, 10);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <SnackbarContent
      ref={ref}
      data-testid="snackbar-content"
      sx={{
        minHeight: 0,
        position: 'relative',
        backgroundColor:
          type === 'success' ? successColor.darker : alertColor.darker,
        paddingX: 3.5,
        paddingY: 2.5
      }}
      message={
        <>
          <Grid
            alignItems="center"
            justifyContent="space-between"
            container
            minWidth={{ xs: '100%', md: '520px', xl: '720px' }}
          >
            <Grid item alignSelf={buttonLabel ? 'flex-start' : 'center'}>
              <Box
                data-testid="snackbar-icon"
                sx={{
                  svg: {
                    fill:
                      type === 'error'
                        ? theme.palette.error.light
                        : theme.palette.success.light,
                    width: 38,
                    height: 38
                  }
                }}
              >
                {iconComponent}
              </Box>
            </Grid>
            <Grid item flex={1}>
              <Box paddingLeft={3}>
                <Text
                  variant="body1"
                  fontWeight={700}
                  sx={{
                    color:
                      type === 'success'
                        ? successColor.lighter
                        : alertColor.lighter
                  }}
                  data-testid="snackbar-title"
                  intlPath={titleIntlPath}
                >
                  {title}
                </Text>

                {description && (
                  <Text
                    mt={1}
                    variant="body1"
                    color="text.secondary"
                    fontWeight={500}
                    lineHeight={1}
                    sx={{
                      letterSpacing: '-0.01em'
                    }}
                    data-testid="snackbar-description"
                    intlPath={descriptionIntlPath}
                  >
                    {description}
                  </Text>
                )}
                {buttonLabel && (
                  <Box mt={2.7}>
                    <Button
                      data-testid="snackbar-button"
                      color={type === 'success' ? 'success' : 'error'}
                      variant="contained"
                      intlpath={buttonLabelIntlPath}
                    >
                      {buttonLabel}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item alignSelf={buttonLabel ? 'flex-start' : 'center'}>
              <Tooltip
                arrow={false}
                placement="top"
                titleIntlPath="common_translations.buttons.close"
              >
                <IconButton onClick={handleDismiss}>
                  <CloseIcon fill={theme.palette.text.secondary} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* <LinearProgress
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 8,
              right: 8,
              borderRadius: '2px',
              height: 4
            }}
            color={type === 'success' ? 'success' : 'error'}
            variant="determinate"
            value={progress}
          /> */}
        </>
      }
    />
  );
});

export default Snackbar;
