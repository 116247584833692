export const settings_menu_items = {
  groups: {
    general: 'General',
    team: 'Team',
    security: 'Security',
    services: 'Services'
  },
  items: {
    languages: 'Language',
    members: 'Members',
    two_factor_authentication: '2-factor authentication',
    ip_permissions: 'IP permissions',
    api_key: 'API key',
    public_key: 'Public key',
    webhook_url: 'Webhook URL & token'
  }
};
