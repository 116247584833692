export const snackbar_messages = {
  error: {
    url_update: 'Error ao atualizar URL',
    get_aplication: 'Erro ao obter aplicações.',
    create_credencials: 'Erro ao criar credenciais.',
    delete_credencials: 'Erro ao excluir as credenciais.',
    new_application: 'Erro ao adicionar uma aplicação.',
    remove_application: 'Erro ao remover uma aplicação.',
    get_credencials: 'Erro ao obter credenciais.',
    add_ip: 'Erro ao adicionar IP.',
    delete_id: 'Erro ao excluir ID.',
    get_ip: 'Erro ao obter IP.',
    get_public_key: 'Erro ao obter chave pública.',
    add_public_key: 'Erro ao adicionar chave pública.',
    change_public_key: 'Erro ao alterar chave pública.',
    get_webhooks: 'Erro ao obter webhooks.',
    resend_confirmation_code_failed: 'Falha ao enviar código de confirmação.',
    get_network: 'Erro ao obter redes blockchain.',
    get_by_id: 'Erro ao obter rede blockchain por ID.',
    withdraw: 'Erro ao sacar',
    get_qr_code: 'Erro ao obter código QR',
    get_pix: 'Erro ao obter informações PIX',
    get_withdraw: 'Erro ao intenções de saque',
    get_deposit: 'Erro ao obter intenções de depósito',
    get_exchange: 'Erro ao obter lista de exchanges de criptomoedas',
    get_quote: 'Erro ao obter cotação de criptomoeda',
    post_exchange: 'Erro ao comprar criptomoeda',
    get_tradable_postman: 'Erro ao obter tokens negociáveis',
    update_intl: 'Erro ao atualizar preferências.',
    create_preference: 'Erro ao criar preferência.',
    get_list_moviments: 'Erro ao obter lista de transações.',
    get_balance: 'Erro ao atualizar saldo',
    create_collection: 'Erro ao criar coleção.',
    create_nft: 'Erro ao criar NFT.',
    transfer_nft: 'Erro ao transferir NFT.',
    create_org_preference: 'Erro ao criar preferência de organização.',
    update_preference: 'Erro ao atualizar preferência.',
    get_org: 'Erro ao obter preferências da organização.',
    get_fees: 'Erro ao obter taxas.',
    create_transaction: 'Erro ao enviar criptomoeda.',
    get_plan_historic: 'Erro ao obter dados de uso do plano.',
    get_plans: 'Erro ao obter planos',
    get_plans_payment: 'Erro ao obter pagamento de planos',
    purchase_plan: 'Erro ao comprar plano.',
    update_credit_card: 'Erro ao atualizar cartão de crédito',
    cancel_plan: 'Erro ao cancelar plano',
    interact_contract: 'Erro na execução',
    get_assets_list: 'Erro ao obter lista de ativos.',
    get_assets_template_list: 'Erro ao obter contratos inteligentes.',
    get_draft: 'Erro ao obter rascunho',
    get_smart_contract: 'Erro ao obter modelo de contrato inteligente por ID.',
    save_draft: 'Erro ao salvar como rascunho',
    update_draft: 'Erro ao atualizar rascunho',
    deploying_contract: 'Erro ao implantar contrato',
    saving_contract: 'Erro ao salvar campos do contrato',
    update_template_contract: 'Erro ao salvar campos do contrato',
    swap_preview: 'Erro ao obter prévia de troca',
    get_swap_transaction: 'Erro ao obter transações de troca',
    swap_crypto: 'Erro ao trocar criptomoeda.',
    default_tokens: 'Erro ao obter tokens padrão.',
    get_tokens_contract: 'Erro ao obter contrato de token.',
    add_token_contract: 'Erro ao adicionar contrato de token.',
    delete_token_contract: 'Erro ao excluir contrato de token.',
    verifying_identity: 'Erro ao verificar identidade',
    create_network_wallet: 'Erro ao criar nova carteira.',
    get_wallets: 'Erro ao obter carteiras.',
    get_wallet_token_balance: 'Erro ao obter saldo de token na carteira.',
    get_wallet_token_history: 'Erro ao obter histórico de token na carteira.',
    creating_webhook: 'Erro ao criar webhook.',
    get_webhooks_by_network: 'Erro ao obter contagem de webhooks por rede.',
    pix_not_found: 'Chave pix não entrada',
    POLICY_ALREADY_IN_USE_EXCEPTION:
      'Não foi possível atualizar esta política porque ela já está associada à um cofre.',
    REQUEST_ERROR_EXCEPTION:
      'Ocorreu um erro, tente novamente em alguns instantes',
    INTERNAL_SERVER_ERROR:
      'Ocorreu um erro, tente novamente em alguns instantes',
    INPUT_VALIDATION_EXCEPTION:
      'Erro ao processar a solicitação, verifique os dados informados',
    InvalidCodeException: 'Código inválido',
    TRANSACTION_FAILURE_EXCEPTION:
      'Erro ao processar a transação, tente novamente em alguns instantes',
    ORGANIZATION_ID_NOT_FOUND_EXCEPTION: 'Organização inválida',
    INCORRECT_PASSWORDS_EXCEPTION: 'Senhas inválidas',
    UNAUTHORIZED_TRANSACTION_EXCEPTION: 'Operação inválida',
    INSUFFICIENT_BALANCE_EXCEPTION: 'Saldo insuficiente',
    UNEXPECTED_PROVIDER_RESPONSE_EXCEPTION:
      'Ocorreu um erro, tente novamente em alguns instantes',
    NOT_FOUND_EXCEPTION: 'Recurso não encontrado',
    HTTP_EXCEPTION: 'Ocorreu um erro, tente novamente em alguns instantes',
    BAD_STORE_EXCEPTION: 'Erro ao armazenar o arquivo',
    INVALID_FILE_FORMAT_EXCEPTION: 'Formato de arquivo inválido',
    INVALID_PASSWORDS_EXCEPTION: 'Senhas inválidas',
    INVALID_PASSWORD_EXCEPTION: 'Senha inválida',
    InputValidationException:
      'Erro ao processar a solicitação, verifique os dados informados',
    RESOURCE_NOT_FOUND_EXCEPTION: 'Recurso não encontrado',
    STORE_NOT_ALLOWED_EXCEPTION: 'Erro ao armazenar o arquivo',
    INVALID_AUTH_TOKEN_EXCEPTION: 'Token de autenticação inválido',
    RESOURCE_CONFLICT_EXCEPTION: 'O recurso informado é inválido',
    INVALID_PERMISSION_EXCEPTION:
      'Você não tem permissão para realizar esta ação',
    UNPROCESSABLE_ENTITY_EXCEPTION:
      'Ocorreu um erro, tente novamente em alguns instantes',
    EXTERNAL_API_EXCEPTION:
      'Ocorreu um erro, tente novamente em alguns instantes',
    INCORRECT_PASSWORD_EXCEPTION: 'Senha inválida',
    INVALID_WALLET_ADDRESS_EXCEPTION:
      'O endereço de carteira informado é inválido',
    NETWORK_NOT_FOUND_EXCEPTION: 'Rede blockchain inválida',
    WRONG_FIELD_INPUT_EXCEPTION: 'Os parâmetros informados são inválidos',
    CUSTOM_WEBHOOK_ALREADY_EXISTS_EXCEPTION:
      'Este endereço já esta sendo monitorado',
    MISSING_DEFAULT_WEBHOOK_EXCEPTION:
      'As configurações padrões de Webhook não foram encontradas',
    WEBHOOK_NOT_ENABLED_EXCEPTION:
      'Este serviço não está habilitado para a rede blockchain informada',
    UserAlreadyExistsException: 'Usuário já existe',
    ASSET_POLICY_RULE_EXCEPTION:
      'A política do cofre não permite o envio da moeda informada',
    SECURE_DESTINATIONS_POLICY_RULE_EXCEPTION:
      'A política do cofre não permite o envio para o endereço informado',
    AMOUNT_POLICY_RULE_EXCEPTION:
      'A política do cofre não permite o envio do valor informado'
  },
  success: {
    api_key_deleted: 'Chave de API deletada com sucesso.',
    handle_credencials: 'Chave da API excluída com sucesso.',
    remove_application: 'Aplicação removida com sucesso.',
    create_public_key: 'Chave pública alterada.',
    send_crypto: 'Cripto enviada com sucesso.',
    url_added: 'URL adicionada com sucesso.',
    url_updated: 'URL atualizada com sucesso.',
    transaction_created: 'Transação criada com successo',
    plan_purchase: 'Plano adquirido com sucesso.',
    import_contract: 'Contrato importado com sucesso.',
    swap: 'Swap criado com sucesso.',
    delete_token: 'Contrato do token excluído.',
    new_wallet: 'Nova carteira criada.',
    webhook: 'Webhook criado.',
    credencials_created: 'Credenciais criadas com sucesso.',
    new_application: 'Nova aplicação criada com sucesso.',
    verify_code: 'Código verificado com sucesso.',
    credit_card_update: 'Cartão de crédito atualizado com sucesso.',
    cancel_plan: 'Plano cancelado com sucesso.',
    token_contract: 'Contrato de token adicionado.',
    add_token_contract: 'Contrato de token adicionado.',
    resend_confirmation_code: 'Código de confirmação reenviado.',
    member_invite: 'Convite enviado com sucesso',
    member_delete: 'Usuário deletado com sucesso'
  }
};
