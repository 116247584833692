export const asset_tokenization_home_page = {
  breadcrumb_title: 'Tokenização de ativos digitais',
  total_of_assets: 'Ativos digitais',
  table: {
    headers: {
      contract_address: 'Endereço do contrato'
    }
  },
  import_asset_dialog_title: 'Importar contrato inteligente',
  snackbar: {
    success: {
      on_import: 'Contrato importado.'
    },
    errors: {
      on_import: 'Erro ao importar contrato.'
    }
  }
};
