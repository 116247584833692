export const webSiteEn = {
  Menu: {
    option1: 'Solutions',
    option2: 'Pricing',
    option3: 'Get started',
    option4: 'Login',
    option5: 'Documentation'
  },
  TechnologyApplications: {
    title: 'Blockchain technology applications',
    target: 'Target',
    advantages: 'Advantages',
    wallets: {
      title: 'Wallets',
      slug: 'wallets',
      cases: {
        wallet: {
          button: 'Cryptocurrency Wallet',
          title: 'Cryptocurrency Wallet with buy and sell',
          description: 'Wallets of market crypto, Wallets of own tokens.',
          targets: {
            banks: 'Banks',
            fintechs: 'Fintechs',
            game: 'Game developers',
            developers: 'Software developers'
          },
          advantages0: 'Ease of integration with crypto exchanges',
          advantages1: 'Possibility to create your own liquidity pools'
        }
      }
    },
    tokens: {
      title: 'Asset tokenization',
      slug: 'assetTokenization',
      cases: {
        platform: {
          button: 'Tokenization platform',
          title: 'Digital asset tokenization platform',
          description:
            'Tokenize a real-world asset (liquid assets, carbon credit, etc).',
          targets: {
            banks: 'Banks',
            fintechs: 'Fintechs',
            sustainability: 'Sustainability platforms'
          },
          advantages0: 'Split and trade non-fractionable assets',
          advantages1: 'Split and trade illiquid assets'
        },
        digital: {
          button: 'Digital Token',
          title: 'Creation of digital tokens',
          description: 'Tokenize carbon credits, tokenize game currency.',
          targets: {
            game: 'Game developers',
            fintechs: 'Fintechs',
            sustainability: 'Sustainability platforms'
          },
          advantages0:
            'Management of digital assets in a transparent and secure way'
        },
        gameCurrency: {
          button: 'Currency for games',
          title: 'Digital currency for games',
          description:
            'A game could use crypto Wallets to move the digital money used within the game, companies can literally create their own currency within a Blockchain.',
          targets: {
            game: 'Game developers'
          },
          advantages0: 'Security',
          advantages1: 'Transparency'
        },
        crowdfunding: {
          button: 'Crowdfunding platform',
          title: 'Decentralized crowdfunding platform',
          description: 'Decentralized crowdfunding platform',
          targets: {
            startups: 'Startups',
            social: 'Social projects',
            investors: 'Investors'
          },
          advantages0: 'Access to global funding',
          advantages1:
            'Greater transparency in transactions and participation of investors from all over the world'
        },
        fidelity: {
          button: 'Fidelity program',
          title: 'Blockchain-based loyalty programs',
          description:
            'Companies can create loyalty programs where reward points are represented as tokens on the Blockchain, enabling customers to transfer, exchange or redeem their points with greater flexibility.',
          targets: {
            retail: 'Retail store',
            hospitality: 'Hospitality',
            airline: 'Airline'
          },
          advantages0: 'Reduction of operating costs',
          advantages1: 'Greater customer engagements',
          advantages2: 'Traceability of transactions'
        },
        reward: {
          button: 'Reward program',
          title: 'Employee reward programs',
          description:
            'Companies can implement incentive programs for their employees, where rewards are granted in the form of tokens that can be used in an internal marketplace or exchanged for benefits.',
          targets: {
            companies: 'Companies from all sectors'
          },
          advantages0: 'Increased employee motivation',
          advantages1: 'Transparent performance tracking',
          advantages2: 'Reduced costs with traditional benefit programs'
        }
      }
    },
    nfts: {
      title: 'NFTs',
      slug: 'nfts',
      cases: {
        platform: {
          button: 'NFTs Platform',
          title: 'NFTs platform',
          description:
            'Allow companies to store and display their NFT in a marketplace, a digital platform for buying and selling NFT.',
          targets: {
            banks: 'Banks',
            fintechs: 'Fintechs',
            others: 'Others'
          },
          advantages0: 'Security',
          advantages1: 'Transparency',
          advantages2: 'Automated flow'
        },
        tickets: {
          button: 'Tickets as NFTs',
          title: 'Tickets as NFT',
          description:
            'A marketplace for NFT event tickets that enables artists and companies to establish stronger connections with their audience, while also eradicating fraud and minimizing the effects of scalping.',
          targets: {
            concerts: 'Concerts',
            movies: 'Movies',
            others: 'Others'
          },
          advantages0: 'Security',
          advantages1: 'Transparency',
          advantages2: 'Tickets as collectibles'
        },
        art: {
          button: 'Digital art',
          title: 'Digital art market',
          description:
            'Create an online marketplace for digital artists to sell their works as NFTs, allowing creations to be authenticated, owned and resold transparently on the Blockchain.',
          targets: {
            artists: 'Digital artists',
            collectors: 'NFT collectors'
          },
          advantages0: 'Greater reach for independent artists',
          advantages1: 'Protection against forgeries of works of art',
          advantages2: 'Automated commissions for artists at resellers'
        }
      }
    },
    trade: {
      title: 'Trade & Swap',
      slug: 'tradeSwap',
      cases: {
        broker: {
          button: 'Assets broker',
          title: 'Digital asset broker',
          description:
            'Tokenize a real-world asset (illiquid assets, carbon credit, etc)',
          targets: {
            companies: 'Companies that want to create a digital asset brokerage'
          },
          advantages0: 'Security',
          advantages1: 'Transparency'
        },
        custody: {
          button: 'Assets custody',
          title: 'Digital assets custody',
          description: 'Securing digital assets with top-notch escrow.',
          targets: {
            banks: 'Banks',
            funds: 'Investment Funds'
          },
          advantages0: 'Security',
          advantages1: 'Transparency'
        }
      }
    },
    buttons: {
      contact: 'Contact us'
    }
  },
  BannerSection: {
    banner1: {
      titleComplete:
        'Infrastructure to connect companies to the Blockchain and integrate more secure and efficient solutions through APIs',
      title: 'Infrastructure to connect companies to the Blockchain',
      title2: 'and integrate more secure and efficient solutions',
      title3: 'through APIs',
      button: 'Our Services',
      button2: 'Service for companies'
    },
    banner2: {
      title: 'Unlimited Cryptocurrency Digital Wallets creation',
      description:
        'Easy to implement on your platform just copying and pasting codes',
      button: 'Learn more'
    },
    banner3: {
      title: 'Increase productivity in asset tokenization',
      description: 'Hassle-free asset tokenization on major networks',
      button: 'Learn more'
    },
    banner4: {
      title: 'Cost-cutting to create your own NFTs',
      description:
        'Easy to use Toolkit to create tokens reducing operating costs',
      button: 'Learn more'
    }
  },
  CardSection: {
    title: 'Web 3.0 development',
    description: 'Future ready',
    card1: {
      title: 'Ease',
      description:
        'Blockchain complexity abstraction, simplifying the implementation of digital ecosystems in a few clicks.'
    },
    card2: {
      title: 'Economy',
      description:
        'Fast service integration, saving time for essential activities and cutting operational costs, where you only pay for what is consumed.'
    },
    card3: {
      title: 'Accessibility',
      description:
        'Convenience for blockchain access, providing fast flows and intuitive tools for users without technical knowledge.'
    }
  },
  NetworksSection: {
    title: 'Build on 10.000+ BaaS-supported assets',
    description: 'The main blockchains on the market',
    titlePart1on720: 'Build on 10.000+',
    titlePart2on720: 'BaaS-supported networks'
  },
  StartNowSection: {
    title: 'Any doubts?',
    description: 'Fill in your details below and we will get back to you'
  },
  UseCasesSection: {
    title: 'Discover solutions that are already being used by our customers',
    description:
      'Integrate your company with all the benefits of Web3.0 with Blockchain as a Service.',
    banking_and_finance: 'Banking and finance',
    banking_and_finance_description: 'Digital asset Wallet and Token platform',
    use_case_text:
      'We connect more than 64 million people to the cryptocurrency universe, providing digital wallet opening and asset transactions services, through the first banking institution to operate in Brazil, with more than 200 years of existence.'
  },
  InvestorsSection: {
    title: 'They believe in us',
    investors: 'Investors:',
    accelerated_by: 'Accelerated by:'
  },
  Footer: {
    social_media_text: 'Follow us on social media',
    services_status: 'Services status',
    description1: 'Bitfy - All rights reserved - {{year}}',
    description2:
      'Bitfy Intermediações e Serviços de Pagamentos LTDA. CNPJ: 31.255.976/0001-39'
  },
  ApiDocsSection: {
    title: 'API & docs',
    subTitle: 'Simple integration via code',
    description:
      'Two code hosting platforms, for developers to copy and paste code for services integration, simplifying and providing more comfort to their work.',
    button: 'Get now'
  },
  StartTutorial: {
    title: 'How to get started?',
    description: '3 simple steps!',
    step1: {
      title: 'Create an account',
      description:
        'By creating a new account you have access to our platform and our technologies. You can create a new account here.'
    },
    step2: {
      title: 'Choose the plan',
      description:
        'We have 4 plans for you to choose what best suits you or you company. You can see all available plans here.'
    },
    step3: {
      title: 'Make the payment',
      description:
        'Our platform allows users to pay via credit card and all our transactions are secure.'
    },
    step4: {
      title:
        'And we are done! You are now ready to start using our technologies.'
    },
    buttons: {
      anyDoubts: 'Any doubts?',
      faq: 'FAQ'
    }
  },
  Services: {
    title: 'Cutting Edge Services',
    subTitle: 'One-stop-shop Hub.',
    card0: {
      title: 'Digital Wallets',
      subTitle: 'Easy implementation on Blockchain technology.',
      description:
        'Digital wallets for storage and/or per-to-per transactions, easy to implement on your platform through simple and straightforward integration. Available for more than 10,000 assets on various Blockchains, offering the possibility for companies to create self-custodial or non-custodial wallets.'
    },
    card1: {
      title: 'Tokens',
      subTitle: 'Asset tokenization, fast and easy.',
      description:
        'Increase your productivity by minimizing risks and excessive technological effort, while creating tokens and smart contracts in an instant, all automated, on the main Blockchains in the market.'
    },
    card2: {
      title: 'NFTs',
      subTitle: 'Available for the main Blockchains in the market.',
      description:
        'Easy-to-use toolkit for companies to create their own NFTs faster and more securely, based on any network supported by BaaS, abstracting the difficulties of connecting blockchains and the application, reducing operational and infrastructure costs.'
    },
    card3: {
      title: 'Swap',
      subTitle: 'Cryptocurrency portfolio’s redistribution.',
      description:
        'Ease of exchanging assets in a convenient way, allowing customers to redeploy their cryptocurrency portfolio in an instant, reducing transaction costs.'
    },
    card4: {
      title: 'Trade',
      subTitle: 'Buying and selling assets made simple.',
      description:
        'Allow your customers to buy and sell assets quickly and automatically, allowing companies to transact the main cryptocurrencies in the market.'
    },
    card5: {
      title: 'Webhook',
      subTitle:
        'Abstract the complexity of monitoring wallets on the Blockchain.',
      description:
        'Keep your client informed about any type of transaction within the blockchain with our notification platform, reducing unnecessary expenses while monitoring transactions on the blockchain.'
    }
  },
  Pricing: {
    title: 'Pricing',
    subTitle: 'Choose one of the plans, or contact us',
    yearly: 'Yearly',
    monthly: 'Monthly',
    availablePlans: 'Available plans',
    contact:
      'Get in touch with us and have the best plan for your organization.',
    button: 'Start',
    buttonContact: 'Contact us'
  },
  Contact: {
    title: 'Contact',
    send_email: 'Send email',
    contact_by_email: 'Contact us via e-mail',
    form: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      message: 'Message',
      messagePlaceholder: 'Type your message',
      button: 'Send'
    }
  },
  FAQ: {
    description: 'Frequently asked questions',
    question0: {
      title: 'Is there a free trial available?',
      content:
        'Yes, just click on "Start Now" in the top menu to go to the sandbox environment and start your free trial.'
    },
    question1: {
      title: 'Can I change my plan later?',
      content:
        'Yes. Once you log in you are able to see your current plan and also pick another plan that best suits you or your company.'
    },
    question2: {
      title: 'How does billing work?',
      content:
        'Our platform uses credit card as the main payment option. You can choose to pay monthly or yearly.'
    },
    question3: {
      title: 'Are these transactions secure?',
      content:
        'Yes, these transactions are secure and our team is cosistently working to keep your information private.'
    },
    question4: {
      title: 'I already have an account. What to do next?',
      content:
        'If you already have an account, you may log in into our platform and choose one our plans to start using our technologies.'
    }
  },
  GetStarted: {
    title: 'How to get started?',
    description: '3 simple steps!',
    step0: {
      title: 'Create an account',
      description:
        'By creating a new account you have access to our platform and our technologies. You can create a new account',
      here: 'here'
    },
    step1: {
      title: 'Choose the plan',
      description:
        'We have some plans for you to choose what best suits you or your company. You can see all available plans',
      here: 'here'
    },
    step2: {
      title: 'Make the payment',
      description:
        'Our platform allows users to pay via credit card and all our transactions are secure'
    },
    done: 'And we are done! You are now ready to start using our technologies.',
    faq: 'FAQ',
    doubts: 'Any doubts?'
  },
  Sandbox: {
    title: 'Sandbox environment',
    description:
      'Want to know the solution? Access our sandbox environment. No costs involved.',
    testNow: 'Start Test'
  },
  Cookie: {
    warning_text:
      'We use third-party cookies to personalize content and analyze site trafic.',
    learn_more: 'Learn more',
    accept: 'Accept'
  }
};
