export const wallet_details_page = {
  breadcrumb_title: '{{walletReference}}',
  transactions_table: {
    title: 'Transações',
    headers: {
      with_custody: 'Com custódia'
    }
  },
  buy_and_sell_table: {
    headers: {
      transaction: 'Transações',
      pair: 'Par',
      explorer: 'Explorador'
    }
  },
  webhooks_table: {
    headers: {
      url: 'URL',
      response: 'Resposta'
    }
  },
  send_crypto_form: {
    title: 'Enviar criptomoeda'
  },
  receive_crypto_form: {
    title: 'Receber criptomoeda',
    informative_label: 'Você pode especificar o valor a ser recebido'
  },
  snackbar: {
    success: {
      sent_crypto: 'Transação concluída'
    },
    errors: {
      get_wallet_generic_error: 'Erro ao recuperar carteira'
    }
  },
  buttons: {
    send: 'Enviar',
    receive: 'Receber'
  },
  details: {
    title: 'Detalhes'
  }
};
