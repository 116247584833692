export const forgotPasswordForm = {
  title: 'Forgot your password?',
  description: 'Enter your e-mail address below.',
  description2: 'We will send you a verification code.',

  emailLabelField: 'E-mail',
  codeLabelField: 'Code',
  emailRequiredField: 'E-mail is required',
  emailInvalidField: 'E-mail is not valid',
  emailErrorField: 'Wrong e-mail',

  sendCodeButton: 'Send code',
  backToLoginButton: 'Back to login',

  snackbar: {
    titleSuccess: 'Code sent successfully.',
    descriptionSuccess: 'Please, check your e-mail.'
  }
};

export const validationCodeForm = {
  title: 'Verify code',
  description: 'Check your inbox and enter below the code we sent you.',

  codeLabelField: 'Code',
  codeRequiredField: 'Code is required',
  codeErrorField: 'Wrong code',

  verifyCodeButton: 'Verify and continue',
  backToLoginButton: 'Back to login',
  resendCodeButton: 'Resend code {{time}}',

  snackbar: {
    titleSuccess: 'Code validated successfully.',
    descriptionSuccess: 'Now you are able to sign in.'
  }
};

export const resetPasswordForm = {
  title: 'Reset password',
  description: 'Create a new password.',

  passwordLabelField: 'Password',
  passwordRequiredField: 'Password is required',
  passwordDoesNotMatchField: 'Password does not match',
  passwordMinCharsField: 'Password must be at least 10 characters',
  passwordRegexField: 'Wrong password requirements.',

  confirmPasswordLabelField: 'Confirm password',
  confirmPasswordRequiredField: 'Confirm password is required',

  resetPasswordButton: 'Reset password',
  backToLoginButton: 'Back to login',

  snackbar: {
    titleSuccess: 'Password reset successfully.',
    descriptionSuccess: 'Try to sign in with the new password.'
  }
};
