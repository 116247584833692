export const asset_tokenization_details_page = {
  breadcrumb_title: 'Asset details',
  connected_to_wallet_label: 'Connected to wallet',
  owner_id_label: 'Owner ID',
  owner_address_label: 'Owner address',
  contract_name_label: 'Contract name',
  contract_address_label: 'Contract address',
  connect_form: {
    title: 'Connect',
    fields: {
      connect_to_wallet: 'Connect to wallet'
    }
  },
  disconnect_dialog: {
    title: 'Disconnect?',
    description: 'You will be disconnected from {{walletReference}}.'
  },
  history_dialog_title: 'History',
  execute_dialog: {
    title: 'Execute function?',
    description: 'You will be disconnected from {{walletReference}}.',
    function_summary_label: 'Function'
  },
  snackbar: {
    success: {
      on_connect_wallet: 'Connected to {{walletReference}}.',
      on_execute_function: 'Function executed.'
    },
    errors: {
      on_password_invalid: 'Invalid password.'
    }
  },
  buttons: {
    connect: 'Connect',
    connect_wallet: 'Connect Wallet',
    disconnect_tooltip: 'Disconnect',
    history_tooltip: 'History',
    execute_function: 'Execute'
  }
};
