/* eslint-disable import/no-cycle */
import { Typography, TypographyProps, TypographyStyle } from '@mui/material';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  TranslateInterpolationType,
  TranslationValuePath,
  translateWithTags
} from 'i18n';
import { useContext, useEffect } from 'react';

export type TextTagProps = {
  color?: string;
  typography?: Omit<TypographyProps, 'children'>;
  fontWeight?: TypographyProps['fontWeight'];
  onClick?: (tagIndex: number) => void;
  textDecorationLine?: TypographyStyle['textDecorationLine'];
};

export interface TextProps extends TypographyProps {
  tagProps?: Record<string, TextTagProps>;
  intlPath?: TranslationValuePath;
  interpolate?: { [key: string]: string | number };
}

export default function Text({
  intlPath: value,
  interpolate,
  onClick,
  tagProps,
  children,
  ...props
}: TextProps): JSX.Element {
  const { languageSelected } = useContext(LanguageContext);
  useEffect(() => {
    // this is supposed to be empty, do not delete it
  }, [languageSelected]);
  return (
    <Typography onClick={onClick} {...props}>
      {!!value
        ? translateWithTags(
            value as TranslationValuePath,
            interpolate as TranslateInterpolationType,
            tagProps
          )
        : children}
    </Typography>
  );
}
