export const wallets_home_page = {
  breadcrumb_title: 'Carteiras',
  my_wallets: 'Minhas carteiras',
  token_contracts: 'Contratos de token',
  my_wallets_tab: {
    wallets_qtt: '{{value}} carteiras',
    wallets_qtt_singular: '{{value}} carteira',
    wallet_details: 'Detalhes da carteira',
    tab_label: 'Minhas carteiras',
    new_wallet_form: {
      title: 'Nova Carteira',
      wallets_limit_informative:
        'Foram criadas <emphasis>{{amountOfWallets}}</emphasis> carteiras do total de <emphasis>{{walletsLimit}}</emphasis> carteiras disponíveis ',
      description:
        'Você está criando uma carteira a nível de rede. Será possível realizar transações tanto na moeda nativa quanto nos tokens que pertencem a esta rede.',
      informative:
        'Um conjunto de senhas será retornado após a criação da Carteira.',
      informative_password_store:
        'Um conjunto de senhas foi gerado para esta Carteira. <emphasis>Por favor, esteja ciente de que você precisa armazenar estas senhas para usá-las posteriormente.</emphasis>',
      fields: {
        address_type: 'Tipo de Endereço',
        password_store_checkbox:
          'Eu entendo que preciso armazenar as senhas desta Carteira.'
      },
      success: {
        wallet_created: 'Sua Carteira foi criada com sucesso.'
      }
    },
    snackbar: {
      success: {
        wallet_created_with_webhook:
          'Carteira criada. Webhook esta ativo para esta carteira.',
        wallet_created_without_webhook:
          'Carteira criada. Webhook não está ativo para esta carteira.'
      },
      errors: {
        generic_error: 'Erro ao criar uma nova carteira.'
      }
    }
  },
  token_contracts_tab: {
    tab_label: 'Contratos de token',
    description:
      'Adicione contratos de tokens às redes de Blockchain disponíveis na plataforma. Ao adicionar contratos de tokens a uma rede, ela fica disponível em todas as carteiras criadas nesta rede.',
    custom_token_contract_form: {
      title: 'Novo contrato de Token personalizado',
      fields: {
        token_name: 'Nome do Token'
      }
    },
    delete_token_contract_dialog: {
      title: 'Excluir contrato de Token?',
      description:
        'O contrato de Token será removido. Todas as carteira desta rede deixaram de exibir o token em questão.'
    },
    snackbar: {
      success: {
        token_contract_added: 'Contrato de Token adicionado.'
      },
      errors: {
        generic_error: 'Erro ao adicionar contrato de token.'
      }
    },
    token_already_in_use: {
      title: 'Falha ao criar o contrato do token',
      description:
        'O contrato do token que você está tentando adicionar já está em uso.'
    },
    invalid_address:
      'O contrato do token que você está tentando adicionar possui um endereço inválido. Corrija-o e tente novamente.'
  },
  buttons: {
    create_wallet: 'Criar Carteira',
    complete: 'Completar',
    add_custom: 'Adicionar token personalizado',
    create_token_contract: 'Criar contrato de Token'
  }
};
