export const taxes_payment_page = {
  title: 'Pagamento de impostos',
  table: {
    title: 'Transactions',
    header: {
      receipt: 'Recibo'
    },
    see_receipt: 'Ver recibo'
  },
  forms: {
    taxes_payment: {
      barcode: 'Código de barras',
      input: 'Insira o código de barras',
      tax_amount: 'Valor do imposto',
      due_date: 'Data do vencimento',
      account_balance: 'Saldo da conta',
      total_ammount: 'Valor total'
    },
    payment_receipt: {
      title: 'Recibo do pagamento',
      activity: 'Activity',
      operation_value: 'Valor da operação',
      service_fee: 'Taxa de serviço',
      download: 'Download'
    }
  },
  buttons: {
    pay_taxes: 'Pagar imposto'
  }
};
