export const settings_menu_items = {
  groups: {
    general: 'Geral',
    team: 'Time',
    security: 'Segurança',
    services: 'Serviços'
  },
  items: {
    languages: 'Idioma',
    members: 'Membros',
    two_factor_authentication: 'Autenticação de 2 fatores',
    ip_permissions: 'Bloqueio por IP',
    api_key: 'Chave de API',
    public_key: 'Chave pública',
    webhook_url: 'Endereço de Webhook'
  }
};
