export const two_factor_settings = {
  breadcrumb_title: '2-factor',
  steps: {
    register_2fa: {
      title: 'Autenticação de 2 fatores',
      subtitle: 'Cadastrar um dispositivo de autenticação',
      step_1_title: 'Baixe um aplicativo de autenticação em seu smartphone',
      step_1_desctiption:
        'Instale um aplicativo de autenticação como o <link_google>Google Authenticator</link_google>, <link_authy>Authy</link_authy>, <link_duo_mobile>Duo Mobile</link_duo_mobile> ou <link_one_password>1Password</link_one_password>.',
      step_2_title: 'Escaneie o QR Code',
      step_2_description:
        'Use o aplicativo de autenticação em seu smartphone para escanear o QR Code abaixo:',
      step_2_observation:
        'Caso não consiga escanear o QR Code, digite este código manualmente:',
      step_3_title: 'Digite o código de autenticação',
      step_3_description:
        'Digite o código de 6 dígitos que foi gerado no aplicativo de autenticação:',
      skip_button: 'Pular',
      skip_dialog_title: 'Não exibir este passo novamente?',
      skip_dialog_description:
        'Você sempre poderá alterá-lo no menu de configurações.'
    },
    registered_2fa: {
      title: 'Autenticação de 2 fatores ativa',
      subtitle_settings:
        'Autenticação de 2 fatores configurada. Veja como usá-lo em suas transações:',
      subtitle_on_login:
        'Use o código de autenticação de 2 fatores para continuar',
      subtitle_required_to_proceed:
        'Use o código de autenticação de 2 fatores para concluir esta transação',
      step_1_title: 'Abra o aplicativo de autenticação de 2 fatores',
      step_1_desctiption: 'Verifique o aplicativo instalado em seu smartphone.',
      step_2_title: 'Verifique o código gerado',
      step_2_description:
        'Um código de 6 dígitos está sendo exibido no aplicativo de autenticação.',
      step_3_title: 'Digite o código de 6 dígitos',
      step_3_description:
        'Digite o código de 6 dígitos que foi gerado no aplicativo de autenticação.'
    },
    turn_off_2fa_dialog: {
      title: 'Desativar a autenticação de 2 fatores?',
      description:
        'Todas as configurações de duplo fator de autenticação serão redefinidas e uma nova deverá ser configurada.'
    },
    turn_on_2fa_dialog: {
      title: 'Ativar a autenticação de 2 fatores?',
      description: 'É necessário um novo cadastro.'
    }
  },
  buttons: {
    verify_continue_button: 'Verificar e continuar',
    cancel_login_button: 'Cancelar login',
    turn_off: 'Desativar',
    turn_on: 'Ativar'
  },
  turn_off_dialog: {
    title: 'Desativar a autenticação de 2 fatores?',
    description:
      'Todas as configurações de duplo fator de autenticação serão redefinidas e uma nova deverá ser configurada.',
    confirmation_label: 'Desativar e resetar'
  },
  notifications: {
    success: {
      removed_2fa: 'A autenticação de 2 fatores foi desativada.'
    },
    error: {
      removed_2fa: 'Erro ao remover autenticação de 2 fatores.'
    }
  }
};
