export const internal_services_page = {
  breadcrumb_title: 'Services',
  services: {
    vaults: {
      title: 'Vaults',
      description:
        'Securely manage transactions and wallets with our Vaults and customize Transaction Policies effortlessly.'
    },
    wallets: {
      title: 'Wallets',
      description:
        'Create and manage (know your balance, send and receive) the main cryptocurrencies on the market.'
    },
    buy_sell: {
      title: 'Buy & Sell',
      description:
        'Have you ever created wallets? Trade the cryptocurrencies you own. Buy and sell any digital asset.'
    },
    swap: {
      title: 'Swap',
      description: 'Have BTC and need ETH? No need to sell and buy, do a SWAP.'
    },
    asset_tokenization: {
      title: 'Asset Tokenization',
      description:
        'Tokenize any type of asset on any of the main market blockchains.'
    },
    nft: {
      title: 'NFT',
      description:
        'Create your NFTs (image, video, and audio) on the main market blockchains in instants.'
    },
    webhooks: {
      title: 'Webhooks',
      description:
        'Notify your system when you receive cryptocurrencies from anywhere in the world.'
    },
    taxes_payment: {
      title: 'Taxes payment',
      description:
        'Simplify tax payments with our secure and efficient platform.'
    },
    defi: {
      title: 'DeFi',
      description:
        'Lend crypto and receive % on the coin itself. Borrow cash in Real (BRL) and use it as a warranty.'
    },
    naas: {
      title: 'Node as a Service',
      description:
        "Your access to the main blockchains' data in real time and on a 24/7 basis."
    }
  },
  blocked_organization: {
    title: 'Blocked company',
    description:
      'We are aware that inconveniences may happen. Please contact our team to continue to use our Services.'
  },
  verify_account: {
    title: 'Verify account',
    description:
      'By verifying your account you will be able to carry out purchase, sale and exchange of crypto or deposits and withdrawals in fiat currency.',
    step_1_required: 'Send documents to approval;',
    link_button: 'Send documentation',
    title_pending: 'Documents sent to approval',
    description_pending:
      '<emphasis>Analysis in progress</empashis>. The status will change and you will receive an email once we verify the documentation.'
  },
  settings: {
    title: 'Services settings',
    description:
      'In order to use our services you need to <emphasis>first register a Public key and then an API key</emphasis> in the Settings page.',
    step_1_required: 'Register a Public Key;',
    step_2_required: 'Register an API Key;',
    link_button: 'Go to Settings'
  }
};
