export const nft_collection_page = {
  breadcrumb_title_collection: 'Collection',
  breadcrumb_title_details: 'NFT details',
  images_tab: 'Images',
  audios_tab: 'Audios',
  videos_tab: 'Videos',
  created: 'Created {{date}}',
  item: '{{value}} item',
  items: '{{value}} items',
  create_nft: 'Create NFT',
  create_nft_form: {
    sensitive_content: {
      title: 'Sensitive content',
      description:
        'Your file is marked as a sensitive content and will only be accessible once we verify your password.'
    },
    preview_image: {
      title: 'Preview image',
      description:
        'Provide an image (PNG, JPG or GIF) for the card display of your item.'
    }
  },
  file_type_label: 'File type',
  deploy_nft_dialog_title: 'Deploy NFT',
  transfer_nft_dialog_title: 'Transfer NFT',
  cancel_dialog: {
    title: 'Cancel NFT creation',
    description: 'Changes will be lost'
  },
  sensitive_content_dialog: {
    description: 'This file is marked as sensitive and requires a password.'
  },
  snackbar: {
    success: {
      on_create: 'NFT created.',
      on_transfer: 'NFT transfered.'
    },
    error: {
      on_create: 'Error on create NFT.',
      on_transfer: 'Error on transfer NFT.'
    }
  },
  buttons: {
    upload_file: 'Upload file',
    reupload_file: 'Reupload file',
    transfer: 'Transfer',
    check_password: 'Check password'
  }
};
