export const new_swap_page = {
  breadcrumb_title: 'Nova troca',
  form: {
    title: 'Quero trocar',
    title2: 'Converter para',
    header: {
      converted_to: 'Convertido para',
      source_blockchain: 'Blockchain de origem',
      destination_blockchain: 'Blockchain de destino',
      converted_value: 'Valor convertido',
      source_hash: 'Hash da origem',
      destination_hash: 'Hash do destino'
    },
    errors: {
      insufficient_funds: 'Saldo insuficiente. Considere a taxa de rede.'
    }
  },
  dialog: {
    title: 'Trocar criptomoedas'
  },
  buttons: {
    swap_crypto: 'Trocar'
  }
};
