export const signUpForm = {
  title: 'Create a new account',
  description: '2-factor authentication',

  nameLabelField: 'Name',
  nameRequiredField: 'Name is required',

  emailLabelField: 'E-mail',
  emailRequiredField: 'E-mail is required',
  emailInvalidField: 'E-mail is not valid',

  documentLabelField: 'Document',
  documentRequiredField: 'Document is required',
  documentInvalidField: 'Document is not valid',

  companyLabelField: 'Company',
  companyPlaceholderField: 'Company name',
  companyRequiredField: 'Company is required',

  passwordLabelField: 'Password',
  passwordRequiredField: 'Password is required',
  passwordDoesNotMatchField: 'Password does not match',
  passwordMinCharsField: 'Password must be at least 10 characters',
  passwordRegexField: 'Wrong password requirements.',

  confirmPasswordLabelField: 'Confirm password',
  confirmPasswordRequiredField: 'Confirm password is required',

  alreadyHaveAccountText: 'Already have an account?',
  changes_will_be_lost: 'All changes will be lost.',
  signInButton: 'Login',
  signUpButton: 'Sign up',

  snackbar: {
    titleSuccess: 'User registered successfully.',
    descriptionSuccess: 'Please, verify the code we sent in your e-mail.'
  }
};
