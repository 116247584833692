import { SxProps } from '@mui/material';

const body: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'text.primary',
  fill: 'currentColor'
};

export default {
  body
};
