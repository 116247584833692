export const plans_and_payments_page = {
  breadcrumb_title: 'Planos & Pagamentos',
  available_plans_label: 'Planos disponíveis',
  chosen_plan_label: 'Plano escolhido',
  credit_card_label: 'Cartão de crédito',
  yearly_plans_tab: 'Anual',
  monthly_plans_tab: 'Mensal',
  new_plan_label: 'Novo plano',
  requests_per_motn_label: 'Requisições por mês',
  webhooks_per_motn_label: 'Webhooks por mês',
  months: {
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro'
  },
  unlimited: 'Ilimitado',
  current_plan: 'Plano atual',
  payment_confirmed: 'Pagamento confirmado',
  payment_pending: 'Pagamento pendente',
  infinite: 'Infinito',
  custom_plan_informative:
    'Entre em contato conosco e tenha o melhor plano para sua organização.',
  payment: 'Pagamento',
  valid_until: 'Válido até {{date}}',
  starts_at: 'Inicia em {{date}}',
  invoice: 'Fatura',
  paid: 'Pago',
  checkout_form: {
    title: 'Checkout',
    fields: {
      expiration_date: 'Expiração',
      card_number: 'Número do cartão',
      holder_name: 'Nome no cartão',
      cvv: 'CVV',
      installments: 'Parcelas'
    }
  },
  manage_billing_dialog: {
    title: 'Gerenciar pagamentos',
    billing_email_label: 'E-mail do financeiro',
    bank_transfer_payment_method: 'Transferência bancária',
    boleto_payment_method: 'Boleto',
    boleto_payment_method_informative:
      'Você receberá mensalmente um e-mail de nossa equipe contendo a fatura a ser paga.'
  },
  table: {
    title: 'Histórico de pagamentos',
    header: {
      bank_slip: 'Boleto bancário',
      date: 'Data',
      value: 'Valor',
      status: 'Status'
    }
  },
  snackbar: {
    success: {
      payment_confirmed: 'Pagamento confirmado'
    },
    errors: {
      payment_invalid: 'Erro ao processar o pagamento.'
    }
  },
  buttons: {
    choose: 'Escolha',
    contact_us: 'Entre em contato',
    confirm_payment: 'Confirme o pagamento {{value}}',
    manage_payment: 'Gerenciar',
    see_available_plans: 'Ver planos disponíveis',
    copy_bank_data: 'Copiar dados bancários',
    copy_bank_data_success: 'Dado bancário copiado com sucesso.'
  },
  new_plan: 'Novo plano'
};
