export const asset_tokenization_details_page = {
  breadcrumb_title: 'Detalhes do Ativo digital',
  connected_to_wallet_label: 'Conectado à carteira',
  owner_id_label: 'ID do Proprietário',
  owner_address_label: 'Endereço do Proprietário',
  contract_name_label: 'Nome do Contrato',
  contract_address_label: 'Endereço do Contrato',
  connect_form: {
    title: 'Conectar',
    fields: {
      connect_to_wallet: 'Conectar à carteira'
    }
  },
  disconnect_dialog: {
    title: 'Desconectar?',
    description: 'Você será desconectado de {{walletReference}}.'
  },
  history_dialog_title: 'Histórico',
  execute_dialog: {
    title: 'Executar função?',
    description: 'Você será desconectado de {{walletReference}}.',
    function_summary_label: 'Função'
  },
  snackbar: {
    success: {
      on_connect_wallet: 'Conectado a {{walletReference}}.',
      on_execute_function: 'Função executada.'
    },
    errors: {
      on_password_invalid: 'Senha inválida.'
    }
  },
  buttons: {
    connect: 'Conectar',
    connect_wallet: 'Conectar Carteira',
    disconnect_tooltip: 'Desconectar',
    history_tooltip: 'Histórico',
    execute_function: 'Executar'
  }
};
