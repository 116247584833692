export const common_translations = {
  status_labels: {
    available: 'Disponível',
    soon: 'Em breve',
    active: 'Ativo',
    pending_deploy: 'Implantação pendente',
    deploy_error: 'Erro na implantação',
    imported: 'Importado',
    verification_needed: 'Verificação necessária',
    pending: 'Pendente',
    pending_payment: 'Pagamento pendente',
    payment_confirmed: 'Pagamento confirmado',
    request_registered: 'Solicitação registrada com sucesso',
    payment_canceled: 'Pagamento cancelado',
    loading: 'Carregando...',
    empty: 'Vazio',
    format_not_supported: 'Formato não suportado',
    format_supported: 'Formato suportado',
    confirmed: 'Confirmed',
    outcome: 'Outcome',
    error: 'Erro',
    response_value: 'Resposta: {{value}}',
    change: 'Trocar',
    manage: 'Gerenciar',
    success: 'Sucesso'
  },
  labels: {
    hash_id: 'ID da hash',
    explorer: 'Explorador',
    details: 'Detalhes',
    hide: 'Esconder',
    show: 'Mostrar',
    connect: 'Conectar',
    disconnect: 'Desconectar',
    free_testing: 'Teste livre',
    image: 'Imagem',
    video: 'Video',
    audio: 'Audio',
    default: 'Padrão',
    blockchain: 'Blockchain',
    network: 'Rede',
    network_fee: 'Taxa da rede',
    blockchain_network: 'Rede Blockchain',
    created_at: 'Criado em {{formatedDate}}',
    symbol: 'Símbolo',
    symbols: 'Símbolos ({{amount}})',
    symbols_word: 'Símbolos',
    decimals_amount: '{{amount}} casas decimais',
    account_balance: 'Saldo da conta',
    date: 'Data',
    date_and_time: 'Data e hora',
    reference: 'Referência',
    destination_wallet: 'Carteira de destino',
    enter_destination_wallet: 'Digite a carteira de destino',
    wallet_reference: 'Referência da carteira',
    wallet_id: 'ID da carteira',
    wallet_identifier: 'Identificador da carteira',
    choose_balance: 'Escolha um balanço',
    wallet_balance: 'Saldo da carteira',
    wallet_address: 'Endereço da carteira',
    wallet: 'Carteira',
    wallets: 'Carteiras',
    address: 'Endereço',
    confirms: 'Confirmações',
    currency: 'Moeda',
    status: 'Status',
    value: 'Valor',
    lines: 'Linhas',
    value_to_send: 'Valor a ser enviado',
    type: 'Tipo',
    token: 'Token',
    name: 'Nome',
    token_name: 'Nome do token',
    description: 'Descrição',
    document: 'Documento',
    crypto_price: 'Preço da cripto',
    blockchain_token_price: 'Preço do {{blockchainToken}}',
    amount: 'Volume',
    search: 'Buscar',
    password: 'Senha',
    payment_method: 'Método de pagamento',
    amount_to_receive: 'Volume a receber',
    secure_transaction_text: 'Esta transação é segura',
    drag_and_drop: 'Arrasta e solta ou',
    requests: 'Requisições',
    services: 'Serviços',
    identifier: 'Identificador',
    id: 'ID',
    decimals: 'Decimais',
    features: 'Recursos',
    client_id: 'ID do cliente',
    key_copied: 'Chave copiada',
    client_secret: 'Segredo do cliente',
    edit_value: 'Editar valor',
    edit_warning: 'Editar o valor irá gerar outro código',
    code_expiration_time_one_hour: 'Esse código é válido por 1 hora',
    expires_in: 'Expira em {{value}}',
    back_login: 'Voltar para o login?',
    disable_webhook: 'Desabilitar Webhook?',
    disable_webhook_description:
      'O Webhook será desativado. Você pode reativá-lo mais tarde.',
    disable: 'Desabilitar',
    delete_webhook: 'Deletar Webhook?',
    delete_webhook_description: 'O Webhook será deletado',
    duration: 'Duração',
    created_by: 'Criado por',
    creation_date: 'Data de criação',
    actions: 'Ações',
    income: 'Entrada',
    outcome: 'Saída'
  },
  forms: {
    password_field_label: 'Senha',
    field_required: 'Campo obrigatório',
    exit_form_message: 'As alterações serão perdidas'
  },
  summaries: {
    new_transaction_step: 'Nova Transação',
    wallet_password_step: 'Senha da carteira',
    summary_step: 'Resumo',
    two_factor_step: 'Autenticação de 2 fatores',
    service_fee: 'Taxa de serviço',
    network_fee_extense: 'Taxa de rede para {{blockchainSymbol}}',
    network_fee_short: 'Taxa para {{blockchainSymbol}}',
    transaction_fee_label: 'Taxa da transação',
    informative_crypto_price: 'A cotação da criptomoeda pode variar',
    informative_transaction_fee:
      'Valores podem variar de acordo com a prioridade.',
    low_priority: 'Baixa prioridade',
    low_priority_description: 'Não estou com pressa.',
    medium_priority: 'Prioridade média',
    medium_priority_description: 'Eu posso esperar algum tempo.',
    high_priority: 'Prioridade alta',
    high_priority_description: 'Eu preciso disso o mais rápido possível.'
  },
  buttons: {
    yes: 'Sim',
    no: 'Não',
    next: 'Próximo',
    back: 'Voltar',
    go_back: 'Voltar',
    done: 'Concluído',
    save: 'Salvar',
    add: 'Adicionar',
    edit: 'Editar',
    confirm: 'Confirmar',
    copy: 'Copiar',
    complete: 'Completar',
    approve: 'Aprovar',
    reject: 'Rejeitar',
    cancel: 'Cancelar',
    delete: 'Deletar',
    remove: 'Remover',
    close: 'Fechar',
    filter: 'Filtro',
    import: 'Importar',
    upload: 'Upload',
    add_filter: 'Adicionar filtro',
    clear: 'Limpar',
    create: 'Criar',
    got_it: 'Entendi',
    logout: 'Sair',
    confirm_transaction: 'Confirmar transação',
    send: 'Enviar',
    refresh: 'Atualizar',
    export: 'Exportar'
  },
  documentation_tooltip: 'Ir para documentação',
  documentation_button: 'Ver documentação',
  sandbox_env_alert_title: 'Ambiente de teste',
  sandbox_env_alert_description:
    'Estamos fornecendo acesso à um ambiente de teste que o permitirá experimentar a plataforma, mas <emphasis>nenhum dado é permanente.</emphasis>',
  sandbox_env_alert_dialog_description:
    'Por favor, lembre-se que estamos provendo acesso a um <emphasis>ambiente de testes</emphasis> que lhe permitirá experimentar a plataforma, mas <emphasis>nenhum dado é permanente.</emphasis>',
  expired_token_alert_title: 'Token expirado',
  expired_token_alert_description: 'Você será redicionado para o login.',
  logout_dialog_title: 'Tem certeza que deseja sair?',
  logout_dialog_description: 'Sua sessão será expirada.',
  success_alert: {
    address_copied: 'Endereço copiado'
  },
  receive_crypto_currency: 'Receber Criptomoeda',
  find_logs: 'Encontrar registros',
  invalid_token: 'Token inválido',
  new_transfer: 'Nova transferência',
  email_exists: 'E-mail já existente'
};
