export const internal_sidebar_menu_items = {
  dashboard: 'Dashboard',
  services: 'Services',
  plans_payments: 'Plans & Payments',
  my_account: 'My account',
  support: 'Support',
  integrations: 'Integrations',
  settings: 'Settings',
  logout: 'Logout'
};
