import { two_factor_settings } from './2factor-authenticaton';
import { settings_menu_items } from './settings-menu-items';

export const settings_page = {
  breadcrumb_title: 'Configurações',
  settings_menu_items,
  two_factor_settings,
  language_settings: {
    title: 'Idioma',
    language_options: {
      en: 'English',
      pt: 'Português',
      es: 'Spañol'
    }
  },
  ip_permissions_settings: {
    title: 'Bloqueio por IP',
    description:
      'Ao configurar, você permite que apenas chamadas com IPs que estão na lista consigam efetuar chamadas a API.',
    add_dialog: {
      title: 'Adicionar IP'
    },
    remove_dialog: {
      title: 'Remover endereço de IP?',
      description: 'O endereço de IP será removido.'
    },
    ip_list_title: 'Lista de IPs',
    snackbar: {
      success: {
        add_ip: 'IP adicionado com sucesso'
      },
      error: {
        add_ip: 'Erro ao adicionar endereço de IP',
        invalid_ip: 'Endereço de IP inválido',
        already_exist_ip: 'IP já existe na lista'
      }
    }
  },
  theme_settings: {
    title: 'Tema',
    dark: 'Dark',
    light: 'Light'
  },
  public_key_settings: {
    title: 'Chave Pública',
    description:
      'Adicione uma chave pública para garantir a encriptação aplicada em suas informações seja apenas decriptada por você.',
    add_public_key_dialog_title: 'Adicionar Chave Pública',
    change_public_key_dialog: {
      title: 'Alterar Chave Pública',
      current_private_key_label: 'Chave privada da atual Chave Pública',
      current_private_key_description:
        'Digite a chave privada que gerou a Chave Pública atual cadastrada em nossa plataforma.',
      new_public_key_label: 'Nova Chave Pública',
      new_public_key_description:
        'Digite a nova Chave Pública, que será usada para criptografar seus dados.'
    },
    snackbar: {
      success: {
        on_add_public_key: 'Chave Pública adicionada.',
        on_change_public_key: 'Chave Pública alterada.'
      },
      error: {
        on_add_public_key: 'Erro ao adicionar Chave Pública.'
      }
    },
    buttons: {
      confirm_changes: 'Confirmar alterações'
    }
  },
  api_key_settings: {
    title: 'Chave de API',
    description:
      'Ao usar uma chave de API em seu projeto, você pode atheticar via OAuth2.',
    create_api_key_dialog_title: 'Criar Chave de API',
    delete_api_key_dialog_title: 'Remover Chave de API?',
    delete_api_key_dialog_description:
      'Esta chave será invalidada e não poderá mais ser usada.',
    add_application_dialog_title: 'Adicionar aplicação',
    remove_application_dialog_title: 'Remover aplicação?',
    remove_application_dialog_description: 'A aplicação será removida.',
    key_label: 'Chave',
    secret_label: 'Segredo',
    application_label: 'Aplicação',
    public_key_required_informative:
      'Você precisa primeiro registrar uma <emphasis>chave pública</emphasis> para registrar uma chave de API.'
  },
  webhooks_settings: {
    title: 'URL de Webhook & token de autenticação',
    description:
      'Defina uma URL padrão e um token de autorização para seus Webhooks.',
    url_label: 'URL',
    url_placeholder: 'Adicionar URL',
    authorization_token_label: 'Token de autorização (opcional)',
    authorization_token_placeholder: 'Adicionar token',

    add_webhook_dialog_title:
      'Adicionar URL de Webhook & token de autenticação',
    edit_webhook_dialog_title: 'Editar URL de Webhook & token de autenticação',

    snackbars: {
      success: {
        on_add_webhook:
          'Conjunto de URL de webhook padrão e token de autorização.',
        invite_member: 'Convite enviado'
      },
      error: {
        on_add_webhook: 'Erro ao adicionar URL do Webhook.'
      }
    }
  },
  members: {
    invite: 'Convidar',
    email: 'E-mail',
    group: 'Grupo',
    add: 'Adicionar',
    invite_button: 'Convidar usuários',
    delete_user_title: 'Deletar usuário',
    delete_user_description: 'Tem certeza que deseja deletar esse usuário?'
  }
};
