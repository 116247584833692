export const signUpForm = {
  title: 'Crie uma nova conta',
  description: 'Autenticação de 2 fatores',

  nameLabelField: 'Nome',
  nameRequiredField: 'Nome é obrigatório',

  emailLabelField: 'E-mail',
  emailRequiredField: 'E-mail é obrigatório',
  emailInvalidField: 'E-mail é inválido',

  documentLabelField: 'Documento',
  documentRequiredField: 'Documento é obrigatório',
  documentInvalidField: 'Documento é inválido',

  companyLabelField: 'Empresa',
  companyPlaceholderField: 'Nome da Empresa',
  companyRequiredField: 'Nome da Empresa é obrigatório',

  passwordLabelField: 'Senha',
  passwordRequiredField: 'Senha é obrigatória',
  passwordDoesNotMatchField: 'As senhas não são iguais',
  passwordMinCharsField: 'Senha precisa ter no mínimo 10 caracteres',
  passwordRegexField: 'Requisitos de senha não atingidos.',

  confirmPasswordLabelField: 'Confirme sua senha',
  confirmPasswordRequiredField: 'Confirmação de senha é obrigatória',

  alreadyHaveAccountText: 'Já tem uma conta?',
  changes_will_be_lost: 'As informações preenchidas serão perdidas.',
  signInButton: 'Login',
  signUpButton: 'Cadastre-se',

  snackbar: {
    titleSuccess: 'Usuário cadastrado com sucesso.',
    descriptionSuccess:
      'Por favor, verifique o código enviado para o seu e-mail.'
  }
};
