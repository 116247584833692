export const verify_account_page = {
  legal_entity: 'Entidade legal',
  country: 'País',
  name: 'Nome',
  pep: 'PEP',
  document: 'Documento',
  zip_code: 'CEP',
  address1: 'Endereço 1',
  address2: 'Endereço 2',
  city: 'Cidade',
  state: 'Estado',
  responsible_name: 'Nome do responsável legal',
  legal_informations: 'Informações legais',
  legal_responsible: 'Responsável legal',
  social_contract: 'Contrato social',
  partners: 'Parceiros',
  pep_description:
    'PEP: São pessoas politicamente expostas, ocupantes de cargos públicos e funções listadas nas regras do PLD/FTP.',
  front: 'Frente',
  back: 'Verso',
  add_partner: 'Adicionar parceiro',
  send_docs: 'Enviar documentos',
  verify_identity: 'Verificar identidade',
  my_account: 'Minha conta',
  select: 'Selecione',
  document_image: 'Imagem do documento',
  verify_account: 'Verificar conta',
  resend_docs: 'Reenviar documentos',
  start_now: 'Começar agora',
  verify_account_dialog: {
    why: 'Por que?',
    how: 'Como?',
    security: 'Segurança',
    send_documentos_to_approval: 'Envie os documentos para aprovação',
    description_1:
      'Para garantir a segurança dos nossos usuários, bem como cumprir as regras e leis do país, precisamos verificar a sua conta.',
    description_2:
      'Esta ação é obrigatória apenas se você deseja negociar criptomoedas (compra, venda e troca) ou realizar depósitos e/ou saques em moeda fiduciária.',
    description_3:
      'A documentação enviada passará por uma análise e você poderá acompanhar o status no seu perfil.'
  },
  send_documents_to_approval_dialog: {
    title: 'Enviar documentos para aprovação',
    sub_title_1: 'Os documentos passarão por análise',
    description_1: 'Enviaremos um e-mail quando estivermos finalizados.',
    description_2: 'Além disso, você pode acompanhar o status no seu perfil (',
    my_account: 'Minha conta).',
    sub_title_2: 'Declaração da veracidade das informações prestadas',
    declaration:
      "Eu, <emphasis>{{name}}</emphasis>, natural do <emphasis>{{country}}</emphasis>, portador do <emphasis>{{cpf}}</emphasis>, DECLARO para fins de comprovação de residência, sob as penas da Lei (art. 2º da Lei 7.11583) que resido no endereço à <emphasis>{{address}}, {{UF}}</emphasis>. Declaro ainda, estar ciente de que declaração falsa pode implicar na sanção penal prevista no art. 299 do Código Penal, in verbis: 'Art. 299 - Omitir, em documento público ou particular, declaração que nele deveria constar, ou nele inserir ou fazer inserir declaração falsa ou diversa da que deveria ser escrita, com o fim de prejudicar direito, criar obrigação ou alterar a verdade sobre o fato juridicamente relevante.' Pena: reclusão de 1 (um) a 5 (cinco) anos e multa, se o documento é público e reclusão de 1 (um) a 3 (três) anos, se o documento é particular. Por ser verdade, assino a presente.",
    agree: 'Concorde com a declaração',
    i_agree:
      'Concordo com a declaração da veracidade das informações prestadas.'
  },
  uploadPDF: 'Upload PDF',
  reuploadPDF: 'Reupload PDF'
};
