export const nft_collection_page = {
  breadcrumb_title_collection: 'Coleção',
  breadcrumb_title_details: 'Detalhes do NFT',
  images_tab: 'Imagens',
  audios_tab: 'Áudios',
  videos_tab: 'Vídeos',
  created: 'Criado em {{date}}',
  item: '{{value}} item',
  items: '{{value}} itens',
  create_nft: 'Criar NFT',
  create_nft_form: {
    sensitive_content: {
      title: 'Conteúdo sensível',
      description:
        'Seu arquivo está marcado como um conteúdo sensível e só estará acessível depois que verificarmos sua senha.'
    },
    preview_image: {
      title: 'Imagem de amostra',
      description:
        'Forneça uma imagem (PNG, JPG or GIF) para servir de capa para o seu NFT.'
    }
  },
  file_type_label: 'Tipo de arquivo',
  deploy_nft_dialog_title: 'Implantar NFT',
  transfer_nft_dialog_title: 'Transferir NFT',
  cancel_dialog: {
    title: 'Cancelar criação do NFT',
    description: 'Alerações serão perdidas'
  },
  sensitive_content_dialog: {
    description: 'Este arquivo está marcado como sensível e requer uma senha.'
  },
  snackbar: {
    success: {
      on_create: 'NFT criado.',
      on_transfer: 'NFT transferido.'
    },
    error: {
      on_create: 'Erro ao criar NFT.',
      on_transfer: 'Erro ao transferir NFT.'
    }
  },
  buttons: {
    upload_file: 'Enviar arquivo',
    reupload_file: 'Reenviar arquivo',
    transfer: 'Transferir',
    check_password: 'Veriricar senha'
  }
};
