import { SyncIcon } from '@bws-bitfy/icons-react';
import { Box, useTheme } from '@mui/material';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Image from 'next/image';
import { alertColor } from 'themes/dark-theme';

interface ErrorPageProps {
  errorTitle: string;
  errorSubtitle?: string;
  errorMessage: string;
  onClickButton: () => void;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  errorTitle,
  errorSubtitle,
  errorMessage,
  onClickButton
}) => {
  const theme = useTheme();

  return (
    <section style={{ width: '100%' }}>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          backgroundColor: theme.palette.background.paper,
          height: '100vh'
        }}
        alignItems="center"
        flexDirection="column"
      >
        <Box>
          <Image width={389} height={303} src="/images/404-image.png" />
        </Box>
        <Box display="flex" justifyContent="center" pt={3}>
          <Text
            variant="h5"
            fontSize={{ xs: '20px', md: '34px' }}
            color={alertColor.lighter}
            fontWeight={700}
          >
            {errorTitle}
          </Text>
        </Box>
        {errorSubtitle && (
          <Box display="flex" justifyContent="center" pt={3}>
            <Text
              variant="h5"
              fontSize={{ xs: '16px', md: '24px' }}
              color={alertColor.lighter}
              fontWeight={500}
            >
              {errorSubtitle}
            </Text>
          </Box>
        )}
        <Box display="flex" justifyContent="center" pt={3}>
          <Text
            variant="h5"
            fontSize={{ xs: '16px', md: '24px' }}
            color={alertColor.light}
            fontWeight={400}
          >
            {errorMessage}
          </Text>
        </Box>

        <Box display="flex" justifyContent="center" pt={{ xs: 4, md: 8 }}>
          <Button
            startIcon={<SyncIcon />}
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              width: '196px'
            }}
            onClick={() => {
              onClickButton();
            }}
          >
            Refresh
          </Button>
        </Box>
      </Box>
    </section>
  );
};

export default ErrorPage;
