export const signInForm = {
  title: 'Login',
  description: 'Autenticação de 2 fatores',

  sandbox_text_one: 'As contas criadas em sandbox são usadas ',
  sandbox_text_two:
    'exclusivamente neste ambiente e completamente\nseparado do ambiente de produção.',

  emailLabelField: 'E-mail',
  emailRequiredField: 'E-mail é obrigatório',
  emailInvalidField: 'E-mail é inválido',
  emailErrorField: 'E-mail incorreto',

  passwordLabelField: 'Senha',
  passwordRequiredField: 'Senha é obrigatória',
  passwordErrorField: 'Senha inválida',

  loginButton: 'Login',
  signInWithGoogleButton: 'Login com Google',
  signUpButton: 'Cadastre-se',
  forgotPasswordButton: 'Esqueci minha senha',

  snackbar: {
    titleSuccess: 'Usuário autenticado com sucesso',
    descriptionSuccess: 'Bem-vindo ao mundo Blockchain!',

    titleError: 'E-mail ou senha inválidos'
  },
  twoFactorTitle: 'Use a autenticação de 2 fatores para continuar.'
};
