export const internal_sidebar_menu_items = {
  dashboard: 'Dashboard',
  services: 'Serviços',
  plans_payments: 'Planos & Pagamentos',
  my_account: 'Minha conta',
  support: 'Suporte',
  integrations: 'Integrações',
  settings: 'Configurações',
  logout: 'Sair'
};
