export const transaction_policy_page = {
  breadcrumb_title: 'Política de transação',
  breadcrumb_title_create: 'Criar',

  create_transaction_policy: 'Criar política de transação',

  form: {
    transaction_label: 'Nome da Política de Transação',
    transaction_placeholder: 'Definir Nome da Política de Transação',

    amount_label: 'Valor',
    amount_description:
      'Definir os valores monetários mínimo e máximo para a transação',
    amount_description2:
      'Transações fora do valor definido serão rejeitadas no momento da criação',

    min_value_invalid: 'O valor mínimo não deve ser maior que o valor máximo',

    secure_destinations_label: 'Destinatários confiáveis',
    secure_destinations_description:
      'Defina um endereço predefinido para envio de criptomoeda',
    secure_destinations_description2:
      'As transações que não forem para endereços seguros serão rejeitadas no momento da criação',

    time_frame_label: 'Período de Tempo',
    time_frame_description:
      'Especificar o período de tempo durante o qual a transação deve ocorrer',
    time_frame_description2:
      'As transações aprovadas fora do prazo definido ficam pendentes até que a janela esteja disponível',
    timezone_field_placeholder: 'Fuso horário',

    monday_to_friday: 'Segunda à Sexta',

    asset_label: 'Ativo',
    asset_description: 'Escolher as criptomoedas envolvidas na transação',
    asset_description2:
      'As transações que utilizam ativos não configurados serão rejeitadas no momento da criação',

    approval_label: 'Aprovação',
    approval_placeholder: 'Aprovadores',
    approval_description:
      'Indicar o número mínimo de aprovações necessárias para a transação',
    approval_description2:
      'Até que o número mínimo de aprovadores seja atingido, a transação não será executada',

    min_approvals_placeholder: 'Min. Aprovações 2',
    min_approvals_invalid_helper_text:
      'Mínimo de aprovadores deve ser menor ou igual ao total de aprovadores informados',
    min_approvals_helper_text: 'Devem haver 1 ou mais aprovadores',

    to_label: 'até',

    minimum_value_label: 'Valor Mínimo',
    maximum_value_label: 'Valor Máximo',
    network_label: 'Rede',
    address_label: 'Endereço',

    add_button: 'Adicionar Política de Transação',
    update_button: 'Atualizar Política de Transação',
    add_address_button: 'Adicionar Endereço',
    add_network_button: 'Adicionar Rede'
  },

  delete_policy_dialog: {
    title: 'Deletar política de transação',
    description:
      'Tem certeza que deseja deletar a política de transação "{{name}}"?'
  },

  table_header: {
    transaction_policy_name: 'Nome da política de transação',
    created_by: 'Criado por',
    date: 'Data',
    delete: 'Deletar'
  },

  success_message: {
    policy_created: 'Política de transação criada com sucesso',
    policy_updated: 'Política de transação atualizada com sucesso',
    policy_deleted: 'Política de transação deletada com sucesso'
  }
};
