import { Box, Tooltip as MUITooltip } from '@mui/material';
import Text from 'components/atoms/Text';
import styles from './styles';
import { CustomTooltipProps } from './types';

function Tooltip({
  icon,
  title,
  children,
  titleIntlPath,
  titleIntlPathProps,
  ...rest
}: CustomTooltipProps) {
  return (
    <MUITooltip
      arrow
      title={
        <Box sx={styles.body}>
          {icon && (
            <Box
              data-testid="tooltip-icon-container"
              display="flex"
              alignItems="center"
            >
              {icon}
            </Box>
          )}

          <Text
            {...titleIntlPathProps}
            fontWeight={400}
            variant="caption"
            marginLeft={icon ? 1.25 : 0}
            intlPath={titleIntlPath}
          >
            {title}
          </Text>
        </Box>
      }
      {...rest}
      enterDelay={700}
      enterTouchDelay={500}
    >
      {children}
    </MUITooltip>
  );
}

export default Tooltip;
