export const wallet_details_page = {
  breadcrumb_title: '{{walletReference}}',
  transactions_table: {
    title: 'Transactions',
    headers: {
      with_custody: 'With custody'
    }
  },
  buy_and_sell_table: {
    headers: {
      transaction: 'Transaction',
      pair: 'Pair',
      explorer: 'Explorer'
    }
  },
  webhooks_table: {
    headers: {
      url: 'URL',
      response: 'Response'
    }
  },
  send_crypto_form: {
    title: 'Send Cryptocurrency'
  },
  receive_crypto_form: {
    title: 'Receive Cryptocurrency',
    informative_label: 'You can specify the amount to be received'
  },
  snackbar: {
    success: {
      sent_crypto: 'P2P was successfully done'
    },
    errors: {
      get_wallet_generic_error: 'Error on get wallets'
    }
  },
  buttons: {
    send: 'Send',
    receive: 'Receive'
  },
  details: {
    title: 'Details'
  }
};
