export const vaults_page = {
  breadcrumb_title: 'Cofres',
  no_vaults_created: 'Nenhum Cofre Criado Ainda',
  create_vault: 'Criar Cofre',
  transaction_polices: 'Políticas de Transação',
  transaction_id: 'ID da transação',
  approvers: 'Aprovadores',
  approvals: 'Aprovações',
  setup: 'Configuração',
  wallet_creation: 'Criação de Carteira',
  vault_password: 'Senha do Cofre',
  vault_name: 'Nome do Cofre',
  give_your_vault_name: 'Dê um nome para o seu cofre',
  users: 'Usuários',
  choose_users: 'Escolha os usuários que terão acesso a este Cofre',
  minimum_operators: 'Operadores Mínimos para Completar Transação',
  minimum_operators_placehold: 'Definir operadores',
  max_time_to_approval: 'Tempo para completar transação',
  max_time_to_approval_placehold: 'horas',
  minimum_operators_2: 'Operadores mínimos: 2',
  operators: 'Operadores',
  choose_operators: 'Escolha os usuários que terão acesso a este Cofre',
  vault_password_info:
    'Senhas do cofre serão enviadas aos participantes após a criação do cofre',
  choose_transaction_policies:
    'Escolha as Políticas de Transação para este Cofre',
  transaction_policies_info:
    'Defina as Políticas de Transação na tela <emphasis>Políticas de Transação</emphasis>',
  create_transaction_policy: 'Criar Política de Transação',
  password_generate_info: 'A senha é gerada após esta etapa.',
  password_info:
    'Esta é a senha do seu cofre. <emphasis>Por favor, esteja ciente de que você precisa guardar esta senha para usá-la posteriormente.</emphasis>',
  agreement_password_info:
    'Eu entendo que preciso guardar a senha deste cofre.',
  status_labels: {
    pending: 'Aguardando operadores',
    pending_wallets: 'Carteiras pendentes',
    created: 'Criado',
    canceled: 'Cancelado'
  },
  transactions_status_labels: {
    pending: 'Pendente',
    pending_execution: 'Execução pendente',
    executed: 'Executada',
    canceled: 'Canceleda'
  },
  vault_approval_dialog_title: 'Aprovação de Cofre',
  transaction_policy_creation_informative:
    'Para criar um Cofre, você precisa criar uma Política de Transação',
  vault_operator_password_info:
    'Esta é a senha do seu cofre. <emphasis>Por favor, esteja ciente de que você precisa guardar esta senha para usá-la posteriormente.</emphasis>',
  vault_password_agreement:
    'Eu entendo que preciso guardar a senha deste cofre.',
  copied_password_message: 'Senha copiada para a área de transferência',

  vault_summary: {
    asset: 'Ativo:',
    amount: 'Quantidade:',
    time_frame: 'Prazo:',
    secure_destinations: 'Destinos Seguros:',
    approvar: 'Aprovação:',
    minimum_approvals: 'Aprovações Mínimas:',
    creation_date: 'Data de Criação',
    created_by: 'Criado por',
    and_more_addresses: 'e mais {{value}} endereços',
    and_more_assets: 'e mais {{value}} ativos'
  },
  add_new_wallet: 'Adicionar Nova Carteira',
  vault_created_successfully: 'Seu Cofre foi criado com sucesso',
  vault_approved_successfully: 'O cofre foi aprovado com sucesso',
  vault_rejected_successfully: 'O cofre foi rejeitado com sucesso',
  password_copied: 'Senha copiada para a área de transferência',
  monday_to_friday: 'Segunda a Sexta-feira',
  validation_errors: {
    minimum_operators:
      'O número mínimo de operadores deve ser igual ou menor que o número total de operadores selecionados',
    minimum_operators_min: 'O número deve ser igual ou maior que 2'
  },
  vaults_password: 'Senha do Cofre',
  vaults_password_placeholder: 'Insira a senha do cofre',
  transaction_created_successfully: 'Transação criada com sucesso',
  transaction_confirmed_successfully: 'Transação confirmada com sucesso',
  transaction_approved_successfully: 'Transação aprovada com sucesso',
  insert_password: 'Inserir Senha',
  pending_transactions: 'Pendente',
  transactions: 'Executada',
  transactions_on_chain: 'Na rede',
  transaction_confirmation: 'Confirmação de Transação',
  amount_to_send: 'Quantidade a enviar',
  coin_network_fee: 'Taxa de rede {{value}}',
  transaction_approval: 'Aprovação da Transação'
};
